import React from 'react';
import { Link } from 'react-router-dom';

function ClubCard({ club }) {
    const truncateDescription = (description, maxLength) => {
        if (description.length > maxLength) {
            return description.slice(0, maxLength) + '...';
        }
        return description;
    };

    return (
        <Link to={`/clubs/${club.clubId}`} className="no-underline">
            <div className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200 hover:bg-gray-100">
                <div className="flex justify-center mb-4">
                    {club.logo ? (
                        <img src={club.logo} alt={`${club.name} Logo`} className="w-24 h-24 rounded-full object-cover" />
                    ) : (
                        <div className="bg-gray-200 h-24 w-24 rounded-full flex items-center justify-center">
                            <span className="text-gray-400 text-2xl">Logo</span>
                        </div>
                    )}
                </div>
                <h2 className="text-xl font-semibold text-gray-800 mb-2">{club.name}</h2>
                <p className="text-gray-600 mb-4">{truncateDescription(club.description, 100)}</p>
            </div>
        </Link>
    );
}

export default ClubCard;

import React from 'react';
import MultiStepForm from './MultiStepForm';

function PaymentsDashboard() {
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Payments Dashboard</h1>
      <MultiStepForm />
    </div>
  );
}

export default PaymentsDashboard;

import React from 'react';

const About = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="bg-white max-w-4xl mx-auto p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center text-primary mb-4">About Us</h1>
        <p className="text-lg text-gray-700 mb-6 text-center">
          Ultra-Rides is a community of cycling enthusiasts who come together to embark on epic long-distance rides. Our mission is to promote a healthy lifestyle through cycling, build a strong community of riders, and explore the beauty of the world on two wheels.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
            <h2 className="text-xl font-semibold text-primary mb-2">Our Mission</h2>
            <p className="text-gray-600">
              Our mission is to inspire people to take up cycling and join our community of passionate riders. We believe in the power of cycling to transform lives, improve health, and connect people.
            </p>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
            <h2 className="text-xl font-semibold text-primary mb-2">Our Team</h2>
            <p className="text-gray-600">
              Our team consists of experienced cyclists, event organizers, and community builders who are dedicated to creating memorable cycling experiences for all our members.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
            <h2 className="text-xl font-semibold text-primary mb-2">Our History</h2>
            <p className="text-gray-600">
              Ultra-Rides was founded in 2010 by a group of cycling enthusiasts who wanted to share their love for long-distance rides. Over the years, we have grown into a vibrant community with members from all over the world.
            </p>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
            <h2 className="text-xl font-semibold text-primary mb-2">Contact Us</h2>
            <p className="text-gray-600">
              We love hearing from our community! If you have any questions, suggestions, or just want to say hello, feel free to reach out to us.
            </p>
          </div>
        </div>

        <div className="text-center">
          <a href="/our-mission" className="inline-block bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-colors mx-2">
            Learn More About Our Mission
          </a>
          <a href="/team" className="inline-block bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-colors mx-2">
            Meet Our Team
          </a>
          <a href="/history" className="inline-block bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-colors mx-2">
            Explore Our History
          </a>
          <a href="/contact" className="inline-block bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-colors mx-2">
            Get in Touch
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;

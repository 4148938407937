import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import axios from '../../config/axiosConfig';
import { FaUserCircle, FaCamera } from 'react-icons/fa';

function AvatarEditorComponent({ profileImage, setProfileImage, setProfileImageUrl, setMessage, setError, initialImageUrl }) {
  const editorRef = useRef(null);
  const [imageScale, setImageScale] = useState(1);
  const [isEditing, setIsEditing] = useState(false);

  const handleImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleImageSave = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      try {
        const response = await axios.patch('/users', { picture: canvas });

        if (response.status === 200) {
          setMessage('Profile image updated successfully.');
          setError('');
          setProfileImageUrl(canvas); // Update the profile image URL after successful save
          setIsEditing(false); // Exit editing mode after save
        } else {
          setMessage('Failed to update profile image.');
          setError(response.data.message || 'Error updating profile image.');
        }
      } catch (error) {
        setMessage('Error updating profile image.');
        setError(error.response?.data?.message || 'Error updating profile image.');
      }
    }
  };

  return (
    <div className="avatar-editor-container text-center relative">
      {isEditing ? (
        <>
          <AvatarEditor
            ref={editorRef}
            image={profileImage || ''}
            width={200}
            height={200}
            border={50}
            scale={imageScale}
            rotate={0}
          />
          <input
            type="range"
            min="1"
            max="2"
            step="0.01"
            value={imageScale}
            onChange={(e) => setImageScale(parseFloat(e.target.value))}
            className="slider my-2"
          />
          <div className="flex justify-center space-x-2">
            <button
              onClick={handleImageSave}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors"
            >
              Save
            </button>
            <button
              onClick={() => setIsEditing(false)}
              className="bg-gray-400 text-white py-2 px-4 rounded hover:bg-gray-500 transition-colors"
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <div className="relative w-32 h-32 mx-auto cursor-pointer" onClick={() => setIsEditing(true)}>
          {initialImageUrl ? (
            <img
              src={initialImageUrl}
              alt="Profile"
              className="w-full h-full rounded-full object-cover"
            />
          ) : (
            <FaUserCircle className="w-full h-full text-gray-300" />
          )}
          <div className="absolute bottom-2 right-2 bg-black bg-opacity-75 rounded-full p-2">
            <FaCamera className="text-white text-xl" />
          </div>
        </div>
      )}
      {isEditing && (
        <div className="mt-2">
          <input type="file" onChange={handleImageChange} className="hidden" id="profileImageUpload" />
          <label htmlFor="profileImageUpload" className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer hover:bg-blue-600 transition-colors">
            Choose File
          </label>
        </div>
      )}
    </div>
  );
}

export default AvatarEditorComponent;

import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from 'react-leaflet';
import { Map as MapIcon, X, Search } from 'lucide-react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const customIcon = new L.Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const ChangeView = ({ center }) => {
  const map = useMap();
  map.setView(center, 13);
  return null;
};

const formatCoordinate = (coord) => {
  // Ensure exactly 5 decimal places
  return Number(parseFloat(coord).toFixed(5));
};

const validateCoordinates = (input) => {
  const coordsRegex = /^-?\d+\.\d{5},\s*-?\d+\.\d{5}$/;
  return coordsRegex.test(input);
};

const MapPicker = ({ value, onChange, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState([12.97160, 77.59460]); // Default to Bangalore
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState('');

  useEffect(() => {
    if (value && validateCoordinates(value)) {
      const [lat, lng] = value.split(',').map(coord => parseFloat(coord.trim()));
      if (!isNaN(lat) && !isNaN(lng)) {
        setPosition([lat, lng]);
      }
    }
  }, [value]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchText.trim()) return;

    setIsSearching(true);
    setSearchError('');

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchText)}`
      );
      const data = await response.json();

      if (data.length > 0) {
        const { lat, lon } = data[0];
        const formattedLat = formatCoordinate(lat);
        const formattedLon = formatCoordinate(lon);
        const newPosition = [formattedLat, formattedLon];
        setPosition(newPosition);
        onChange(`${formattedLat}, ${formattedLon}`);
      } else {
        setSearchError('Location not found');
      }
    } catch (error) {
      setSearchError('Error searching location');
    } finally {
      setIsSearching(false);
    }
  };

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        const formattedLat = formatCoordinate(lat);
        const formattedLon = formatCoordinate(lng);
        const newPosition = [formattedLat, formattedLon];
        setPosition(newPosition);
        onChange(`${formattedLat}, ${formattedLon}`);
      },
    });

    return position ? <Marker position={position} icon={customIcon} /> : null;
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    onChange(inputValue);
    
    if (validateCoordinates(inputValue)) {
      const [lat, lng] = inputValue.split(',').map(coord => parseFloat(coord.trim()));
      setPosition([lat, lng]);
    }
  };

  return (
    <div className={`relative ${className}`}>
      <div className="flex gap-2">
        <input
          type="text"
          className="flex-1 p-2 border rounded-md"
          placeholder="latitude, longitude (e.g., 12.97160, 77.59460)"
          value={value || ''}
          onChange={handleInputChange}
        />
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center gap-2"
        >
          {isOpen ? <X size={16} /> : <MapIcon size={16} />}
          {isOpen ? 'Close Map' : 'Pick on Map'}
        </button>
      </div>

      {isOpen && (
        <div className="absolute z-10 top-full left-0 right-0 mt-2">
          <div className="border rounded-lg shadow-lg bg-white p-2">
            <form onSubmit={handleSearch} className="mb-2 flex gap-2">
              <input
                type="text"
                placeholder="Search for a city or location..."
                className="flex-1 p-2 border rounded-md"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <button
                type="submit"
                className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center gap-2"
                disabled={isSearching}
              >
                <Search size={16} />
                {isSearching ? 'Searching...' : 'Search'}
              </button>
            </form>
            
            {searchError && (
              <div className="mb-2 text-red-500 text-sm">{searchError}</div>
            )}

            <MapContainer
              center={position}
              zoom={13}
              className="h-96 w-full rounded-md"
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker />
              <ChangeView center={position} />
            </MapContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapPicker;

import React from 'react';
import { X, Check } from 'lucide-react';

const CategoryPopup = ({ 
  isOpen, 
  onClose, 
  categories = [], 
  selectedCategory, 
  onCategorySelect,
  isUserRegistered 
}) => {
  if (!isOpen) return null;

  const getCategoryBadge = (category) => {
    let badge = '';
    if (category.professionalLevel) badge += category.professionalLevel;
    if (category.gender) badge += badge ? ` • ${category.gender}` : category.gender;
    if (category.ageGroup) badge += badge ? ` • ${category.ageGroup}` : category.ageGroup;
    return badge;
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" onClick={onClose} />
        
        <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Select Category</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="space-y-2">
            {categories.map((category) => (
              <div
                key={category.id}
                onClick={() => {
                  if (isUserRegistered) {
                    onCategorySelect(category.id);
                    onClose();
                  }
                }}
                className={`
                  p-4 rounded-lg border-2 transition-all duration-200 cursor-pointer
                  ${!isUserRegistered ? 'opacity-50 cursor-not-allowed' : 'hover:border-primary/50'}
                  ${selectedCategory === category.id ? 'border-primary bg-primary/5' : 'border-gray-200'}
                `}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h4 className="font-medium text-gray-900">{category.name}</h4>
                    <div className="mt-1 text-sm text-gray-500">
                      {getCategoryBadge(category)}
                    </div>
                    <div className="mt-2 space-y-1 text-sm text-gray-500">
                      {category.minAge && category.maxAge && (
                        <p>Age: {category.minAge} - {category.maxAge} years</p>
                      )}
                      {category.maxParticipants && (
                        <p>Limited to {category.maxParticipants} participants</p>
                      )}
                      {category.price && (
                        <p className="font-medium text-gray-900">
                          Entry fee: ₹{category.price}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={`
                    w-6 h-6 rounded-full border-2 flex items-center justify-center
                    ${selectedCategory === category.id ? 'border-primary bg-primary' : 'border-gray-300'}
                  `}>
                    {selectedCategory === category.id && (
                      <Check className="w-4 h-4 text-white" />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryPopup;

import { useState, useEffect } from 'react';
import { calculateControlTime } from '../utils/wizardUtils';

export const useRideForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        date: '',
        time: '',
        timezone: '',
        registrationClosingDate: '',
        registrationClosingTime: '',
        rideType: '',
        details: 'Details about route, equipment requirements, facilities, and special instructions can be added from the Ride Admin Panel after creation.',
        startPoint: '',
        route: '',
        distance: '',
        isTimeControlled: false,
        timeControlType: '',
        controlPoints: [],
        minimumControlPoints: 0,
        responsibleName: '',
        responsibleEmail: '',
        responsiblePhone: '',
        rulesAndRegulations: '',
        routeDetails: '',
        whatsappGroupUrl: ''
    });

    // Effect to handle control points initialization and updates
    useEffect(() => {
        if (formData.rideType === 'audax' && formData.distance) {
            const shouldInitializeControlPoints = 
                formData.controlPoints.length === 0 || 
                (formData.controlPoints.length === 2 && 
                 formData.controlPoints[1].distance !== parseInt(formData.distance));

            if (shouldInitializeControlPoints) {
                setFormData(prev => ({
                    ...prev,
                    controlPoints: [
                        {
                            distance: 0,
                            description: "Start Point",
                            gpsCoordinates: "",
                            closeTime: calculateControlTime("0", prev.time, prev.timeControlType, prev.date)
                        },
                        {
                            distance: parseInt(prev.distance),
                            description: "End Point",
                            gpsCoordinates: "",
                            closeTime: calculateControlTime(prev.distance, prev.time, prev.timeControlType, prev.date)
                        }
                    ],
                    minimumControlPoints: 2
                }));
            }
        }
    }, [formData.rideType, formData.distance, formData.time, formData.timeControlType, formData.date]);

    const handleInputChange = (field, value) => {
        setFormData(prev => {
            const newData = { ...prev, [field]: value };

            if (field === 'rideType') {
                newData.isTimeControlled = value === 'audax';
                if (value !== 'audax') {
                    newData.timeControlType = '';
                    newData.controlPoints = [];
                }
            }

            if ((field === 'time' || field === 'timeControlType' || field === 'date') && newData.isTimeControlled) {
                newData.controlPoints = newData.controlPoints.map(point => ({
                    ...point,
                    closeTime: calculateControlTime(
                        point.distance,
                        newData.time,
                        newData.timeControlType,
                        newData.date
                    )
                }));
            }

            return newData;
        });
    };

    const updateControlPoint = (index, field, value) => {
        setFormData(prev => ({
            ...prev,
            controlPoints: prev.controlPoints.map((point, i) => {
                if (i === index) {
                    const updatedPoint = { ...point, [field]: value };
                    if (field === 'distance' && index !== 0 && index !== prev.controlPoints.length - 1) {
                        updatedPoint.closeTime = calculateControlTime(
                            value,
                            prev.time,
                            prev.timeControlType,
                            prev.date
                        );
                    }
                    return updatedPoint;
                }
                return point;
            })
        }));
    };

    const addControlPoint = () => {
        setFormData(prev => {
            const newPoint = {
                distance: '',
                description: '',
                gpsCoordinates: '',
                closeTime: calculateControlTime(
                    '',
                    prev.time,
                    prev.timeControlType,
                    prev.date
                )
            };

            const currentPoints = [...prev.controlPoints];
            currentPoints.splice(currentPoints.length - 1, 0, newPoint);

            return {
                ...prev,
                controlPoints: currentPoints,
                minimumControlPoints: currentPoints.length
            };
        });
    };

    const removeControlPoint = (index) => {
        setFormData(prev => {
            if (index === 0 || index === prev.controlPoints.length - 1) return prev;

            const newPoints = prev.controlPoints.filter((_, i) => i !== index);
            return {
                ...prev,
                controlPoints: newPoints,
                minimumControlPoints: newPoints.length
            };
        });
    };

    const resetForm = () => {
        setFormData({
            name: '',
            date: '',
            time: '',
            registrationClosingDate: '',
            registrationClosingTime: '',
            rideType: '',
            details: 'Details about route, equipment requirements, facilities, and special instructions can be added from the Ride Admin Panel after creation.',
            startPoint: '',
            route: '',
            distance: '',
            isTimeControlled: false,
            timeControlType: '',
            controlPoints: [],
            minimumControlPoints: 0,
            responsibleName: '',
            responsibleEmail: '',
            responsiblePhone: '',
            rulesAndRegulations: '',
            routeDetails: '',
            whatsappGroupUrl: ''
        });
    };

    return {
        formData,
        setFormData,
        handleInputChange,
        updateControlPoint,
        addControlPoint,
        removeControlPoint,
        resetForm
    };
};

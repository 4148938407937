// WaiverText.js
import React from 'react';

const WaiverText = () => (
    <div>
        <h1 className="text-2xl font-bold mb-4">Ultra-Rides - Terms, Waiver and Release from Liability</h1>
        <p className="mb-4">
            In consideration of my registration and for permitting me to participate in this event, I hereby take responsibility and accountability for myself, my executors, administrators, heirs, next of kin, successors, and assigns as follows:
        </p>
        <ul className="list-disc list-inside mb-4">
            <li>I certify that I am fit, have trained sufficiently for participation in this event, and have not been advised against participating by a qualified medical professional.</li>
            <li>I will ride safely and use a hard-shell riding helmet. I will equip my bike with a strong front light, red rear lights (with spare bulbs and batteries for both), and reflective gear. I will obey all local traffic laws and regulations and understand that portions of the route may be along busy roads or highways.</li>
            <li>I am responsible for knowing the event route. I understand that unexpected situations may arise during the event that may be beyond the immediate control of the event organizers, officials, and volunteers.</li>
            <li>I understand that only basic first aid may be available on the event route. In case of serious injury, I am solely responsible for any medical expenses and evacuation costs.</li>
            <li>I understand that my registration fee (if any) is non-refundable. The event may be canceled or rescheduled due to weather or other unforeseen circumstances, at the discretion of the event organizers.</li>
            <li>I permit the free use of my name, pictures and event related details on any social media platforms, in media broadcasts, print, and television, etc.</li>
            <li>I fully understand and acknowledge that by participating in this event, I am engaging in an activity that carries inherent risks, including but not limited to, physical exertion, traffic and road conditions, weather conditions, interactions with other participants, and potential for accidents or injuries. I also acknowledge that cycling is hazardous and may result in injury, death, or property damage. I willingly assume all risks associated with my participation, including those that may not be foreseeable at this time. I hereby irrevocably release, waive, discharge, and covenant not to sue Ultra-Rides, the event organizers, their affiliates, employees, volunteers, agents, and any other associated entities (collectively referred to as "Released Parties") from any and all liability, claims, demands, actions, or causes of action whatsoever arising out of or related to any loss, damage, injury, or death that may be sustained by me, or to any property belonging to me, whether caused by the negligence of the Released Parties or otherwise, while participating in this event. This release extends to any and all claims, demands, damages, costs, expenses, actions, and causes of action, including those of which I am not now aware, arising from or related to my participation in the event, including travel to and from the event, and any activities incidental to the event. I further agree to indemnify and hold harmless the Released Parties from any loss, liability, damage, or costs, including court costs and attorneys' fees, that they may incur due to my participation in the event, whether caused by negligence of the Released Parties or otherwise.</li>
        </ul>
        <p className="mb-4">
            As part of our registration process, you understand and acknowledge that the respective event organizer (through our Ultra-Rides platform) needs to collect some personal information from you, including your e-mail address and phone number (including phone number of an emergency contact), to ensure a smooth, safe and personalized experience. The respective event organizer (through our Ultra-Rides platform) collects your personal data to (i) send you important updates and information about/ related to the event; (ii) facilitate communication regarding your participation and logistics; (iii) provide emergency contact in case of any issues during the event; and (iv) send you any post event updates and communication. By providing your personal data, you agree to the collection and use of your personal data as outlined in this statement. You have the right to (i) correct, or delete your personal data at any time from the Ultra-Rides platform; and (ii) withdraw your consent at any time - by contacting the respective event organizer directly.
        </p>
        <p className="font-semibold mb-4">I have duly read, completely understand and hereby agree to all the above terms.</p>
    </div>
);

export default WaiverText;
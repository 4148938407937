import React from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';

const ForClubOwners = () => {
    return (
        <div className="flex flex-col md:flex-row items-center my-12 p-8 bg-white shadow-lg rounded-lg">
            <div className="md:w-1/2 p-4">
                <h2 className="text-4xl font-extrabold mb-4">Empower Your Cycling Club</h2>
                <p className="text-gray-600 mb-6">
                    UltraRides offers a robust platform to manage and promote your cycling events. Ensure your riders' achievements are permanently recognized with our homologation system. Enhance your club's visibility and provide a seamless experience for your members.
                </p>
                <ul className="mb-6">
                    <li className="flex items-center mb-2">
                        <FaRegCheckCircle className="text-primary mr-2" />
                        <span>Homologation Management: Easily manage and track rider homologations.</span>
                    </li>
                    <li className="flex items-center mb-2">
                        <FaRegCheckCircle className="text-primary mr-2" />
                        <span>Digital Certificates: Issue digital certificates to riders upon completion of events.</span>
                    </li>
                    <li className="flex items-center mb-2">
                        <FaRegCheckCircle className="text-primary mr-2" />
                        <span>Public Profiles: Showcase your club's successful events and riders' achievements.</span>
                    </li>
                    <li className="flex items-center mb-2">
                        <FaRegCheckCircle className="text-primary mr-2" />
                        <span>Event Promotion: Utilize our platform to promote upcoming events.</span>
                    </li>
                </ul>
                <a
                    href="/create-account"
                    className="inline-block bg-primary text-white py-3 px-8 rounded-full font-semibold text-xl hover:bg-primary-dark transition-all"
                >
                    Get Started as a Club Owner
                </a>
            </div>
            <div className="md:w-1/2 p-4">
                <img src="/club.jpg" alt="Club Owner Managing Events" className="w-full rounded-lg shadow-md" />
            </div>
        </div>
    );
};

export default ForClubOwners;

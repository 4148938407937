import React from 'react';

const ExploreOurHistory = () => {
  const historyEvents = [
    {
      year: 2010,
      event: 'Ultra-Rides was founded by a group of passionate cyclists.'
    },
    {
      year: 2012,
      event: 'First international ride event was organized, attracting cyclists from around the world.'
    },
    {
      year: 2015,
      event: 'Launch of our online platform connecting cyclists and clubs globally.'
    },
    {
      year: 2018,
      event: 'Introduced annual cycling series and competitions.'
    },
    {
      year: 2020,
      event: 'Reached a milestone of 10,000 registered riders.'
    },
    {
      year: 2023,
      event: 'Launched a new mobile app to enhance rider experience.'
    },
  ];

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="bg-white max-w-4xl mx-auto p-6 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold text-center text-primary mb-4">Our History</h1>
        <div className="space-y-4">
          {historyEvents.map((event, index) => (
            <div key={index} className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
              <h2 className="text-xl font-semibold text-primary mb-2">{event.year}</h2>
              <p className="text-gray-600">{event.event}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExploreOurHistory;

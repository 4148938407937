import React, { useState, useEffect } from 'react';
import WaiverText from './WaiverText';
import axios from '../../config/axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

// Utility functions to interact with localStorage
export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
    return localStorage.getItem(key);
};

export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
    localStorage.clear();
};

export const safeParseJSON = (value) => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
};

const WaiverForm = ({ onSubmit, user }) => {
    const { rideId } = useParams();
    const { state } = useAuth();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        urnNumber: '',
        name: '',
        date: '',
        ecName: '',
        ecPhoneNumber: '',
        bloodGroup: '',
        signature: false
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionMessage, setSubmissionMessage] = useState('');

    useEffect(() => {
        const idTokenPayload = safeParseJSON(getLocalStorage('idTokenPayload'));
        if (idTokenPayload) {
            setFormData({
                urnNumber: idTokenPayload['custom:userId'] || '',
                name: idTokenPayload['name'] || '',
                date: new Date().toISOString().split('T')[0],
                ecName: idTokenPayload['custom:ec_name'] || '',
                ecPhoneNumber: idTokenPayload['custom:ec_phone_number'] || '',
                bloodGroup: idTokenPayload['custom:blood_group'] || '',
                signature: false
            });
        }
    }, []);

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setFormData({
            ...formData,
            [name]: e.target.type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.signature) {
            alert('Please agree to the terms before submitting.');
            return;
        }
        setIsSubmitting(true);
        setSubmissionMessage('Submitting...');
        try {
            const response = await axios.post(`/rides/${rideId}/waiver`, { rideId, waiverDetails: formData });
            if (response.status === 200) {
                setSubmissionMessage('Waiver submitted successfully.');
                if (onSubmit) {
                    onSubmit(formData);
                }
                setTimeout(() => {
                    navigate('#check-in');
                }, 500);
            } else {
                setSubmissionMessage('Failed to submit waiver.');
            }
        } catch (error) {
            console.error('Error submitting waiver form:', error);
            setSubmissionMessage('Error submitting waiver form. Please try again later.');
        }
    };

    const isFormValid = () => {
        return formData.urnNumber && formData.name && formData.date && formData.ecName && formData.ecPhoneNumber && formData.bloodGroup && formData.signature;
    };

    return (
        <div className="max-w-3xl mx-auto p-6 bg-gray-100 shadow-md rounded-lg">
            {submissionMessage && (
                <p className="mt-4 text-center text-green-600">{submissionMessage}</p>
            )}
            <WaiverText />
            {user.waiver ? (
                <p className="text-center text-green-600">You have already submitted the waiver.</p>
            ) : (
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="form-group">
                        <label htmlFor="urnNumber" className="block text-sm font-medium text-textSecondary">URN Number:</label>
                        <input type="text" id="urnNumber" name="urnNumber" value={formData.urnNumber} onChange={handleChange} readOnly className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name" className="block text-sm font-medium text-textSecondary">Name:</label>
                        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="date" className="block text-sm font-medium text-textSecondary">Date:</label>
                        <input type="date" id="date" name="date" value={formData.date} onChange={handleChange} required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="ecName" className="block text-sm font-medium text-textSecondary">Emergency Contact Name:</label>
                        <input type="text" id="ecName" name="ecName" value={formData.ecName} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="ecPhoneNumber" className="block text-sm font-medium text-textSecondary">Emergency Contact Phone Number:</label>
                        <input type="text" id="ecPhoneNumber" name="ecPhoneNumber" value={formData.ecPhoneNumber} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="bloodGroup" className="block text-sm font-medium text-textSecondary">Blood Group:</label>
                        <input type="text" id="bloodGroup" name="bloodGroup" value={formData.bloodGroup} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="signature" className="flex items-center text-sm font-medium text-textSecondary">
                            <input type="checkbox" id="signature" name="signature" checked={formData.signature} onChange={handleChange} required className="h-4 w-4 text-indigo-600 border-gray-300 rounded mr-2" />
                            I agree to the terms and conditions
                        </label>
                    </div>
                    <button type="submit" disabled={isSubmitting || !isFormValid()} className={`w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${isFormValid() ? 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' : 'bg-gray-400 cursor-not-allowed'}`}>Submit</button>
                </form>
            )}
            {submissionMessage && (
                <p className="mt-4 text-center text-green-600">{submissionMessage}</p>
            )}
        </div>
    );
};

export default WaiverForm;

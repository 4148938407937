import React from 'react';
import { useNavigate } from 'react-router-dom';

const CalendarStrip = ({ rides }) => {
  const navigate = useNavigate();
  const today = new Date();
  
  const next30DaysRides = rides
    .filter(ride => {
      const rideDate = new Date(ride.startDateTime);
      const diffTime = rideDate - today;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays >= 0 && diffDays <= 30;
    })
    .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));

  const ridesByDate = next30DaysRides.reduce((acc, ride) => {
    const date = new Date(ride.startDateTime).toDateString();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(ride);
    return acc;
  }, {});

  const getRideTypeLabel = (ride) => {
    if (ride.timeControlType === 'race') return '🏁';
    if (ride.timeControlType === 'timetrial') return '⏱️';
    return '';
  };

  const formatRideInfo = (ride) => {
    const typeLabel = getRideTypeLabel(ride);
    return `${ride.distance}km ${typeLabel}`.trim();
  };

  const handleDateClick = (dayRides) => {
    if (dayRides.length === 1) {
      navigate(`/rides/${dayRides[0].rideId}`);
    } else if (dayRides.length > 1) {
      const firstRideDate = new Date(dayRides[0].startDateTime);
      const monthYear = firstRideDate.toLocaleString('default', { month: 'long', year: 'numeric' });
      const monthSection = document.getElementById(`month-${monthYear}`);
      if (monthSection) {
        monthSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const datesWithRides = Object.keys(ridesByDate).map(dateStr => new Date(dateStr));
  
  return (
    <div className="overflow-x-auto">
      <div className="flex items-center min-w-max p-4">
        {datesWithRides.map((date, index) => {
          const dayRides = ridesByDate[date.toDateString()] || [];
          const isToday = date.toDateString() === today.toDateString();
          
          const prevDate = index > 0 ? datesWithRides[index - 1] : today;
          const daysDiff = Math.floor((date - prevDate) / (1000 * 60 * 60 * 24));
          
          return (
            <React.Fragment key={date.toISOString()}>
              {daysDiff > 1 && (
                <div className="flex items-center mx-2">
                  <div className="h-px w-4 bg-gray-200" />
                  <div className="text-xs text-gray-400 mx-2">
                    {daysDiff - 1}d
                  </div>
                  <div className="h-px w-4 bg-gray-200" />
                </div>
              )}
              <div
                className="flex flex-col items-center group cursor-pointer hover:bg-gray-50 p-2 rounded-lg transition-colors duration-200"
                onClick={() => handleDateClick(dayRides)}
              >
                <div className="text-sm text-gray-600">
                  {date.toLocaleDateString('en-US', { weekday: 'short' })}
                </div>
                <div
                  className={`
                    w-10 h-10 rounded-full flex items-center justify-center mb-1
                    ${isToday ? 'ring-2 ring-primary ring-offset-2' : ''}
                    bg-primary text-white group-hover:bg-primary/90
                  `}
                >
                  {date.getDate()}
                </div>
                <div className="flex flex-col items-center gap-0.5">
                  {dayRides.slice(0, 2).map((ride) => (
                    <div
                      key={ride.rideId}
                      className={`
                        text-xs font-medium whitespace-nowrap px-1.5 py-0.5 rounded-full
                        ${ride.timeControlType === 'Race' ? 'bg-red-100 text-red-700' :
                          ride.timeControlType === 'TT' ? 'bg-orange-100 text-orange-700' :
                            'bg-blue-100 text-blue-700'}
                      `}
                    >
                      {formatRideInfo(ride)}
                    </div>
                  ))}
                  {dayRides.length > 2 && (
                    <div className="text-xs font-medium text-gray-500 mt-0.5">
                      +{dayRides.length - 2}
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarStrip;

import React from 'react';
import { Users, Clock, AlertTriangle } from 'lucide-react';

const LiveRidersControl = ({ riders, controlPoints }) => {
    if (!controlPoints || controlPoints.length === 0) {
        return (
            <div className="bg-yellow-50 border border-yellow-200 p-4 rounded-lg">
                <p className="text-yellow-700">No control points configured for this ride.</p>
            </div>
        );
    }

    // Helper function to check if rider has all previous controls
    const hasPreviousControls = (rider, currentControlNumber) => {
        // For CP1, no previous controls needed
        if (currentControlNumber === 1) return true;

        // Check all previous control points
        for (let i = 1; i < currentControlNumber; i++) {
            const hasThisControl = rider.ControlPoints?.some(cp => 
                parseInt(cp.ControlPointNumber) === i
            );
            if (!hasThisControl) return false;
        }
        return true;
    };

    const controlPointStats = controlPoints.map((controlPoint, index) => {
        const controlNumber = index + 1;
        
        const ridersAtControl = riders
            .filter(rider => rider.ControlPoints?.some(cp => 
                parseInt(cp.ControlPointNumber) === controlNumber
            ))
            .map(rider => {
                const checkIn = rider.ControlPoints.find(cp => 
                    parseInt(cp.ControlPointNumber) === controlNumber
                );
                return {
                    name: rider.Name,
                    urn: rider.URN,
                    timestamp: new Date(checkIn.Timestamp),
                    isAdminCheckIn: checkIn.isAdminCheckIn,
                    imageUrl: checkIn.ImageUrl,
                    hasPreviousControls: hasPreviousControls(rider, controlNumber),
                    missingControls: Array.from({ length: controlNumber - 1 }, (_, i) => i + 1)
                        .filter(num => !rider.ControlPoints?.some(cp => parseInt(cp.ControlPointNumber) === num))
                };
            })
            .sort((a, b) => a.timestamp - b.timestamp);

        return {
            number: controlNumber,
            name: controlPoint.description,
            totalRiders: riders.length,
            checkedInCount: ridersAtControl.length,
            percentage: Math.round((ridersAtControl.length / riders.length) * 100) || 0,
            riders: ridersAtControl
        };
    });

    const formatTime = (date) => {
        if (!date) return '-';
        return date.toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            month: 'short',
            day: 'numeric'
        });
    };

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {controlPointStats.map((control) => (
                <div key={control.number} className="bg-white rounded-lg border border-gray-200 overflow-hidden shadow-sm">
                    <div className="px-4 py-3 bg-gray-50 border-b border-gray-200">
                        <h3 className="text-lg font-medium text-gray-900">
                            CP{control.number}: {control.name}
                        </h3>
                    </div>
                    <div className="p-4 space-y-4">
                        <div>
                            <div className="flex justify-between text-sm text-gray-600 mb-1">
                                <span className="flex items-center">
                                    <Users className="w-4 h-4 mr-1" />
                                    {control.checkedInCount} / {control.totalRiders} riders
                                </span>
                                <span>{control.percentage}%</span>
                            </div>
                            <div className="w-full bg-gray-200 rounded-full h-2">
                                <div
                                    className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                                    style={{ width: `${control.percentage}%` }}
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <h4 className="text-sm font-medium text-gray-700 mb-2">Check-in Details:</h4>
                            <div className="max-h-96 overflow-y-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50 sticky top-0">
                                        <tr>
                                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
                                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">URN</th>
                                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {control.riders.map((rider, idx) => (
                                            <tr key={idx} 
                                                className={`hover:bg-gray-50 ${!rider.hasPreviousControls ? 'bg-red-50' : ''}`}
                                                title={!rider.hasPreviousControls ? `Missing Control Points: ${rider.missingControls.map(cp => `CP${cp}`).join(', ')}` : ''}
                                            >
                                                <td className="px-3 py-2 text-sm text-gray-900 whitespace-nowrap">
                                                    {formatTime(rider.timestamp)}
                                                </td>
                                                <td className="px-3 py-2 text-sm text-gray-900 whitespace-nowrap">
                                                    {rider.urn || 'N/A'}
                                                </td>
                                                <td className="px-3 py-2 text-sm text-gray-900">
                                                    {rider.name || 'Unknown'}
                                                </td>
                                                <td className="px-3 py-2 text-sm whitespace-nowrap">
                                                    {rider.isAdminCheckIn ? (
                                                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                                                            Admin
                                                        </span>
                                                    ) : (
                                                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                                                            Auto
                                                        </span>
                                                    )}
                                                </td>
                                                <td className="px-3 py-2 text-sm whitespace-nowrap">
                                                    {!rider.hasPreviousControls && (
                                                        <div className="flex items-center text-red-600 gap-1" title={`Missing: ${rider.missingControls.map(cp => `CP${cp}`).join(', ')}`}>
                                                            <AlertTriangle className="w-4 h-4" />
                                                            <span className="text-xs">Missing Previous CP</span>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {control.riders.length === 0 && (
                                    <div className="text-center py-4 text-sm text-gray-500">
                                        No check-ins yet
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default LiveRidersControl;

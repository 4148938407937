import React from 'react';

function BankDetailsForm({ formData, handleChange, prevStep, handleSubmit }) {
    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <h2 className="text-xl font-bold">Bank Details</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <label>Bank Account Number:</label>
                    <input type="text" name="bankAccountNumber" value={formData.bankAccountNumber} onChange={handleChange} className="input" required />
                </div>
                <div>
                    <label>IFSC Code:</label>
                    <input type="text" name="ifscCode" value={formData.ifscCode} onChange={handleChange} className="input" required />
                </div>
                <div>
                    <label>Beneficiary Name:</label>
                    <input type="text" name="beneficiaryName" value={formData.beneficiaryName} onChange={handleChange} className="input" required />
                </div>
                <div className="flex items-center">
                    <input type="checkbox" name="termsAccepted" checked={formData.termsAccepted} onChange={handleChange} required />
                    <label className="ml-2">Accept Terms and Conditions</label>
                </div>
            </div>
            <div className="flex justify-between">
                <button type="button" onClick={prevStep} className="btn-secondary">Back</button>
                <button type="submit" className="btn-primary">Submit</button>
            </div>
        </form>
    );
}

export default BankDetailsForm;

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import RideHeader from '../RideHeader';
import { FaArrowLeft, FaList } from 'react-icons/fa';

const AdminLayout = ({ children, title }) => {
  const { rideId } = useParams();

  return (
    <>
      <RideHeader />
      <div className="max-w-6xl mx-auto p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-4">
            <Link
              to={`/rides/${rideId}/admin`}
              className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-800 transition-colors"
            >
              <FaArrowLeft className="text-sm" />
              <span>Back to Admin Dashboard</span>
            </Link>
            <div className="h-6 w-px bg-gray-300" />
            <Link
              to="/owner-dashboard/rides"
              className="inline-flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors"
            >
              <FaList />
              <span>All Club Rides</span>
            </Link>
          </div>
          <div className="text-sm text-gray-500">
            Admin Panel
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-6">
          {title && <h1 className="text-2xl font-bold mb-6">{title}</h1>}
          {children}
        </div>
      </div>
    </>
  );
};

export default AdminLayout;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import userPool from '../config/cognitoConfig';
import { useAuth } from '../context/AuthContext';
import { updateLocalStorageAfterLogin } from '../lib/storage';

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useAuth();

  useEffect(() => {
    // if (state.isAuthenticated) {
    //   navigate('/dashboard');
    // }
  }, [state.isAuthenticated, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleVerificationChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    const authenticationDetails = new AuthenticationDetails({
      Username: formData.email,
      Password: formData.password,
    });

    const userData = {
      Username: formData.email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        console.log('Login successful:');

        cognitoUser.getUserAttributes((err, attributes) => {
          if (err) {
            console.error('Error fetching user attributes:', err);
            setMessage('Error fetching user details.');
            setLoading(false);
            return;
          }

          const { userId, userType, name, accessToken, idToken, refreshToken, accessTokenPayload, idTokenPayload, refreshTokenPayload } = updateLocalStorageAfterLogin(result, attributes);

          dispatch({
            type: 'LOGIN',
            payload: {
              user: { email: formData.email, userType },
              token: accessToken,
              idToken,
              refreshToken,
              accessTokenPayload,
              idTokenPayload,
              refreshTokenPayload,
              userId,
              name,
            },
          });

          const redirectUrl = new URLSearchParams(location.search).get('redirect');
          if (redirectUrl) {
            navigate(redirectUrl);
          } else {
            if (userType === 'ClubOwner') {
              navigate('/clubs');
            } else {
              navigate('/dashboard');
            }
          }
        });
      },
      onFailure: (err) => {
        console.error('Login error:', err);
        if (err.code === 'UserNotConfirmedException') {
          setShowVerification(true);
          setMessage('Account not verified. Please enter the verification code sent to your email.');
        } else {
          setMessage(`Login error: ${err.message}`);
        }
        setLoading(false);
      },
    });
  };

  const handleVerificationSubmit = (e) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    const userData = {
      Username: formData.email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
      if (err) {
        console.error('Verification error:', err);
        setMessage(`Verification error: ${err.message}`);
        setLoading(false);
        return;
      }
      console.log('Verification successful:', result);
      setMessage('Verification successful! You can now log in.');
      setShowVerification(false);
      setLoading(false);
    });
  };

  const queryParams = location.search;

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
      <h1 className="text-2xl font-semibold text-primary mb-4">Login</h1>
      {message && <p className={message.includes('error') ? 'text-red-500' : 'text-green-500'}>{message}</p>}
      {!showVerification ? (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded"
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded"
          />
          <button
            type="submit"
            className="bg-primary text-white py-2 rounded hover:bg-primary-dark transition-all"
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      ) : (
        <form onSubmit={handleVerificationSubmit} className="flex flex-col gap-4">
          <input
            type="text"
            placeholder="Verification Code"
            value={verificationCode}
            onChange={handleVerificationChange}
            required
            className="p-2 border border-gray-300 rounded"
          />
          <button
            type="submit"
            className="bg-primary text-white py-2 rounded hover:bg-primary-dark transition-all"
            disabled={loading}
          >
            {loading ? 'Verifying...' : 'Verify'}
          </button>
        </form>
      )}
      <p className="text-primary mt-4">
        Don't have an account? <Link to={`/create-account${queryParams}`} className="hover:text-secondary transition-all">Register here</Link>.
      </p>
      <p className="text-primary mt-4">
        Forgot your password? <Link to={`/forgot-password${queryParams}`} className="hover:text-secondary transition-all">Reset it here</Link>.
      </p>
    </div>
  );
}

export default Login;

import React from 'react';

function LeaderboardCard({ leaderboard }) {
    if (!leaderboard || !leaderboard.byRides || leaderboard.byRides.length === 0) {
        return null;
    }

    return (
        <div className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Top Riders</h3>
            <div className="space-y-4">
                {leaderboard.byRides.slice(0, 5).map((rider) => (
                    <div key={rider.id} className="flex items-center justify-between p-2 hover:bg-gray-100 rounded">
                        <div className="flex items-center space-x-4">
                            <span className="font-semibold text-gray-600 w-6">{rider.rank}.</span>
                            <span className="text-gray-800">{rider.name}</span>
                        </div>
                        <div className="text-right">
                            <div className="text-gray-800 font-medium">{rider.totalRides} rides</div>
                            <div className="text-gray-600 text-sm">{rider.totalDistance} km</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LeaderboardCard;

export function calculateCloseTime(distance, startDateTime, type = 'BRM') {
    try {
        const startDate = new Date(startDateTime);
        
        let speedInKmph = type === 'BRM' ? 15 : 12.5;
        let timeInHours = distance / speedInKmph;
        
        const closeTime = new Date(startDate.getTime() + timeInHours * 60 * 60 * 1000);
        
        // Format to YYYY-MM-DDTHH:mm:ss without timezone
        const year = closeTime.getFullYear();
        const month = String(closeTime.getMonth() + 1).padStart(2, '0');
        const day = String(closeTime.getDate()).padStart(2, '0');
        const hours = String(closeTime.getHours()).padStart(2, '0');
        const minutes = String(closeTime.getMinutes()).padStart(2, '0');
        const seconds = String(closeTime.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    } catch (error) {
        console.error('Error in calculateCloseTime:', error);
        return '';
    }
}

export function validateControlPoint(controlPoint, index, totalDistance) {
    const errors = {};
    
    if (!controlPoint.description) {
        errors.description = 'Description is required';
    }

    if (typeof controlPoint.distance !== 'number' || isNaN(controlPoint.distance)) {
        errors.distance = 'Valid distance is required';
    } else if (controlPoint.distance < 0) {
        errors.distance = 'Distance cannot be negative';
    } else if (controlPoint.distance > totalDistance) {
        errors.distance = 'Distance cannot be greater than total distance';
    }

    if (!controlPoint.latitude || isNaN(controlPoint.latitude)) {
        errors.latitude = 'Valid latitude is required';
    }

    if (!controlPoint.longitude || isNaN(controlPoint.longitude)) {
        errors.longitude = 'Valid longitude is required';
    }

    return errors;
}

import React from 'react';

const HowItWorks = () => {
  return (
    <div className="mt-20 bg-gray-100 py-12">
      <h2 className="text-4xl font-extrabold text-center mb-12">How It Works</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-2xl font-bold mb-4">Sign Up</h3>
          <p className="text-gray-600">Create an account to get started.</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-2xl font-bold mb-4">Find a Ride</h3>
          <p className="text-gray-600">Browse and join rides that interest you.</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-2xl font-bold mb-4">Ride & Enjoy</h3>
          <p className="text-gray-600">Enjoy the ride and connect with others.</p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;

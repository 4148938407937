import React from 'react';
import { Link } from 'react-router-dom';

const InfoCards = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
      <div className="bg-white rounded-lg shadow-md p-6 text-center">
        <h2 className="text-2xl font-bold mb-4">Find Rides</h2>
        <p className="text-gray-600 mb-6">Discover new and exciting rides in your area.</p>
        <Link to="/rides" className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-primary-dark transition">Browse Rides</Link>
      </div>
      <div className="bg-white rounded-lg shadow-md p-6 text-center">
        <h2 className="text-2xl font-bold mb-4">Join a Community</h2>
        <p className="text-gray-600 mb-6">Connect with other cyclists and share your passion.</p>
        <Link to="/community" className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-primary-dark transition">Join Now</Link>
      </div>
      <div className="bg-white rounded-lg shadow-md p-6 text-center">
        <h2 className="text-2xl font-bold mb-4">Track Your Progress</h2>
        <p className="text-gray-600 mb-6">Monitor your performance and achieve your goals.</p>
        <Link to="/progress" className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-primary-dark transition">Track Now</Link>
      </div>
    </div>
  );
};

export default InfoCards;

import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const TruncatedText = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);
  
  // Set max-height to roughly 15 lines of text (assuming ~24px per line)
  const maxHeight = 360;

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > maxHeight);
    }
  }, [children]);

  return (
    <div className="relative">
      <div
        ref={contentRef}
        style={{ maxHeight: isExpanded ? contentRef.current?.scrollHeight + 'px' : maxHeight + 'px' }}
        className="prose prose-sm max-w-none transition-all duration-300 overflow-hidden"
      >
        {children}
      </div>
      
      {!isExpanded && isOverflowing && (
        <div>
          {/* Gradient overlay */}
          <div className="absolute bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-white via-white/90 to-transparent pointer-events-none" />
          
          {/* Show more button with enhanced visibility */}
          <div className="absolute bottom-0 left-0 right-0 flex justify-center pb-2">
            <button
              onClick={() => setIsExpanded(true)}
              className="px-4 py-2 bg-white shadow-md rounded-full border border-gray-200 
                       flex items-center gap-2 text-primary hover:text-primary-dark 
                       hover:shadow-lg transition-all duration-200 font-medium"
            >
              Show More <ChevronDown className="w-5 h-5" />
            </button>
          </div>
        </div>
      )}
      
      {isExpanded && isOverflowing && (
        <div className="flex justify-center mt-4">
          <button
            onClick={() => setIsExpanded(false)}
            className="px-4 py-2 bg-white shadow-md rounded-full border border-gray-200 
                     flex items-center gap-2 text-primary hover:text-primary-dark 
                     hover:shadow-lg transition-all duration-200 font-medium"
          >
            Show Less <ChevronUp className="w-5 h-5" />
          </button>
        </div>
      )}
    </div>
  );
};

export default TruncatedText;

import React from 'react';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="bg-white max-w-4xl mx-auto p-6 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold text-center text-primary mb-4">Terms of Service</h1>
        <p className="text-lg text-gray-700 mb-6">
          Welcome to Ultra-Rides. These Terms of Service ("Terms") govern your use of our website and services ("Services"). By accessing or using our Services, you agree to comply with and be bound by these Terms.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">1. Acceptance of Terms</h2>
        <p className="text-gray-600 mb-4">
          By using our Services, you agree to be bound by these Terms and any additional terms and conditions that are referenced herein or that otherwise may apply to specific sections of the Services.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">2. Changes to Terms</h2>
        <p className="text-gray-600 mb-4">
          We reserve the right to modify or update these Terms at any time. We will notify you of any changes by posting the new Terms on the Site. Your continued use of the Services after any such changes constitutes your acceptance of the new Terms.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">3. User Conduct</h2>
        <p className="text-gray-600 mb-4">
          You agree to use the Services only for lawful purposes. You are prohibited from posting or transmitting through the Services any material that violates or infringes the rights of others, or that is threatening, abusive, defamatory, invasive of privacy, vulgar, obscene, or otherwise objectionable.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">4. Intellectual Property</h2>
        <p className="text-gray-600 mb-4">
          All content included in the Services, such as text, graphics, logos, images, and software, is the property of Ultra-Rides or its content suppliers and is protected by copyright, trademark, and other laws. You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Services without the express written permission of Ultra-Rides.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">5. Refunds and Cancellations</h2>
        <p className="text-gray-600 mb-4">
          If you are not satisfied with the service, you may request a refund. Refunds will be processed within 5-7 working days, and the credited amount will reflect in your bank account accordingly. Please note that the exact time frame may vary depending on your bank and payment method.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">6. Disclaimer of Warranties</h2>
        <p className="text-gray-600 mb-4">
          The Services are provided "as is" and "as available" without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">7. Limitation of Liability</h2>
        <p className="text-gray-600 mb-4">
          In no event shall Ultra-Rides be liable for any indirect, incidental, special, consequential, or punitive damages, including, but not limited to, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Services; and (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Services by any third party.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">8. Governing Law</h2>
        <p className="text-gray-600 mb-4">
          These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Ultra-Rides is headquartered, without regard to its conflict of law principles.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">9. Contact Information</h2>
        <p className="text-gray-600 mb-4">
          If you have any questions about these Terms, please contact us at terms@ultra-rides.com, +91-XXXXXXXXXX (Indian contact number), or our office at [Office Address], India.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
import React, { useState } from 'react';
import axios from '../../../config/axiosConfig';

function RegisterRiderByURN({ rideId, onClose, onSuccess }) {
  const [urn, setUrn] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRegister = async () => {
    if (!urn) {
      setError('Please enter a URN number.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Modify the API endpoint to use the admin register URL
      const response = await axios.post(`/rides/${rideId}/admin-register/${urn}`);

      if (response.status === 200) {
        onSuccess(response.data);
        onClose();
      } else {
        setError('Failed to register rider.');
      }
    } catch (err) {
      console.error('Error registering rider:', err);
      setError(err.response?.data?.message || 'Error registering rider.');
    } finally {
      setLoading(false);
    }
  };

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50' onClick={handleBackgroundClick}>
      <div className='bg-white p-6 rounded-lg shadow-lg max-w-md w-full'>
        <h2 className='text-xl font-semibold mb-4'>Register Rider by URN</h2>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='urn'>
            URN Number
          </label>
          <input
            type='text'
            id='urn'
            name='urn'
            value={urn}
            onChange={(e) => setUrn(e.target.value)}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          />
        </div>
        {error && <p className='text-red-500 text-sm mb-2'>{error}</p>}
        <button
          onClick={handleRegister}
          disabled={loading}
          className='w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
        >
          {loading ? 'Registering...' : 'Register'}
        </button>
        <button
          onClick={onClose}
          className='w-full mt-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default RegisterRiderByURN;

import React from 'react';
import { Phone, Mail, MessageSquare, User } from 'lucide-react';

function LocationAndContactComponent({ 
  responsibleName,
  responsibleEmail,
  responsiblePhone,
  whatsappGroupUrl 
}) {
  const renderContactItem = (value, icon, href = null, label = null, customText = null) => {
    if (!value) return null;
    const Icon = icon;
    const content = (
      <div className="flex items-center gap-2 text-sm">
        <Icon className="w-4 h-4 text-gray-500 shrink-0" />
        <span className="text-gray-700">
          {label && <span className="text-gray-500 mr-2">{label}:</span>}
          {customText || value}
        </span>
      </div>
    );

    if (href) {
      return (
        <a 
          href={href} 
          className="hover:text-primary transition-colors" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {content}
        </a>
      );
    }
    return content;
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-4">
      <div className="space-y-2">
        {renderContactItem(responsibleName, User, null, "Organizer")}
        {renderContactItem(responsiblePhone, Phone, `tel:${responsiblePhone}`)}
        {renderContactItem(responsibleEmail, Mail, `mailto:${responsibleEmail}`)}
        {whatsappGroupUrl && renderContactItem(
          whatsappGroupUrl, 
          MessageSquare, 
          whatsappGroupUrl, 
          "WhatsApp Group",
          "Click to join"
        )}
      </div>
    </div>
  );
}

export default LocationAndContactComponent;

import React, { useState } from 'react';

function BulkActions({ riders, controlPoints, onBulkUpdate }) {
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState({ current: 0, total: 0 });

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371e3;
        const φ1 = (lat1 * Math.PI) / 180;
        const φ2 = (lat2 * Math.PI) / 180;
        const Δφ = ((lat2 - lat1) * Math.PI) / 180;
        const Δλ = ((lon2 - lon1) * Math.PI) / 180;
        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    const isValidControlPoint = (checkIn, controlPoint) => {
        if (!checkIn || !controlPoint || !controlPoint.gpsCoordinates) {
            return false;
        }

        const [expectedLat, expectedLon] = controlPoint.gpsCoordinates.split(',').map(coord => parseFloat(coord.trim()));
        const distance = calculateDistance(
            expectedLat,
            expectedLon,
            checkIn.Latitude,
            checkIn.Longitude
        );

        const checkInTime = new Date(checkIn.Timestamp);
        const closeTime = new Date(controlPoint.closeTime);

        return distance <= 200 && checkInTime <= closeTime;
    };

    const allControlsValid = (rider) => {
        if (!rider.ControlPoints || !Array.isArray(rider.ControlPoints)) {
            return false;
        }

        // Filter out test check-ins and ensure we have the right controls
        const validCheckIns = rider.ControlPoints.filter(cp =>
            cp.ControlPointNumber > 0 &&
            cp.ControlPointNumber <= controlPoints.length
        ).sort((a, b) => parseInt(a.ControlPointNumber) - parseInt(b.ControlPointNumber));

        // Must have the exact number of control points
        if (validCheckIns.length !== controlPoints.length) {
            return false;
        }

        // Check if control points are sequential and valid
        return validCheckIns.every((checkIn, index) => {
            const controlPoint = controlPoints[index];
            const correctNumber = index + 1;

            return parseInt(checkIn.ControlPointNumber) === correctNumber &&
                checkIn.ControlPointName === controlPoint.description &&
                isValidControlPoint(checkIn, controlPoint);
        });
    };

    const processBulkUpdates = async (ridersToUpdate, status, options = {}) => {
        const { skipConfirm = false } = options;
        setIsProcessing(true);
        setProgress({ current: 0, total: ridersToUpdate.length });

        for (let i = 0; i < ridersToUpdate.length; i++) {
            const rider = ridersToUpdate[i];

            let totalTime = '';
            if (status === 'Finished' && rider.ControlPoints?.length > 0) {
                const validCheckIns = rider.ControlPoints.filter(cp => cp.ControlPointNumber > 0)
                    .sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                if (validCheckIns.length >= 2) {
                    const firstTime = new Date(validCheckIns[0].Timestamp);
                    const lastTime = new Date(validCheckIns[validCheckIns.length - 1].Timestamp);
                    const duration = (lastTime - firstTime) / 1000;
                    const hours = Math.floor(duration / 3600);
                    const minutes = Math.floor((duration % 3600) / 60);
                    totalTime = `${hours}h ${minutes}m`;
                }
            }

            await onBulkUpdate([{
                sub: rider.sub,
                status: status,
                totalTime: totalTime
            }]);

            setProgress(prev => ({ ...prev, current: i + 1 }));
            await new Promise(resolve => setTimeout(resolve, 500));
        }

        setIsProcessing(false);
        setProgress({ current: 0, total: 0 });
    };

    const handleBulkDNS = () => {
        const ridersWithNoPracticalCheckins = riders.filter(rider => {
            if (!rider.ControlPoints) return true;

            const validCheckIns = rider.ControlPoints.filter(cp =>
                cp.ControlPointNumber > 0 && cp.ControlPointNumber <= controlPoints.length
            );

            return validCheckIns.length === 0;
        });

        if (ridersWithNoPracticalCheckins.length === 0) {
            alert('No riders found qualifying for bulk DNS update');
            return;
        }

        const confirmed = window.confirm(
            `This will mark ${ridersWithNoPracticalCheckins.length} riders as DNS. These are riders with no check-ins at any control point. Continue?`
        );

        if (confirmed) {
            processBulkUpdates(ridersWithNoPracticalCheckins, 'DNS');
        }
    };

    const handleBulkDNF = () => {
        const ridersForDNF = riders.filter(rider => {
            // Skip if already has a result
            if (rider.Result) return false;

            // Skip if they have not started (no valid check-ins)
            const validCheckIns = (rider.ControlPoints || []).filter(cp =>
                cp.ControlPointNumber > 0 && cp.ControlPointNumber <= controlPoints.length
            );

            if (validCheckIns.length === 0) return false;

            // If they have all controls valid, they shouldn't be DNF
            if (allControlsValid(rider)) return false;

            // If they have at least one valid control but not all valid controls,
            // they should be DNF
            return true;
        });

        if (ridersForDNF.length === 0) {
            alert('No riders found qualifying for bulk DNF update');
            return;
        }

        // Create a list of URNs with details about missing/invalid controls
        const urnList = ridersForDNF
            .sort((a, b) => (a.URN || '').localeCompare(b.URN || ''))
            .map(rider => rider.URN)
            .join('\n');

        const confirmed = window.confirm(
            `This will mark ${ridersForDNF.length} riders as DNF. These are riders who started but have either invalid check-ins or missing controls.\n\nRiders to be marked DNF (URNs):\n${urnList}\n\nContinue?`
        );

        if (confirmed) {
            processBulkUpdates(ridersForDNF, 'DNF');
        }
    };

    const handleBulkFinished = () => {
        const ridersWithAllGreen = riders.filter(rider => {
            if (rider.Result) return false;
            return allControlsValid(rider);
        });

        if (ridersWithAllGreen.length === 0) {
            alert('No riders found qualifying for bulk Finished update');
            return;
        }

        const confirmed = window.confirm(
            `This will mark ${ridersWithAllGreen.length} riders as Finished. Continue?`
        );

        if (confirmed) {
            processBulkUpdates(ridersWithAllGreen, 'Finished');
        }
    };

    const handleClearAllResults = async () => {
        const ridersWithResults = riders.filter(rider => rider.Result || rider.TotalTime);

        if (ridersWithResults.length === 0) {
            alert('No riders found with results to clear');
            return;
        }

        const confirmed = window.confirm(
            `This will clear the results and finish times for ${ridersWithResults.length} riders. This cannot be undone. Are you sure you want to continue?`
        );

        if (confirmed) {
            await processBulkUpdates(ridersWithResults, '', { skipConfirm: true });
            alert('All results have been cleared');
        }
    };

    return (
        <div className="mb-4 space-y-4">
            <div className="flex flex-wrap gap-4">
                <button
                    onClick={handleBulkDNS}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                    disabled={isProcessing}
                    title="Mark riders who haven't checked in at any control point as DNS"
                >
                    Bulk DNS
                </button>
                <button
                    onClick={handleBulkDNF}
                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                    disabled={isProcessing}
                    title="Mark riders who started but didn't finish as DNF"
                >
                    Bulk DNF
                </button>
                <button
                    onClick={handleBulkFinished}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                    disabled={isProcessing}
                    title="Mark riders who completed all control points successfully as Finished"
                >
                    Bulk Finished
                </button>
                <button
                    onClick={handleClearAllResults}
                    className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                    disabled={isProcessing}
                    title="Clear results and finish times for all riders"
                >
                    Clear All Results
                </button>
            </div>

            {isProcessing && (
                <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t shadow-lg" style={{ zIndex: 1000 }}>
                    <div className="max-w-4xl mx-auto">
                        <div className="bg-blue-50 p-4 rounded-lg shadow">
                            <div className="flex justify-between mb-2">
                                <span className="font-medium">Processing Updates...</span>
                                <span className="text-blue-600">{progress.current} / {progress.total}</span>
                            </div>
                            <div className="w-full bg-gray-200 rounded-full h-2.5">
                                <div
                                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                                    style={{ width: `${(progress.current / progress.total) * 100}%` }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default BulkActions;
import React from 'react';

const DevTools = ({ loadTestData, resetForm }) => (
    <div className="fixed bottom-4 right-4 bg-gray-800 text-white p-4 rounded-lg shadow-lg z-50">
        <h3 className="font-medium mb-2">Dev Tools</h3>
        <div className="space-y-2">
            <button
                onClick={() => loadTestData('audax')}
                className="block w-full px-3 py-1 bg-blue-500 rounded hover:bg-blue-600"
            >
                Load Audax Test
            </button>
            <button
                onClick={() => loadTestData('race')}
                className="block w-full px-3 py-1 bg-green-500 rounded hover:bg-green-600"
            >
                Load Race Test
            </button>
            <button
                onClick={() => loadTestData('time-trial')}
                className="block w-full px-3 py-1 bg-purple-500 rounded hover:bg-purple-600"
            >
                Load Time Trial Test
            </button>
            <button
                onClick={resetForm}
                className="block w-full px-3 py-1 bg-red-500 rounded hover:bg-red-600"
            >
                Clear Form
            </button>
        </div>
    </div>
);

export default DevTools;

import React from 'react';

function AccountCreationForm({ formData, handleChange, nextStep }) {
  return (
    <form onSubmit={(e) => { e.preventDefault(); nextStep(); }} className="space-y-4">
      <h2 className="text-xl font-bold">Account Creation</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label>Phone Number:</label>
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>Legal Business Name:</label>
          <input type="text" name="legalBusinessName" value={formData.legalBusinessName} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>Business Type:</label>
          <input type="text" name="businessType" value={formData.businessType} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>Contact Name:</label>
          <input type="text" name="contactName" value={formData.contactName} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>Street Address:</label>
          <input type="text" name="streetAddress" value={formData.streetAddress} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>City:</label>
          <input type="text" name="city" value={formData.city} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>State:</label>
          <input type="text" name="state" value={formData.state} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>Postal Code:</label>
          <input type="text" name="postalCode" value={formData.postalCode} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>Country:</label>
          <input type="text" name="country" value={formData.country} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>PAN Number:</label>
          <input type="text" name="panNumber" value={formData.panNumber} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>GST Number:</label>
          <input type="text" name="gstNumber" value={formData.gstNumber} onChange={handleChange} className="input" required />
        </div>
      </div>
      <button type="submit" className="btn-primary">Next</button>
    </form>
  );
}

export default AccountCreationForm;

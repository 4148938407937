import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import axios from '../config/axiosConfig';

function Breadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const [clubName, setClubName] = useState('');

  useEffect(() => {
    const fetchClubName = async () => {
      const paths = location.pathname.split('/').filter((x) => x);

      if (paths.includes('clubs') && paths[1]) {
        try {
          const clubResponse = await axios.get(`/clubs/${paths[1]}`);
          setClubName(clubResponse.data.name);
        } catch (error) {
          console.error('Error fetching club name:', error);
        }
      }
    };

    fetchClubName();
  }, [location.pathname]);

  if (location.pathname === '/') return null; // Remove breadcrumbs on home page

  const truncatePathname = (path) => {
    if (path.length > 15) {
      return `${path.slice(0, 7)}...${path.slice(-5)}`;
    }
    return path;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <nav className="bg-white p-3 rounded-lg shadow-md mb-4" aria-label="breadcrumb">
        <ol className="list-reset flex text-gray-600">
          <li>
            <Link to="/" className="text-primary hover:underline flex items-center">
              <FaHome className="mr-1" />
              Home
            </Link>
          </li>
          {pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isClubPath = pathnames[index - 1] === 'clubs';
            const isRidePath = pathnames[index - 1] === 'rides';
            const displayName = isClubPath
              ? clubName || capitalizeFirstLetter(value)
              : isRidePath
                ? 'Ride'
                : capitalizeFirstLetter(value);

            return (
              <li key={to} className="flex items-center">
                <span className="mx-2 text-primary">/</span>
                {index === pathnames.length - 1 ? (
                  <span className="text-gray-500">{truncatePathname(displayName)}</span>
                ) : (
                  <Link to={to} className="text-primary hover:underline">
                    {truncatePathname(displayName)}
                  </Link>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
}

export default Breadcrumbs;

import React, { useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, Window, ChannelHeader, MessageList, MessageInput, Thread } from 'stream-chat-react';
import axiosInstance from '../../config/axiosConfig';
import { getLocalStorage } from '../../lib/storage';
import CustomMessage from './CustomMessage.js'; // Import your custom message component
import { useAuth } from '../../context/AuthContext'; // Import useAuth hook for authentication check

function ChatTab({ rideId, rideName }) { // Accept rideName as a prop
    const { state } = useAuth(); // Access authentication state
    const [client, setClient] = useState(null);
    const [channel, setChannel] = useState(null);
    const [error, setError] = useState(null); // State to handle errors

    useEffect(() => {
        const initChat = async () => {
            try {
                if (!state.isAuthenticated) {
                    setError('You must be logged in to access the chat.');
                    return;
                }

                const idTokenPayload = JSON.parse(getLocalStorage('idTokenPayload'));

                // Extract URN and name from idTokenPayload
                const urn = idTokenPayload?.['custom:userId'];
                const name = idTokenPayload?.name || `User ${rideId}`;

                if (!urn) {
                    throw new Error('URN not found in ID token');
                }

                const validUserId = urn.replace(/\./g, '_');

                const response = await axiosInstance.get('/generate-chat-token', {
                    params: { user_id: validUserId }
                });
                const userToken = response.data.token;

                if (!userToken) {
                    throw new Error('Token not received from server');
                }

                const chatClient = StreamChat.getInstance('mjf6ztvrx7zd');
                await chatClient.connectUser(
                    {
                        id: validUserId,
                        name: name,
                    },
                    userToken
                );

                const rideChannel = chatClient.channel('messaging', `ride-${rideId}`, {
                    name: `Chat for ${rideName}`, // Use rideName instead of rideId
                    members: [validUserId], // Ensure the user is a member of the channel
                });
                await rideChannel.watch();

                setClient(chatClient);
                setChannel(rideChannel);
            } catch (error) {
                console.error('Error initializing chat:', error);
                setError('Failed to load chat. Please try again later.');
            }
        };

        initChat();

        return () => {
            if (client) {
                client.disconnectUser();
            }
        };
    }, [rideId, rideName, state.isAuthenticated]);

    if (error) return <div>{error}</div>;
    if (!channel) return <div>Loading chat...</div>;

    return (
        <Chat client={client} theme="messaging light">
            <Channel channel={channel} Message={CustomMessage}>
                <Window>
                    <ChannelHeader title={`Chat for ${rideName}`} /> {/* Set the title here */}
                    <MessageList />
                    <MessageInput />
                </Window>
                <Thread />
            </Channel>
        </Chat>
    );
}

export default ChatTab;

import React from 'react';
import { Calendar, Route, Settings, Eye } from 'lucide-react';

const WizardProgress = ({ steps, currentStep }) => {
    const progress = ((currentStep - 1) / (steps.length - 1)) * 100;

    const getStepStyle = (index) => {
        // Step is completed
        if (currentStep > index + 1) {
            return "text-blue-500";
        }
        // Current step
        if (currentStep === index + 1) {
            return "text-blue-700 bg-blue-50 border-blue-500";
        }
        // Future step
        return "text-gray-400";
    };

    const getIconContainerStyle = (index) => {
        const baseStyle = "w-8 h-8 rounded-full border-2 flex items-center justify-center mr-2";
        if (currentStep > index + 1) {
            return `${baseStyle} border-blue-500`;
        }
        if (currentStep === index + 1) {
            return `${baseStyle} border-blue-500 bg-blue-500 text-white`;
        }
        return `${baseStyle} border-gray-300`;
    };

    return (
        <div className="mb-8">
            <div className="flex justify-between mb-4">
                {steps.map((label, index) => (
                    <div 
                        key={label} 
                        className={`flex items-center px-3 py-2 rounded-lg ${getStepStyle(index)}`}
                    >
                        <div className={getIconContainerStyle(index)}>
                            {index === 0 ? <Calendar size={16} /> :
                                index === 1 ? <Route size={16} /> :
                                    index === 2 && steps.length === 4 ? <Settings size={16} /> :
                                        <Eye size={16} />}
                        </div>
                        <span className="text-sm font-medium">{label}</span>
                    </div>
                ))}
            </div>
            <div className="h-2 bg-gray-200 rounded-full">
                <div
                    className="h-full bg-blue-500 rounded-full transition-all duration-300 ease-in-out"
                    style={{ width: `${progress}%` }}
                />
            </div>
        </div>
    );
};

export default WizardProgress;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import AdminLayout from './AdminLayout';
import ErrorMessage from '../Message';
import AdminAccessCheck from './AdminAccessCheck';
import { FaGlobe, FaLink } from 'react-icons/fa';

function RideVisibility() {
   const { rideId } = useParams();
   const queryClient = useQueryClient();
   const [isPublic, setIsPublic] = useState(true);
   const [clubId, setClubId] = useState('');
   const [saveError, setSaveError] = useState('');
   const [isClubOwner, setIsClubOwner] = useState(false);

   const { data: ride, error, isLoading } = useQuery({
       queryKey: ['rideDetails', rideId],
       queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
       staleTime: 1000 * 60 * 5,
   });

   useEffect(() => {
       if (ride) {
           setIsPublic(ride.rideCategory === 'allRides');
           setClubId(ride.clubId);
           if (ride.user) {
               setIsClubOwner(ride.user.isClubOwner);
           }
       }
   }, [ride]);

   const mutation = useMutation({
       mutationFn: async ({ isPublic, clubId }) => {
           return axios.patch(`/rides/${rideId}`, {
               rideCategory: isPublic ? 'allRides' : 'private',
               clubId
           });
       },
       onSuccess: () => {
           queryClient.invalidateQueries(['rideDetails', rideId]);
           setSaveError('');
       },
       onError: (error) => {
           setSaveError('Failed to update visibility settings');
       }
   });

   const handleVisibilityChange = (event) => {
       const newVisibility = event.target.checked;
       setIsPublic(newVisibility);
       mutation.mutate({
           isPublic: newVisibility,
           clubId
       });
   };

   if (isLoading) return <div>Loading...</div>;
   if (error) return <ErrorMessage message="Error fetching ride details." />;

   return (
       <AdminAccessCheck isAdmin={isClubOwner}>
           <AdminLayout title="Ride Visibility Settings">
               <div className="flex items-center space-x-4">
                   <label className="relative inline-flex items-center cursor-pointer">
                       <input
                           type="checkbox"
                           className="sr-only peer"
                           checked={isPublic}
                           onChange={handleVisibilityChange}
                       />
                       <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                   </label>
                   <span className="text-lg font-medium flex items-center">
                       {isPublic ? (
                           <>
                               <FaGlobe className="inline-block mr-2" />
                               Public
                           </>
                       ) : (
                           <>
                               <FaLink className="inline-block mr-2" />
                               Anyone with the link
                           </>
                       )}
                   </span>
               </div>

               <p className="text-gray-600 mt-2">
                   {isPublic
                       ? 'This ride is visible in public rides listing and can be discovered by all users.'
                       : 'This ride is not listed publicly. Only people with the direct link can access it.'}
               </p>

               <div className={`mt-4 p-4 rounded-lg ${isPublic ? 'bg-green-50 border border-green-200' : 'bg-yellow-50 border border-yellow-200'}`}>
                   <p className={`text-sm ${isPublic ? 'text-green-700' : 'text-yellow-700'}`}>
                       {isPublic
                           ? 'Visible in rides listing'
                           : 'Not listed publicly'}
                   </p>
               </div>

               {saveError && <ErrorMessage message={saveError} />}
           </AdminLayout>
       </AdminAccessCheck>
   );
}

export default RideVisibility;

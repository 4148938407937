// src/components/RideDetails/RideCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

function RideCard({ ride }) {
    const formattedDate = dayjs(ride.startDateTime).format('MMMM D, YYYY h:mm A');
    return (
        <Link to={`/rides/${ride.rideId}`} className="no-underline">
            <div className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200 hover:bg-gray-100">
                <h2 className="text-xl font-semibold text-textPrimary mb-2">{ride.name}</h2>
                <p className="text-textSecondary mb-4">{ride.description}</p>
                <p className="text-textSecondary"><strong>Date:</strong> {formattedDate}</p>
                <p className="text-textSecondary"><strong>Distance:</strong> {ride.distance} km</p>
            </div>
        </Link>
    );
}

export default RideCard;

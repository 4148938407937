import React from 'react';

const SimpleBadge = ({ ride }) => {
  if (ride.rideDistance >= 100 && ride.Result === 'Finished') {
    let badgeImage = '';
    console.log(ride.rideDistance)

    switch (ride.rideDistance) {
      case 100:
        badgeImage = ride.timeControlType !== 'BRM' ? '/100kBadge_challenge.png' : '/100kBadge.png';
        break;
      case 150:
        badgeImage = '/150kBadge.png';
        break;
      case 200:
        badgeImage = '/200kBadge.png';
        break;
      case 250:
        badgeImage = ride.timeControlType !== 'BRM' ? '/250Badge12h.png' : '/250kBadge.png';
        break;
      case 300:
        badgeImage = '/300kBadge.png';
        break;
      default:
        return null;
    }

    return (
      <div className="flex items-center space-x-2">
        <img src={badgeImage} alt="${ride.rideDistance}k Badge" className="w-32 h-32 object-contain" />
      </div>
    );
  }
  return null;
};

export default SimpleBadge;

import React from 'react';
import { FaBicycle, FaUsers, FaMapMarkedAlt } from 'react-icons/fa';

const features = [
    {
        icon: <FaBicycle />,
        title: 'Epic Rides',
        description: 'Explore amazing routes and push your limits with our curated rides.',
    },
    {
        icon: <FaUsers />,
        title: 'Community',
        description: 'Connect with fellow riders, share experiences, and ride together.',
    },
    {
        icon: <FaMapMarkedAlt />,
        title: 'Navigation',
        description: 'Get turn-by-turn navigation and stay on track during your rides.',
    },
];

const FeaturesSection = () => {
    return (
        <div className="py-16 px-8 bg-gray-100 text-center">
            <h2 className="text-4xl font-bold mb-8">Our Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {features.map((feature, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg"
                    >
                        <div className="text-6xl text-primary mb-4">{feature.icon}</div>
                        <h3 className="text-2xl font-semibold mb-2">{feature.title}</h3>
                        <p className="text-gray-600">{feature.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeaturesSection;

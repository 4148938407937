import React, { useEffect, useState } from 'react';
import axios from '../../../config/axiosConfig';

function NoCheckInDetails({ onClose, rideId, controlPointName, controlPointNumber, rider }) {
    const [checkInData, setCheckInData] = useState({
        dateTime: new Date().toISOString().slice(0, 16),
        riderName: rider.Name,
        riderURN: rider.URN,
        riderId: rider.sub,
        controlPointName: controlPointName,
        controlPointNumber: controlPointNumber ? controlPointNumber.toString() : '', // Ensure controlPointNumber is a string or an empty string
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCheckInData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleCheckIn = async () => {
        const payload = {
            ...checkInData,
            riderId: checkInData.riderId,
        };

        try {
            const response = await axios.post(`/rides/${rideId}/manual-check-in`, payload);
            if (response.status === 200) {
                alert('Check-in successful');
                onClose();
            } else {
                alert('Failed to check in');
            }
        } catch (error) {
            console.error('Error during check-in:', error);
            alert('Error during check-in');
        }
    };

    const handleBackgroundClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    useEffect(() => {
        const handleEscapeKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscapeKeyPress);

        return () => {
            document.removeEventListener('keydown', handleEscapeKeyPress);
        };
    }, [onClose]);

    return (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50' onClick={handleBackgroundClick}>
            <div className='bg-white p-6 rounded-lg shadow-lg max-w-md w-full'>
                <h2 className='text-xl font-semibold mb-4'>No Check-In Data Available</h2>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='dateTime'>
                        Date and Time
                    </label>
                    <input
                        type='datetime-local'
                        id='dateTime'
                        name='dateTime'
                        value={checkInData.dateTime}
                        onChange={handleInputChange}
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='riderName'>
                        Rider Name
                    </label>
                    <input
                        type='text'
                        id='riderName'
                        name='riderName'
                        value={checkInData.riderName}
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        readOnly
                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='riderURN'>
                        Rider URN
                    </label>
                    <input
                        type='text'
                        id='riderURN'
                        name='riderURN'
                        value={checkInData.riderURN}
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        readOnly
                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='controlPointName'>
                        Control Point Name
                    </label>
                    <input
                        type='text'
                        id='controlPointName'
                        name='controlPointName'
                        value={checkInData.controlPointName}
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        readOnly
                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='controlPointNumber'>
                        Control Point Number
                    </label>
                    <input
                        type='text'
                        id='controlPointNumber'
                        name='controlPointNumber'
                        value={checkInData.controlPointNumber}
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        readOnly
                    />
                </div>
                <button
                    onClick={handleCheckIn}
                    className='w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                >
                    Check In
                </button>
                <button
                    onClick={onClose}
                    className='w-full mt-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                >
                    Close
                </button>
            </div>
        </div>
    );
}

export default NoCheckInDetails;

import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import axios from '../config/axiosConfig';
import ProfileHeader from './Profile/ProfileHeader';
import ProfileField from './Profile/ProfileField';
import ContactInformationForm from './Profile/ContactInformationForm';
import AvatarEditorComponent from './Profile/AvatarEditorComponent';
import PDFDownloadButton from './Profile/PDFDownloadButton';
import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Toast from './Profile/Toast';

function Profile() {
  const { state, dispatch } = useAuth();
  const { idTokenPayload, user } = state;
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState('');

  useEffect(() => {
    if (idTokenPayload || user) {
      setProfileImageUrl(idTokenPayload?.picture || '');
    }
  }, [idTokenPayload, user]);

  const handleSave = async (data) => {
    try {
      if (data['custom:bio']) {
        const bioBytes = new TextEncoder().encode(data['custom:bio']).length;
        if (bioBytes < 20 || bioBytes > 512) {
          setError('Biography must be between 20 and 512 bytes.');
          return;
        }
      } else {
        console.log("custom:bio not found in data");
      }

      const response = await axios.patch('/users', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setMessage('Information updated successfully.');
        setError('');

        const updatedPayload = {
          ...idTokenPayload,
          ...data,
        };

        const updatedUser = {
          ...user,
          ...data,
        };

        dispatch({ type: 'UPDATE_PROFILE', payload: { idTokenPayload: updatedPayload, user: updatedUser } });

        setProfileImageUrl(updatedPayload.picture || '');
      } else {
        setMessage('Failed to update information.');
        setError(response.data.message || 'Error updating information.');
      }
    } catch (error) {
      setMessage('Error updating information.');
      setError(error.response?.data?.message || 'Error updating information.');
    }
  };

  const handleAccountDeletion = () => {
    setMessage('Your account has been marked for deletion.');
    setError('');

    // Delay logout by 3 seconds to display the message
    setTimeout(() => {
      dispatch({ type: 'LOGOUT' });
    }, 3000);
  };

  return (
    <div className="min-h-screen bg-lightGray p-8">
      <div className="bg-white w-full max-w-4xl mx-auto p-8 rounded-lg shadow-lg border border-gray-300">
        <ProfileHeader />
        <div className="bg-lightContainer p-8 rounded-lg border border-gray-300">
          <div className="flex items-center mb-4">
            <FaUser className="text-primary mr-2" />
            <h2 className="text-xl font-semibold text-primary">Public Information</h2>
          </div>
          <div className="flex justify-center mb-8">
            <AvatarEditorComponent
              profileImage={profileImage}
              setProfileImage={setProfileImage}
              setProfileImageUrl={setProfileImageUrl}
              setMessage={setMessage}
              setError={setError}
              initialImageUrl={profileImageUrl}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <ProfileField label="Name" field="name" value={idTokenPayload?.name || user?.name || ''} onSave={handleSave} editable={true} />
            <ProfileField label="URN (Ultra-Rides Number)" field="custom:userId" value={idTokenPayload?.['custom:userId'] || user?.['custom:userId'] || ''} />
          </div>
          <ProfileField label="Biography" field="custom:bio" value={idTokenPayload?.['custom:bio'] || user?.['custom:bio'] || ''} onSave={handleSave} editable={true} multiline={true} />
        </div>
        <div className="mt-8">
          <ContactInformationForm
            phoneNumber={idTokenPayload?.phone_number || ''}
            emergencyContactName={idTokenPayload?.['custom:ec_name'] || ''}
            emergencyContactPhone={idTokenPayload?.['custom:ec_phone_number'] || ''}
            bloodGroup={idTokenPayload?.['custom:blood_group'] || ''}
            handleSave={handleSave}
            message={message}
            error={error}
          />
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center mt-8 space-y-4 sm:space-y-0">
          <Link to={`/athletes/${idTokenPayload?.['custom:userId'] || user?.['custom:userId']}/profile`} className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded transition-colors">
            View Public Profile
          </Link>
          <PDFDownloadButton leftSideData={{ customUserId: idTokenPayload?.['custom:userId'], name: idTokenPayload?.name }} idTokenPayload={idTokenPayload} />
        </div>
        <div className="mt-8">
          <button onClick={handleAccountDeletion} className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors">
            Delete Account
          </button>
        </div>
      </div>
      {message && <Toast message={message} type="success" />}
      {error && <Toast message={error} type="error" />}
    </div>
  );
}

export default Profile;

import { useState } from 'react';
import { validateStep } from '../utils/wizardUtils';

export const useWizardNavigation = (formData) => {
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState({});

    const getSteps = () => {
        return ['Basic Details', 'Route Details', 'Control Points', 'Preview'];
    };

    const handleNext = (onSubmit) => {
        const newErrors = validateStep(step, formData);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            if (step === getSteps().length) {
                onSubmit();
            } else {
                setStep(prev => prev + 1);
            }
        }
    };

    const handlePrevious = () => {
        setStep(prev => Math.max(1, prev - 1));
    };

    return {
        step,
        setStep,
        errors,
        setErrors,
        getSteps,
        handleNext,
        handlePrevious
    };
};

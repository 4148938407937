import React from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';

const ForRiders = () => {
    return (
        <div className="flex flex-col md:flex-row items-center my-12 p-8 bg-white shadow-lg rounded-lg">
            <div className="md:w-1/2 p-4 order-last md:order-first">
                <img src="/rider.jpg" alt="Rider Showing Digital Certificate" className="w-full rounded-lg shadow-md" />
            </div>
            <div className="md:w-1/2 p-4">
                <h2 className="text-4xl font-extrabold mb-4">Celebrate Your Cycling Achievements</h2>
                <p className="text-gray-600 mb-6">
                    With UltraRides, every ride is an opportunity to shine. Complete your rides, meet the control requirements, and receive permanent homologations. Showcase your cycling journey with digital certificates and a public profile page.
                </p>
                <ul className="mb-6">
                    <li className="flex items-center mb-2">
                        <FaRegCheckCircle className="text-primary mr-2" />
                        <span>Permanent Homologations: Your homologation remains with you forever.</span>
                    </li>
                    <li className="flex items-center mb-2">
                        <FaRegCheckCircle className="text-primary mr-2" />
                        <span>Digital Certificates: Receive a digital certificate for every completed ride.</span>
                    </li>
                    <li className="flex items-center mb-2">
                        <FaRegCheckCircle className="text-primary mr-2" />
                        <span>Public Profile Page: Display all your homologations and share your achievements.</span>
                    </li>
                    <li className="flex items-center mb-2">
                        <FaRegCheckCircle className="text-primary mr-2" />
                        <span>Event Participation: Easily join and participate in events hosted by clubs.</span>
                    </li>
                </ul>
                <a
                    href="/create-account"
                    className="inline-block bg-primary text-white py-3 px-8 rounded-full font-semibold text-xl hover:bg-primary-dark transition-all"
                >
                    Join as a Rider
                </a>
            </div>
        </div>
    );
};

export default ForRiders;

import React from 'react';
import NoCheckInDetails from './NoCheckInDetails';

const NoCheckInDetailsPopup = ({ onClose, rideId, controlPointName, controlPointNumber, rider }) => (
    <NoCheckInDetails
        onClose={onClose}
        rideId={rideId}
        controlPointName={controlPointName}
        controlPointNumber={controlPointNumber}
        rider={rider}
    />
);

export default NoCheckInDetailsPopup;

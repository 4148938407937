import React from 'react';
import HeroSection from './HeroSection';
import InfoCards from './InfoCards';
import Testimonials from './Testimonials';
import HowItWorks from './HowItWorks';
import FeaturesSection from './FeaturesSection';
import ForClubOwners from './ForClubOwners';
import ForRiders from './ForRiders';
import MobileApps from './MobileApps';
import { Link } from 'react-router-dom';
import { FaUsers, FaCalendarAlt, FaBicycle } from 'react-icons/fa';

const Home = () => {
  return (
    <div className="relative min-h-screen flex flex-col items-center justify-start bg-background">
      <HeroSection />

      <div className="relative z-10 my-16 px-4 md:px-8 grid grid-cols-1 lg:grid-cols-3 gap-12 text-center w-full">
        <Link to="/about" className="bg-white shadow-lg rounded-lg p-8 hover:bg-accent hover:text-white transition-all">
          <FaUsers className="text-5xl text-primary mb-4 mx-auto" />
          <h2 className="text-3xl font-semibold mb-2 text-textPrimary">About Ultra-Rides</h2>
          <p className="text-textSecondary">Learn more about our mission and how we connect riders and clubs.</p>
        </Link>
        <Link to="/rides" className="bg-white shadow-lg rounded-lg p-8 hover:bg-accent hover:text-white transition-all">
          <FaCalendarAlt className="text-5xl text-primary mb-4 mx-auto" />
          <h2 className="text-3xl font-semibold mb-2 text-textPrimary">Upcoming Rides</h2>
          <p className="text-textSecondary">Explore our upcoming rides and join us on an epic journey.</p>
        </Link>
        <div className="bg-white shadow-lg rounded-lg p-8 hover:bg-accent hover:text-white transition-all">
          <FaBicycle className="text-5xl text-primary mb-4 mx-auto" />
          <h2 className="text-3xl font-semibold mb-2 text-textPrimary">Join Our Community</h2>
          <p className="text-textSecondary">Sign up as a rider or club owner and be part of our vibrant community.</p>
        </div>
      </div>
      <MobileApps />
      <ForClubOwners />
      <ForRiders />
      <FeaturesSection />
      <InfoCards />
      <Testimonials />
      <HowItWorks />
    </div>
  );
};

export default Home;

import React from 'react';

const Message = ({ message, type }) => {
    if (!message) return null;

    let styles = {
        success: "bg-green-100 border border-green-400 text-green-700",
        error: "bg-red-100 border border-red-400 text-red-700"
    };

    return (
        <div className={`${styles[type]} px-4 py-3 rounded relative`} role="alert">
            <span className="block sm:inline">{message}</span>
        </div>
    );
};

export default Message;
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import axios from '../../../config/axiosConfig';

function DeleteModal({ show, onClose, onSuccess, selectedRide }) {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  if (!show) return null;

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      setDeleteError(null);
      await axios.delete(`/rides/${selectedRide.rideId}`);
      onSuccess(selectedRide.rideId);
    } catch (error) {
      console.error('Error deleting ride:', error);
      if (error.response?.data?.message) {
        setDeleteError(`${error.response.data.message}${error.response.data.registeredRiders ? ` (${error.response.data.registeredRiders} registered riders)` : ''}`);
      } else {
        setDeleteError('Error deleting ride. Please try again.');
      }
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Confirm Delete</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <FaTimes />
          </button>
        </div>

        <div className="space-y-4">
          <p className="text-gray-600">
            Are you sure you want to delete "{selectedRide?.name}"? This action cannot be undone.
          </p>

          {deleteError && (
            <div className="text-red-500 text-sm bg-red-50 p-3 rounded">
              {deleteError}
            </div>
          )}

          <div className="flex justify-end space-x-3 mt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
              disabled={deleteLoading}
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              disabled={deleteLoading}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 disabled:opacity-50"
            >
              {deleteLoading ? 'Deleting...' : 'Delete Ride'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;

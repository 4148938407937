import React from 'react';

const levels = [
  { level: 1, distanceToNext: 100, totalDistance: 0 },
  { level: 2, distanceToNext: 150, totalDistance: 100 },
  { level: 3, distanceToNext: 200, totalDistance: 250 },
  { level: 4, distanceToNext: 300, totalDistance: 450 },
  { level: 5, distanceToNext: 400, totalDistance: 750 },
  { level: 6, distanceToNext: 500, totalDistance: 1150 },
  { level: 7, distanceToNext: 600, totalDistance: 1650 },
  { level: 8, distanceToNext: 700, totalDistance: 2250 },
  { level: 9, distanceToNext: 800, totalDistance: 2950 },
  { level: 10, distanceToNext: 0, totalDistance: 3750 },
];

const LevelProgress = ({ totalDistance }) => {
  const currentLevelData = levels.find(
    (levelData) => totalDistance < levelData.totalDistance + levelData.distanceToNext
  );

  if (!currentLevelData) {
    return <p>Congratulations! You have reached the maximum level.</p>;
  }

  const { level, distanceToNext, totalDistance: levelStart } = currentLevelData;
  const distanceCoveredInLevel = totalDistance - levelStart;
  const progress = (distanceCoveredInLevel / distanceToNext) * 100;
  const kmsToNextLevel = distanceToNext - distanceCoveredInLevel;

  return (
    <div className="level-progress">
      <h2 className="text-2xl font-semibold mb-4 text-center text-theme-primary">
        Ultra-Rides Level: {level}
      </h2>
      <div className="w-full bg-background rounded-full h-4 mb-4">
        <div
          className="bg-accent h-4 rounded-full"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <p className="text-center text-textSecondary">
        {kmsToNextLevel} km to go to next level
      </p>
    </div>
  );
};

export default LevelProgress;

import React, { useEffect, useState } from 'react';
import axios from '../../../config/axiosConfig';
import RidesList from './RidesList';
import DeleteModal from './DeleteModal';
import DuplicateModal from './DuplicateModal';
import { useNavigate } from 'react-router-dom';

function RidesComponent() {
  const [rides, setRides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRide, setSelectedRide] = useState(null);
  const navigate = useNavigate();

  const fetchRides = async () => {
    try {
      const response = await axios.get('/clubs/myclub/rides');
      setRides(response.data);
    } catch (error) {
      console.error('Error fetching rides:', error);
      setError('Error fetching rides.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRides();
  }, []);

  const handleDeleteSuccess = (rideId) => {
    setRides(rides.filter(ride => ride.rideId !== rideId));
    setShowDeleteModal(false);
    setSelectedRide(null);
  };

  const handleDuplicateSuccess = (newRideId) => {
    setShowDateModal(false);
    setSelectedRide(null);
    navigate(`/rides/${newRideId}/admin`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="max-w-6xl mx-auto p-6 bg-background-light rounded-lg shadow-lg mt-8">
      <h1 className="text-3xl font-semibold text-primary mb-4">Club Rides</h1>

      <DuplicateModal
        show={showDateModal}
        onClose={() => setShowDateModal(false)}
        onSuccess={handleDuplicateSuccess}
        selectedRide={selectedRide}
      />

      <DeleteModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSuccess={handleDeleteSuccess}
        selectedRide={selectedRide}
      />

      <RidesList
        rides={rides}
        onDeleteClick={(ride) => {
          setSelectedRide(ride);
          setShowDeleteModal(true);
        }}
        onDuplicateClick={(ride) => {
          setSelectedRide(ride);
          setShowDateModal(true);
        }}
      />
    </div>
  );
}

// Export the component directly
export default RidesComponent;

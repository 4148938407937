import React, { useEffect } from 'react';

function ControlCheckInDetails({ controlDetails, onClose, rideStartTime }) {

    useEffect(() => {
        const handleEscapeKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscapeKeyPress);

        return () => {
            document.removeEventListener('keydown', handleEscapeKeyPress);
        };
    }, [onClose]);

    if (!controlDetails) return null;

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371e3; // Earth's radius in meters
        const toRad = (value) => (value * Math.PI) / 180;
        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    const distance = controlDetails.OriginalLatitude && controlDetails.OriginalLongitude && controlDetails.Latitude && controlDetails.Longitude
        ? calculateDistance(
            controlDetails.OriginalLatitude,
            controlDetails.OriginalLongitude,
            controlDetails.Latitude,
            controlDetails.Longitude
        ) : 0;

    const formatDistance = (distance) => {
        if (distance >= 10000) {
            return `${(distance / 1000).toFixed(0)} km`;
        } else if (distance >= 1000) {
            return `${(distance / 1000).toFixed(1)} km`;
        } else {
            return `${distance.toFixed(0)} meters`;
        }
    };

    const highlightStyle = distance > 200 ? 'bg-yellow-200' : '';

    const checkInTime = new Date(controlDetails.Timestamp);
    const closeTime = new Date(controlDetails.closeTime);
    const checkInTimeStyle = checkInTime > closeTime ? 'bg-yellow-200' : '';

    const handleBackgroundClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };


    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleBackgroundClick}>
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-xl font-semibold mb-4">Control Check-In Details</h2>
                <div className="mb-4">
                    <p><strong>Control Point:</strong> {controlDetails.ControlPointName || 'N/A'}</p>
                    <p className={checkInTimeStyle}><strong>Check-In Time:</strong> {checkInTime.toLocaleString()}</p>
                    {!controlDetails.isAdminCheckIn && (
                        <>
                            <p><strong>Coordinates (Actual):</strong> {controlDetails.Latitude?.toFixed(4) || 'N/A'}, {controlDetails.Longitude?.toFixed(4) || 'N/A'}</p>
                            <p><strong>Coordinates (Original):</strong> {controlDetails.OriginalLatitude?.toFixed(4) || 'N/A'}, {controlDetails.OriginalLongitude?.toFixed(4) || 'N/A'}</p>
                            <p className={highlightStyle}><strong>Distance from Original Point:</strong> {formatDistance(distance)}</p>
                            <p><strong>Control Closing Time:</strong> {closeTime.toLocaleString()}</p>
                        </>
                    )}
                    <p><strong>Ride Start Time:</strong> {rideStartTime.toLocaleString()}</p>
                    {controlDetails.isAdminCheckIn && (
                        <p className="text-blue-500 font-bold"><strong>Admin Check-In</strong></p>
                    )}
                </div>
                {controlDetails.ImageUrl && (
                    <div className="mb-4">
                        <img src={controlDetails.ImageUrl} alt="Check-In" className="w-full h-auto rounded-lg" />
                    </div>
                )}
                <button
                    onClick={onClose}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Close
                </button>
            </div>
        </div>
    );
}

export default ControlCheckInDetails;

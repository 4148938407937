import React from 'react';
import { Link } from 'react-router-dom';
import { FaCopy, FaTrash } from 'react-icons/fa';
import RideVisibility from './RideVisibility';

function RidesList({ rides, onDeleteClick, onDuplicateClick }) {
  const groupRidesByMonth = () => {
    const grouped = rides.reduce((acc, ride) => {
      const date = new Date(ride.startDateTime);
      const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
      if (!acc[monthYear]) acc[monthYear] = [];
      acc[monthYear].push(ride);
      return acc;
    }, {});

    Object.keys(grouped).forEach(month => {
      grouped[month].sort((a, b) => new Date(b.startDateTime) - new Date(a.startDateTime));
    });

    return Object.entries(grouped).sort(([monthA], [monthB]) => {
      const dateA = new Date(monthA);
      const dateB = new Date(monthB);
      return dateB - dateA;
    });
  };

  const ActionButton = ({ to, onClick, children, className = "" }) => {
    const baseClasses = "px-2.5 py-1 text-xs font-medium rounded border transition-colors duration-150 " + className;
    
    return to ? (
      <Link to={to} className={baseClasses}>{children}</Link>
    ) : (
      <button onClick={onClick} className={baseClasses}>{children}</button>
    );
  };

  const groupedRides = groupRidesByMonth();

  return (
    <>
      {groupedRides.map(([month, monthRides]) => (
        <div key={month} className="mb-8">
          <h2 className="text-xl font-semibold text-gray-700 mb-4 border-b-2 border-gray-200 pb-2">
            {month}
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b text-left text-sm font-medium text-gray-600">Name</th>
                  <th className="py-2 px-4 border-b text-left text-sm font-medium text-gray-600">Start Date & Time</th>
                  <th className="py-2 px-4 border-b text-left text-sm font-medium text-gray-600">Visibility</th>
                  <th className="py-2 px-4 border-b text-left text-sm font-medium text-gray-600">Actions</th>
                </tr>
              </thead>
              <tbody>
                {monthRides.map((ride) => (
                  <tr key={ride.rideId} className="hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">
                      <Link to={`/rides/${ride.rideId}`} className="text-blue-600 hover:text-blue-800">
                        {ride.name}
                      </Link>
                    </td>
                    <td className="py-2 px-4 border-b text-gray-600">
                      {ride.startDateTime ? new Date(ride.startDateTime).toLocaleString() : 'N/A'}
                    </td>
                    <td className="py-2 px-4 border-b">
                      <RideVisibility rideCategory={ride.rideCategory} />
                    </td>
                    <td className="py-2 px-4 border-b">
                      <div className="flex items-center gap-1.5">
                        <ActionButton 
                          to={`/rides/${ride.rideId}/admin`}
                          className="border-gray-300 bg-white hover:bg-gray-50 text-gray-700"
                        >
                          Admin
                        </ActionButton>
                        <ActionButton
                          onClick={() => onDuplicateClick(ride)}
                          className="border-gray-300 bg-white hover:bg-gray-50 text-gray-700"
                        >
                          <FaCopy className="inline-block w-3 h-3 mr-1" />
                          Copy
                        </ActionButton>
                        <ActionButton
                          onClick={() => onDeleteClick(ride)}
                          className="border-gray-300 bg-white hover:bg-gray-50 text-gray-700"
                        >
                          <FaTrash className="inline-block w-3 h-3 mr-1" />
                          Delete
                        </ActionButton>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </>
  );
}

export default RidesList;

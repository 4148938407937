import React from 'react';
import { AlertTriangle, Users } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';
import AdminLayout from '../AdminLayout';
import AdminAccessCheck from '../AdminAccessCheck';

function MissingCPMonitor() {
    const { rideId } = useParams();
    const [isClubOwner, setIsClubOwner] = React.useState(false);

    const { data: rideDetails } = useQuery({
        queryKey: ['rideDetail', rideId],
        queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
    });

    const { data: checkinsData } = useQuery({
        queryKey: ['allCheckins', rideId],
        queryFn: () => axios.get(`/rides/${rideId}/all-checkins`).then(res => res.data),
        enabled: !!rideDetails,
    });

    React.useEffect(() => {
        if (rideDetails?.user) {
            setIsClubOwner(rideDetails.user.isClubOwner);
        }
    }, [rideDetails]);

    // Helper function to check if rider has all previous controls
    const hasPreviousControls = (rider, currentControlNumber) => {
        if (currentControlNumber === 1) return true;
        for (let i = 1; i < currentControlNumber; i++) {
            const hasThisControl = rider.ControlPoints?.some(cp => 
                parseInt(cp.ControlPointNumber) === i
            );
            if (!hasThisControl) return false;
        }
        return true;
    };

    const getMissingControls = (rider, currentControlNumber) => {
        return Array.from({ length: currentControlNumber - 1 }, (_, i) => i + 1)
            .filter(num => !rider.ControlPoints?.some(cp => 
                parseInt(cp.ControlPointNumber) === num
            ));
    };

    const getRidersWithMissingCP = () => {
        if (!checkinsData || !rideDetails?.controlPoints) return [];
        
        const problematicRiders = [];
        
        rideDetails.controlPoints.forEach((controlPoint, index) => {
            const controlNumber = index + 1;
            
            const ridersAtControl = checkinsData
                .filter(rider => rider.ControlPoints?.some(cp => 
                    parseInt(cp.ControlPointNumber) === controlNumber
                ))
                .filter(rider => !hasPreviousControls(rider, controlNumber))
                .map(rider => {
                    const checkIn = rider.ControlPoints.find(cp => 
                        parseInt(cp.ControlPointNumber) === controlNumber
                    );
                    return {
                        name: rider.Name,
                        urn: rider.URN,
                        timestamp: new Date(checkIn.Timestamp),
                        currentCP: controlNumber,
                        missingCPs: getMissingControls(rider, controlNumber)
                    };
                });
            
            problematicRiders.push(...ridersAtControl);
        });

        return problematicRiders.sort((a, b) => b.timestamp - a.timestamp);
    };

    const formatTime = (date) => {
        return date.toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            month: 'short',
            day: 'numeric'
        });
    };

    const problematicRiders = getRidersWithMissingCP();

    if (!rideDetails || !checkinsData) {
        return (
            <AdminLayout title="Missing Control Points Monitor">
                <div className="flex items-center justify-center p-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                </div>
            </AdminLayout>
        );
    }

    return (
        <AdminAccessCheck isAdmin={isClubOwner}>
            <AdminLayout title="Missing Control Points Monitor">
                <div className="space-y-6">
                    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden shadow-sm">
                        <div className="px-4 py-3 bg-gray-50 border-b border-gray-200">
                            <div className="flex items-center justify-between">
                                <h3 className="text-lg font-medium text-gray-900">
                                    Riders with Missing Previous Control Points
                                </h3>
                                <div className="flex items-center gap-2 text-sm text-red-600">
                                    <AlertTriangle className="w-4 h-4" />
                                    <span>{problematicRiders.length} riders found</span>
                                </div>
                            </div>
                        </div>

                        <div className="p-4">
                            {problematicRiders.length > 0 ? (
                                <div className="overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Time</th>
                                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">URN</th>
                                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Current CP</th>
                                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Missing CPs</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {problematicRiders.map((rider, idx) => (
                                                <tr key={idx} className="hover:bg-red-50">
                                                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                                                        {formatTime(rider.timestamp)}
                                                    </td>
                                                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                                                        {rider.urn}
                                                    </td>
                                                    <td className="px-4 py-3 text-sm text-gray-900">
                                                        {rider.name}
                                                    </td>
                                                    <td className="px-4 py-3 text-sm text-gray-900">
                                                        CP{rider.currentCP}
                                                    </td>
                                                    <td className="px-4 py-3">
                                                        <div className="flex items-center gap-1">
                                                            <AlertTriangle className="w-4 h-4 text-red-500" />
                                                            <span className="text-sm text-red-600">
                                                                Missing: {rider.missingCPs.map(cp => `CP${cp}`).join(', ')}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="text-center py-8 text-gray-500">
                                    <Users className="w-12 h-12 mx-auto text-gray-400 mb-3" />
                                    <p>No riders with missing control points found</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </AdminAccessCheck>
    );
}

export default MissingCPMonitor;

import React from 'react';
import NoDataCheckIn from './NoDataCheckIn';

const ControlPointIcon = ({ checkedIn, distance, closeTime }) => {
    let icon = <NoDataCheckIn />;

    if (checkedIn) {
        const checkInTime = new Date(checkedIn.Timestamp);
        const controlCloseTime = new Date(closeTime);

        if (distance > 200) {
            icon = <div className='w-4 h-4 rounded-full bg-yellow-500' />;
        } else if (checkInTime > controlCloseTime) {
            icon = <div className='w-4 h-4 rounded-full bg-yellow-500' />;
        } else {
            icon = <div className='w-4 h-4 rounded-full bg-green-500' />;
        }

        if (checkedIn.isAdminCheckIn) {
            icon = <div className='w-4 h-4 rounded-full bg-blue-500' />;
        }
    } else {
        icon = <div className='w-4 h-4 rounded-full bg-red-400' />;
    }

    return icon;
};

export default ControlPointIcon;

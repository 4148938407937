import React, { useState, useEffect } from 'react';
import axios from '../../config/axiosConfig';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { groupRidesByMonth } from './utils/rideUtils';
import MonthSection from './components/MonthSection';
import LoadingSkeleton from './components/LoadingSkeleton';
import CalendarStrip from './components/CalendarStrip';
import { FaCalendarAlt, FaMedal, FaArrowRight } from 'react-icons/fa';

const fetchRides = async () => {
  const response = await axios.get('/rides');
  return response.data.rides;
};

function RideList() {
  const navigate = useNavigate();
  const { data: rides = [], error, isLoading } = useQuery({
    queryKey: ['rides'],
    queryFn: fetchRides,
  });
  const [expandedMonths, setExpandedMonths] = useState({});

  useEffect(() => {
    const groupedRides = groupRidesByMonth(rides);
    if (groupedRides.length > 0) {
      const firstMonth = groupedRides[0][0];
      setExpandedMonths(prev => ({
        ...prev,
        [firstMonth]: true
      }));
    }
  }, [rides]);

  const toggleMonth = (month) => {
    setExpandedMonths(prev => ({
      ...prev,
      [month]: !prev[month]
    }));
  };

  const handleRideClick = (rideId, event) => {
    event.preventDefault();
    navigate(`/rides/${rideId}`);
  };

  if (isLoading) return <LoadingSkeleton />;
  if (error) return <div className="p-4 text-error">Error fetching rides</div>;
  if (!Array.isArray(rides)) return <div className="p-4 text-error">Error: Expected an array of rides</div>;

  const groupedRides = groupRidesByMonth(rides);

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <header className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
          <h1 className="text-3xl font-bold text-gray-900">
            Upcoming Ultra-Rides
          </h1>

          <Link
            to="/myrides"
            className="group inline-flex items-center justify-center px-6 py-3 border border-primary text-base font-medium rounded-md text-primary hover:bg-primary hover:text-white transition-all duration-200 shadow-sm hover:shadow-md"
          >
            <span className="mr-2">My Rides</span>
            <FaArrowRight className="transition-transform duration-200 group-hover:translate-x-1" />
          </Link>
        </header>

        <section className="space-y-6">
          <div className="bg-white rounded-xl shadow-sm border border-gray-100">
            <div className="p-4 border-b border-gray-100">
              <div className="flex items-center text-gray-700">
                <FaCalendarAlt className="mr-2" />
                <h2 className="font-medium">Next 30 Days</h2>
              </div>
            </div>
            <CalendarStrip rides={rides} />
          </div>

          <div className="flex items-center text-gray-700 mb-4">
            <FaMedal className="mr-2" />
            <h2 className="font-medium">All Upcoming Rides</h2>
          </div>

          <div className="grid gap-4">
            {groupedRides.map(([month, monthRides]) => (
              <MonthSection
                key={month}
                month={month}
                rides={monthRides}
                isExpanded={expandedMonths[month]}
                onToggle={toggleMonth}
                onRideClick={handleRideClick}
              />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default RideList;

import axios from '../config/axiosConfig';

export const createRide = async (clubId, rideData) => {
  try {
    const response = await axios.post(`/clubs/${clubId}/rides`, rideData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

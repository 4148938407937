import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import AdminLayout from './AdminLayout';
import ErrorMessage from '../Message';
import AdminAccessCheck from './AdminAccessCheck';
import { FaMoneyBill, FaCreditCard, FaExclamationCircle, FaCheckCircle, FaTimes, FaEdit, FaSave } from 'react-icons/fa';

function RidePayment() {
   const { rideId } = useParams();
   const queryClient = useQueryClient();
   const [paymentAmount, setPaymentAmount] = useState('');
   const [currency, setCurrency] = useState('INR');
   const [isEditing, setIsEditing] = useState(false);
   const [isClubOwner, setIsClubOwner] = useState(false);
   const [platformFee, setPlatformFee] = useState(0);
   const [razorpayFee, setRazorpayFee] = useState(0);
   const [netAmount, setNetAmount] = useState(0);

   const { data: ride, error, isLoading } = useQuery({
       queryKey: ['rideDetails', rideId],
       queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
       staleTime: 1000 * 60 * 5,
   });

   const PLATFORM_FEE_RATE = 0.075;
   const RAZORPAY_FEE_RATE = 0.025;
   const MAX_TOTAL_FEE = 100;

   useEffect(() => {
       if (ride?.payment) {
           setPaymentAmount(ride.payment.amount);
           setCurrency(ride.payment.currency || 'INR');
       }
       if (ride?.user) {
           setIsClubOwner(ride.user.isClubOwner);
       }
   }, [ride]);

   useEffect(() => {
       calculateFees(paymentAmount);
   }, [paymentAmount]);

   const calculateFees = (amount) => {
       const numAmount = parseFloat(amount) || 0;
       let calculatedPlatformFee = Number((numAmount * PLATFORM_FEE_RATE).toFixed(2));
       let calculatedRazorpayFee = Number((numAmount * RAZORPAY_FEE_RATE).toFixed(2));
       const totalFee = calculatedPlatformFee + calculatedRazorpayFee;

       if (totalFee > MAX_TOTAL_FEE) {
           const ratio = MAX_TOTAL_FEE / totalFee;
           calculatedPlatformFee = Number((calculatedPlatformFee * ratio).toFixed(2));
           calculatedRazorpayFee = Number((calculatedRazorpayFee * ratio).toFixed(2));
       }

       setPlatformFee(calculatedPlatformFee);
       setRazorpayFee(calculatedRazorpayFee);
       setNetAmount(Number((numAmount - calculatedPlatformFee - calculatedRazorpayFee).toFixed(2)));
   };

   const mutation = useMutation({
       mutationFn: async () => {
           return axios.patch(`/rides/${rideId}`, {
               payment: {
                   amount: Number(paymentAmount),
                   currency: currency,
               },
               clubId: ride.clubId,
           });
       },
       onSuccess: () => {
           queryClient.invalidateQueries(['rideDetails', rideId]);
           setIsEditing(false);
       },
       onError: (error) => {
           console.error('Error updating payment:', error);
       }
   });

   if (isLoading) return <div>Loading...</div>;
   if (error) return <ErrorMessage message="Error fetching ride details." />;

   if (!ride?.club?.razorpayId) {
       return (
           <AdminAccessCheck isAdmin={isClubOwner}>
               <AdminLayout title="Payment Settings">
                   <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                       <div className="flex items-center">
                           <FaExclamationCircle className="text-yellow-400 mr-3" />
                           <h3 className="text-yellow-800 font-medium">Payment Setup Required</h3>
                       </div>
                       <div className="mt-2">
                           <p className="text-yellow-700">To enable payments for this ride, please:</p>
                           <ol className="list-decimal list-inside mt-2 space-y-1 text-yellow-700">
                               <li>Go to your club dashboard</li>
                               <li>Navigate to payment settings</li>
                               <li>Connect your Razorpay account</li>
                               <li>Return here to set up ride payments</li>
                           </ol>
                       </div>
                   </div>
               </AdminLayout>
           </AdminAccessCheck>
       );
   }

   return (
       <AdminAccessCheck isAdmin={isClubOwner}>
           <AdminLayout title="Payment Settings">
               {ride.payment && !isEditing ? (
                   <div className="space-y-6">
                       <div className="bg-green-50 border-l-4 border-green-400 p-4">
                           <div className="flex items-center">
                               <FaCheckCircle className="text-green-400 mr-3" />
                               <p className="text-green-700">
                                   Registration fee is set to {Number(ride.payment.amount).toFixed(2)} {ride.payment.currency}
                               </p>
                           </div>
                       </div>

                       <div className="bg-gray-50 p-6 rounded-lg">
                           <h3 className="text-lg font-medium mb-4">Payment Breakdown</h3>
                           <div className="space-y-3">
                               <div className="flex justify-between">
                                   <span>Registration Fee</span>
                                   <span>{Number(ride.payment.amount).toFixed(2)} {ride.payment.currency}</span>
                               </div>
                               <div className="flex justify-between">
                                   <span>Platform Fee (7.5%)</span>
                                   <span>- {platformFee.toFixed(2)} {ride.payment.currency}</span>
                               </div>
                               <div className="flex justify-between">
                                   <span>Razorpay Fee (2.5%)</span>
                                   <span>- {razorpayFee.toFixed(2)} {ride.payment.currency}</span>
                               </div>
                               <div className="border-t pt-2 flex justify-between font-medium">
                                   <span>Net Amount</span>
                                   <span>{netAmount.toFixed(2)} {ride.payment.currency}</span>
                               </div>
                           </div>
                       </div>

                       <div className="flex space-x-4">
                           <button
                               onClick={() => setIsEditing(true)}
                               className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                           >
                               <FaEdit className="mr-2" /> Edit Payment
                           </button>
                           <button
                               onClick={() => mutation.mutate({ payment: null })}
                               className="inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                           >
                               <FaTimes className="mr-2" /> Remove Payment
                           </button>
                       </div>
                   </div>
               ) : (
                   <div className="space-y-6">
                       <div>
                           <label className="block text-sm font-medium text-gray-700 mb-1">
                               Registration Fee
                           </label>
                           <div className="mt-1 relative rounded-md shadow-sm">
                               <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                   <FaMoneyBill className="text-gray-400" />
                               </div>
                               <input
                                   type="number"
                                   value={paymentAmount}
                                   onChange={(e) => setPaymentAmount(e.target.value)}
                                   className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-12 sm:text-sm border-gray-300 rounded-md"
                                   placeholder="Enter amount"
                                   min="0"
                                   step="0.01"
                               />
                               <div className="absolute inset-y-0 right-0 flex items-center">
                                   <select
                                       value={currency}
                                       onChange={(e) => setCurrency(e.target.value)}
                                       className="focus:ring-blue-500 focus:border-blue-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                                   >
                                       <option value="INR">INR</option>
                                       <option value="USD">USD</option>
                                       <option value="EUR">EUR</option>
                                       <option value="GBP">GBP</option>
                                   </select>
                               </div>
                           </div>
                       </div>

                       {paymentAmount > 0 && (
                           <div className="bg-gray-50 p-6 rounded-lg">
                               <h3 className="text-lg font-medium mb-4">Payment Preview</h3>
                               <div className="space-y-3">
                                   <div className="flex justify-between">
                                       <span>Registration Fee</span>
                                       <span>{Number(paymentAmount).toFixed(2)} {currency}</span>
                                   </div>
                                   <div className="flex justify-between">
                                       <span>Platform Fee (7.5%)</span>
                                       <span>- {platformFee.toFixed(2)} {currency}</span>
                                   </div>
                                   <div className="flex justify-between">
                                       <span>Razorpay Fee (2.5%)</span>
                                       <span>- {razorpayFee.toFixed(2)} {currency}</span>
                                   </div>
                                   <div className="border-t pt-2 flex justify-between font-medium">
                                       <span>Net Amount</span>
                                       <span>{netAmount.toFixed(2)} {currency}</span>
                                   </div>
                               </div>
                           </div>
                       )}

                       <div className="flex space-x-4">
                           <button
                               onClick={() => mutation.mutate()}
                               disabled={!paymentAmount || paymentAmount <= 0}
                               className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                           >
                               <FaSave className="mr-2" /> Save Payment Settings
                           </button>
                           <button
                               onClick={() => setIsEditing(false)}
                               className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                           >
                               Cancel
                           </button>
                       </div>
                   </div>
               )}
           </AdminLayout>
       </AdminAccessCheck>
   );
}

export default RidePayment;

// src/config/cognitoConfig.js
import { CognitoUserPool } from 'amazon-cognito-identity-js';

// Hardcoded values for UserPoolId and ClientId
const poolData = {
  UserPoolId: 'ap-south-1_LcERpQC3j',  // Ensure this is the correct UserPoolId
  ClientId: '5a3l5unl4a692qhv6v7q124730', // Ensure this is the correct ClientId
};

// Check if the UserPoolId format is correct
const userPoolIdPattern = /^[a-z]{2}-[a-z]+-\d{1,2}_[a-zA-Z0-9]+$/;
if (!poolData.UserPoolId || !userPoolIdPattern.test(poolData.UserPoolId)) {
  console.error("Invalid UserPoolId format:", poolData.UserPoolId);
  throw new Error("Invalid UserPoolId format.");
}

if (!poolData.ClientId) {
  console.error("ClientId is required.");
  throw new Error("ClientId is required.");
}

// Initialize the Cognito User Pool
const userPool = new CognitoUserPool(poolData);

export default userPool;

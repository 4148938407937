export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
    return localStorage.getItem(key);
};

export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
    localStorage.clear();
};

export const safeParseJSON = (value) => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
};

export const updateLocalStorageAfterLogin = (result, attributes) => {
    const accessToken = result.accessToken.jwtToken;
    setLocalStorage('accessToken', accessToken);
    const accessTokenPayload = result.accessToken.payload;
    setLocalStorage('accessTokenPayload', JSON.stringify(accessTokenPayload));

    const idToken = result.idToken.jwtToken;
    setLocalStorage('idToken', idToken);
    const idTokenPayload = result.idToken.payload;
    setLocalStorage('idTokenPayload', JSON.stringify(idTokenPayload));

    const refreshToken = result.refreshToken.token;
    setLocalStorage('refreshToken', refreshToken);
    const refreshTokenPayload = result.refreshToken.payload;
    setLocalStorage('refreshTokenPayload', JSON.stringify(refreshTokenPayload));

    setLocalStorage('isLoggedIn', true);

    const userIdAttr = attributes.find(attr => attr.Name === 'custom:userId');
    const userTypeAttr = attributes.find(attr => attr.Name === 'custom:userType');
    const nameAttr = attributes.find(attr => attr.Name === 'name');
    const userId = userIdAttr ? userIdAttr.Value : '';
    const userType = userTypeAttr ? userTypeAttr.Value : 'Rider';
    const name = nameAttr ? nameAttr.Value : '';

    setLocalStorage('userType', userType);
    setLocalStorage('name', name);

    return {
        userId,
        userType,
        name,
        accessToken,
        idToken,
        refreshToken,
        accessTokenPayload,
        idTokenPayload,
        refreshTokenPayload
    };
};

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

function RouteTab({ route, routeDetails }) {
  const routeIdMatch = route ? route.match(/\/routes\/(\d+)/) : null;
  const routeId = routeIdMatch ? routeIdMatch[1] : '';
  const gpxLink = routeId ? `https://ridewithgps.com/routes/${routeId}.gpx?sub_format=track` : '';
  const embedLink = routeId ? `https://ridewithgps.com/embeds?type=route&id=${routeId}&metricUnits=true&sampleGraph=true&distanceMarkers=true` : '';

  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md mt-4">
      {routeDetails && (
        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Route Details</h2>
          <div className="prose prose-sm max-w-none prose-headings:text-gray-900 prose-p:text-gray-700 prose-a:text-primary hover:prose-a:text-primary-dark prose-strong:text-gray-900">
            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
              {routeDetails}
            </ReactMarkdown>
          </div>
        </div>
      )}
      <div className="mt-4">
        <p className="font-medium">GPX File:</p>
        {gpxLink ? (
          <a 
            className="text-accent hover:underline"
            href={gpxLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </a>
        ) : (
          <p className="text-red-500">Invalid route link</p>
        )}
      </div>
      {embedLink && (
        <div className="mt-4">
          <iframe
            src={embedLink}
            style={{ width: '1px', minWidth: '100%', height: '700px', border: 'none' }}
            scrolling="no"
            title="Route Map"
          ></iframe>
        </div>
      )}
    </div>
  );
}

export default RouteTab;

import React, { useState } from 'react';
import axios from '../../config/axiosConfig';

function ClubPaymentsOnboarding() {
  const [clubName, setClubName] = useState('');
  const [description, setDescription] = useState('');
  const [coreTeam, setCoreTeam] = useState('');
  const [supporters, setSupporters] = useState('');
  const [razorpayAccount, setRazorpayAccount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const clubDetails = {
      name: clubName,
      description,
      coreTeam: coreTeam.split(',').map((member) => member.trim()),
      supporters: supporters.split(',').map((supporter) => supporter.trim()),
      razorpayAccount,
    };

    try {
      const response = await axios.post('/clubs/onboarding', clubDetails);
      alert('Club details saved successfully');
    } catch (error) {
      console.error('Error saving club details:', error);
      setError('Error saving club details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-background-light p-8">
      <div className="bg-white w-full max-w-4xl p-4 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center text-primary mb-4">Club Onboarding</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-text-primary font-medium mb-2">Club Name</label>
            <input
              type="text"
              value={clubName}
              onChange={(e) => setClubName(e.target.value)}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-text-primary font-medium mb-2">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-text-primary font-medium mb-2">Core Team Members (Comma-separated URNs)</label>
            <input
              type="text"
              value={coreTeam}
              onChange={(e) => setCoreTeam(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-text-primary font-medium mb-2">Supporters (Comma-separated URNs)</label>
            <input
              type="text"
              value={supporters}
              onChange={(e) => setSupporters(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-text-primary font-medium mb-2">Razorpay Account ID</label>
            <input
              type="text"
              value={razorpayAccount}
              onChange={(e) => setRazorpayAccount(e.target.value)}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <button
            type="submit"
            className="bg-primary text-white py-2 px-4 rounded mt-2"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save Club Details'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ClubPaymentsOnboarding;

import React from 'react';
import { MessageSimple } from 'stream-chat-react';

const CustomMessage = (props) => {
    // Customize the message rendering here
    return (
        <div style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '8px' }}>
            <MessageSimple {...props} />
        </div>
    );
};

export default CustomMessage;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';
import AdminLayout from '../AdminLayout';
import ControlPointCard from './ControlPointCard';
import { calculateCloseTime, validateControlPoint } from '../../../utils/timeControlUtils';
import { FaPlus, FaSave } from 'react-icons/fa';
import AdminAccessCheck from '../AdminAccessCheck';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ControlPointsEditor() {
    const { rideId } = useParams();
    const queryClient = useQueryClient();
    const [controlPoints, setControlPoints] = useState([]);
    const [errors, setErrors] = useState({});
    const [isClubOwner, setIsClubOwner] = useState(false);
    const [timeControlType, setTimeControlType] = useState('BRM');
    const [startTimeMismatch, setStartTimeMismatch] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [unsavedFields, setUnsavedFields] = useState({});

    const { data: ride, isLoading } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => axios.get(`/rides/${rideId}`).then((res) => res.data),
    });

    useEffect(() => {
        if (ride) {
            const formattedPoints = ride.controlPoints.map((cp) => {
                let lat = '';
                let lng = '';
                if (cp.gpsCoordinates) {
                    [lat, lng] = cp.gpsCoordinates
                        .split(',')
                        .map((coord) => parseFloat(coord.trim()));
                }

                return {
                    description: cp.description || '',
                    distance: cp.distance || '',
                    latitude: lat,
                    longitude: lng,
                    closeTime: cp.closeTime || '',
                    editable: true,
                };
            });

            if (formattedPoints.length > 0) {
                formattedPoints[0].editable = false;
                formattedPoints[formattedPoints.length - 1].editable = false;
            }

            setControlPoints(formattedPoints);
            setTimeControlType(ride.timeControlType || 'BRM');
            if (ride.user) {
                setIsClubOwner(ride.user.isClubOwner);
            }

            if (formattedPoints.length > 0) {
                const rideStartTime = new Date(ride.startDateTime).getTime();
                const controlStartTime = new Date(formattedPoints[0].closeTime).getTime();

                if (rideStartTime !== controlStartTime) {
                    setStartTimeMismatch(true);
                } else {
                    setStartTimeMismatch(false);
                }
            }
        }
    }, [ride]);

    const mutation = useMutation({
        mutationFn: async (updatedPoints) => {
            const formattedPoints = updatedPoints.map((cp) => ({
                description: cp.description,
                distance: cp.distance,
                gpsCoordinates: `${cp.latitude}, ${cp.longitude}`,
                closeTime: cp.closeTime,
            }));

            return axios.patch(`/rides/${rideId}`, {
                controlPoints: formattedPoints,
                timeControlType: timeControlType,
                clubId: ride.clubId,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['rideDetails', rideId]);
            toast.success('Control points updated successfully');
            setUnsavedChanges(false);
            setUnsavedFields({});
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message || 'Failed to update control points';
            toast.error(errorMessage);
        },
    });

    const handleTimeControlTypeChange = (newType) => {
        setTimeControlType(newType);

        const updatedPoints = controlPoints.map((point) => ({
            ...point,
            closeTime: calculateCloseTime(point.distance, ride.startDateTime, newType),
        }));
        setControlPoints(updatedPoints);
        setUnsavedChanges(true);

        const newUnsavedFields = {};
        controlPoints.forEach((_, index) => {
            newUnsavedFields[index] = {
                ...unsavedFields[index],
                closeTime: true,
            };
        });
        setUnsavedFields(newUnsavedFields);
    };


    // In ControlPointsEditor.js
    const handleInputChange = (index, field, value) => {
        const updatedPoints = [...controlPoints];

        if (field === 'coordinates') {
            // Handle coordinate updates
            updatedPoints[index] = {
                ...updatedPoints[index],
                latitude: value.latitude,
                longitude: value.longitude
            };
        } else {
            // Handle other field updates
            updatedPoints[index] = {
                ...updatedPoints[index],
                [field]: value
            };
        }

        if (field === 'distance') {
            updatedPoints[index].closeTime = calculateCloseTime(
                updatedPoints[index].distance,
                ride.startDateTime,
                timeControlType
            );
        }

        setControlPoints(updatedPoints);
        setUnsavedChanges(true);

        setUnsavedFields((prevUnsavedFields) => {
            const newUnsavedFields = { ...prevUnsavedFields };
            if (!newUnsavedFields[index]) {
                newUnsavedFields[index] = {};
            }

            if (field === 'coordinates') {
                newUnsavedFields[index].latitude = true;
                newUnsavedFields[index].longitude = true;
            } else {
                newUnsavedFields[index][field] = true;
            }

            if (field === 'distance') {
                newUnsavedFields[index].closeTime = true;
            }

            return newUnsavedFields;
        });

        if (errors[index]?.[field]) {
            const newErrors = { ...errors };
            delete newErrors[index][field];
            if (Object.keys(newErrors[index]).length === 0) {
                delete newErrors[index];
            }
            setErrors(newErrors);
        }
    };

    const handleAddControlPoint = () => {
        const newControlPoint = {
            description: '',
            distance: '',
            latitude: '',
            longitude: '',
            closeTime: '',
            editable: true,
        };

        const updatedPoints = [
            ...controlPoints.slice(0, controlPoints.length - 1),
            newControlPoint,
            controlPoints[controlPoints.length - 1],
        ];
        setControlPoints(updatedPoints);
        setUnsavedChanges(true);

        setUnsavedFields((prevUnsavedFields) => {
            const newUnsavedFields = { ...prevUnsavedFields };
            const newIndex = controlPoints.length - 1;
            newUnsavedFields[newIndex] = {
                description: true,
                distance: true,
                latitude: true,
                longitude: true,
                closeTime: true,
            };
            return newUnsavedFields;
        });
    };

    const handleRemoveControlPoint = (index) => {
        const updatedPoints = [...controlPoints];
        updatedPoints.splice(index, 1);
        setControlPoints(updatedPoints);
        setUnsavedChanges(true);

        setUnsavedFields((prevUnsavedFields) => {
            const newUnsavedFields = { ...prevUnsavedFields };
            delete newUnsavedFields[index];

            const updatedUnsavedFields = {};
            Object.keys(newUnsavedFields).forEach((i) => {
                const idx = parseInt(i, 10);
                if (idx > index) {
                    updatedUnsavedFields[idx - 1] = newUnsavedFields[i];
                } else if (idx < index) {
                    updatedUnsavedFields[idx] = newUnsavedFields[i];
                }
            });
            return updatedUnsavedFields;
        });
    };

    const handleSave = async () => {
        if (!validateControlPoints()) {
            toast.error('Please fix validation errors before saving');
            return;
        }

        try {
            await mutation.mutate(controlPoints);
        } catch (error) {
            console.error('Error saving control points:', error);
        }
    };

    const validateControlPoint = (point, index, totalDistance) => {
        const errors = {};

        // Handle description
        if (!point.description || point.description.trim() === '') {
            errors.description = 'Description is required';
        }

        // Handle distance validation with special case for start point
        if (index === 0) {
            // Start point should always be 0
            if (point.distance !== 0 && point.distance !== '0') {
                errors.distance = 'Start point distance must be 0';
            }
        } else {
            // For all other points
            const distance = parseFloat(point.distance);
            if (isNaN(distance) || distance <= 0) {
                errors.distance = 'Valid distance is required';
            }

            // Additional check for end point
            if (index > 0) {
                const prevPointDistance = parseFloat(point.previousDistance || 0);
                if (!isNaN(distance) && distance <= prevPointDistance) {
                    errors.distance = 'Distance must be greater than previous control point';
                }
            }
        }

        // Validate GPS coordinates
        if (!point.latitude || !point.longitude) {
            errors.latitude = 'GPS coordinates are required';
        } else {
            const lat = parseFloat(point.latitude);
            const lng = parseFloat(point.longitude);

            if (isNaN(lat) || lat < -90 || lat > 90) {
                errors.latitude = 'Invalid latitude';
            }
            if (isNaN(lng) || lng < -180 || lng > 180) {
                errors.longitude = 'Invalid longitude';
            }
        }

        return errors;
    };

    // For reference, also update the ControlPointsEditor validation check
    const validateControlPoints = () => {
        const newErrors = {};
        let hasErrors = false;

        controlPoints.forEach((point, index) => {
            // Add previous point's distance for validation
            const pointWithPrevDistance = {
                ...point,
                previousDistance: index > 0 ? controlPoints[index - 1]?.distance : 0
            };

            // For start point, ensure distance is treated as 0
            if (index === 0 && (!point.distance || point.distance === '')) {
                pointWithPrevDistance.distance = 0;
            }

            const pointErrors = validateControlPoint(pointWithPrevDistance, index, ride.distance);
            if (Object.keys(pointErrors).length > 0) {
                newErrors[index] = pointErrors;
                hasErrors = true;
            }
        });

        setErrors(newErrors);
        return !hasErrors;
    };

    const handleUpdateControlPointTimes = () => {
        const updatedPoints = controlPoints.map((point) => ({
            ...point,
            closeTime: calculateCloseTime(point.distance, ride.startDateTime, timeControlType),
        }));
        setControlPoints(updatedPoints);
        setStartTimeMismatch(false);
        setUnsavedChanges(true);

        const newUnsavedFields = {};
        controlPoints.forEach((_, index) => {
            newUnsavedFields[index] = {
                ...unsavedFields[index],
                closeTime: true,
            };
        });
        setUnsavedFields(newUnsavedFields);
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <AdminAccessCheck isAdmin={isClubOwner}>
            <AdminLayout title="Edit Control Points">
                <div className="max-w-5xl mx-auto space-y-4">
                    <ToastContainer position="top-right" autoClose={3000} />

                    {startTimeMismatch && (
                        <div
                            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                            role="alert"
                        >
                            <strong className="font-bold">Warning!</strong>
                            <span className="block sm:inline">
                                {' '}
                                Ride start time has changed. Please update control point times.
                            </span>
                            <button
                                onClick={handleUpdateControlPointTimes}
                                className="ml-4 bg-blue-500 text-white px-2 py-1 rounded"
                            >
                                Update Times
                            </button>
                        </div>
                    )}

                    {unsavedChanges && (
                        <div
                            className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative"
                            role="alert"
                        >
                            <span className="block sm:inline">
                                You have unsaved changes. Please save to apply updates.
                            </span>
                        </div>
                    )}

                    <div className="bg-white p-4 rounded-lg shadow">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Time Control Type:
                        </label>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => handleTimeControlTypeChange('BRM')}
                                className={`px-4 py-2 rounded-lg ${timeControlType === 'BRM'
                                    ? 'bg-blue-500 text-white'
                                    : 'bg-gray-200 text-gray-700'
                                    }`}
                            >
                                BRM (15 kmph)
                            </button>
                            <button
                                onClick={() => handleTimeControlTypeChange('Populaire')}
                                className={`px-4 py-2 rounded-lg ${timeControlType === 'Populaire'
                                    ? 'bg-blue-500 text-white'
                                    : 'bg-gray-200 text-gray-700'
                                    }`}
                            >
                                Populaire (12.5 kmph)
                            </button>
                        </div>
                        <p className="mt-2 text-sm text-gray-500">
                            Changing this will recalculate the control point closing times.
                        </p>
                    </div>

                    {controlPoints.map((point, index) => (
                        <ControlPointCard
                            key={index}
                            controlPoint={point}
                            index={index}
                            handleInputChange={handleInputChange}
                            handleRemoveControlPoint={handleRemoveControlPoint}
                            totalPoints={controlPoints.length}
                            totalDistance={ride.distance}
                            errors={errors[index] || {}}
                            isStartOrEnd={index === 0 || index === controlPoints.length - 1}
                            unsavedFields={unsavedFields[index] || {}}
                        />
                    ))}

                    <div className="flex justify-between">
                        <button
                            onClick={handleAddControlPoint}
                            className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                        >
                            <FaPlus />
                            Add Control Point
                        </button>
                        <button
                            onClick={handleSave}
                            className={`flex items-center gap-2 px-4 py-2 text-white rounded-lg ${unsavedChanges
                                ? 'bg-blue-500 hover:bg-blue-600'
                                : 'bg-gray-400 cursor-not-allowed'
                                }`}
                            disabled={!unsavedChanges}
                        >
                            <FaSave />
                            Save Changes
                        </button>
                    </div>
                </div>
            </AdminLayout>
        </AdminAccessCheck>
    );
}

export default ControlPointsEditor;

import React from 'react';
import { getBadgeImage, shouldShowTimingControlType } from '../utils/rideUtils';

const RideCard = ({ ride, onClick }) => {
  const badgeImage = getBadgeImage(ride.distance, ride.timeControlType);

  return (
    <div 
      onClick={onClick}
      className="p-6 bg-club-secondary rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer"
    >
      <div className="mb-4">
        <div className="block text-2xl font-semibold text-club-accent text-center md:text-left">
          {ride.name}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
        <div className="flex justify-center mb-4 md:mb-0">
          <div className="w-24 h-24">
            {ride.clubLogo ? (
              <img src={ride.clubLogo} alt={`${ride.clubName} Logo`} className="w-full h-full rounded-full object-cover" />
            ) : (
              <div className="w-24 h-24 rounded-full bg-gray-200"></div>
            )}
          </div>
        </div>

        <div className="col-span-1">
          <div className="text-text-primary space-y-2 text-center md:text-left">
            <p className="text-lg font-medium text-accent">
              {new Date(ride.startDateTime).toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </p>
            <p>
              <span className="font-semibold">Start Point:</span> {ride.startPoint}
            </p>
            <p>
              {ride.clubName} - {ride.responsibleName}
            </p>
            <p className="inline-block bg-blue-100 text-blue-700 px-2 py-1 rounded-md text-sm font-semibold">
              {ride.distance} km
            </p>
            {shouldShowTimingControlType(ride.timeControlType) && (
              <p className="inline-block bg-yellow-100 text-yellow-800 px-2 py-1 rounded-md text-sm font-semibold ml-2">
                {ride.timeControlType}
              </p>
            )}
          </div>
        </div>

        {badgeImage && (
          <div className="flex justify-center mt-4 md:mt-0">
            <div className="relative flex flex-col items-center">
              <img
                src={badgeImage}
                alt={`${ride.distance}k Badge`}
                className="w-24 h-24 object-contain"
              />
              <div className="absolute top-2 left-2 text-gray-900 bg-yellow-200 bg-opacity-70 px-2 py-0.5 rounded transform rotate-12 text-xs font-bold">
                Achievable
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RideCard;

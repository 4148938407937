import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Clock, Calendar } from 'lucide-react';

const DateTimePicker = ({ 
  selected, 
  onChange, 
  showTimeSelect = true,
  minDate,
  className = "",
  error,
  label,
  disabled = false,
  placeholderText = "Select date and time"
}) => {
  return (
    <div className="space-y-2">
      {label && (
        <label className="block text-sm font-medium">{label}</label>
      )}
      <div className="relative">
        <DatePicker
          selected={selected ? new Date(selected) : null}
          onChange={onChange}
          showTimeSelect={showTimeSelect}
          dateFormat={showTimeSelect ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"}
          className={`w-full p-2 pr-10 border rounded-md ${error ? 'border-red-500' : 'border-gray-300'} ${className} ${disabled ? 'bg-gray-100' : ''}`}
          minDate={minDate}
          placeholderText={placeholderText}
          disabled={disabled}
        />
        <div className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 pointer-events-none">
          {showTimeSelect ? <Clock size={18} /> : <Calendar size={18} />}
        </div>
      </div>
      {error && (
        <p className="text-red-500 text-sm">{error}</p>
      )}
    </div>
  );
};

export default DateTimePicker;

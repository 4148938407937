import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';
import { FaMoneyBill, FaSort, FaCheck, FaSearch, FaFilter, FaFileInvoiceDollar } from 'react-icons/fa';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../../ui/alert-dialog";
import AdminLayout from '../AdminLayout';
import AdminAccessCheck from '../AdminAccessCheck';

function OfflinePayments() {
    const { rideId } = useParams();
    const queryClient = useQueryClient();
    const [riders, setRiders] = useState([]);
    const [filteredRiders, setFilteredRiders] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [showOnlyUnpaid, setShowOnlyUnpaid] = useState(true);
    const [isClubOwner, setIsClubOwner] = useState(false);
    const [totalPaidCount, setTotalPaidCount] = useState(0);
    const [totalUnpaidCount, setTotalUnpaidCount] = useState(0);
    const [selectedRider, setSelectedRider] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const { data: rideDetails } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
        staleTime: 1000 * 60 * 5,
    });

    useEffect(() => {
        if (rideDetails?.user) {
            setIsClubOwner(rideDetails.user.isClubOwner);
        }
        if (rideDetails?.registeredRiders) {
            setRiders(rideDetails.registeredRiders);
            filterRiders(rideDetails.registeredRiders, showOnlyUnpaid, searchTerm);
        }
    }, [rideDetails]);

    useEffect(() => {
        if (riders.length > 0) {
            const paid = riders.filter(rider =>
                rider.payment && (rider.payment.status === 'paid' || rider.payment.status === 'true' || rider.payment.offlinePayment)
            ).length;
            setTotalPaidCount(paid);
            setTotalUnpaidCount(riders.length - paid);
        }
    }, [riders]);

    const filterRiders = (ridersList, showUnpaid, search) => {
        let filtered = ridersList;

        if (showUnpaid) {
            filtered = filtered.filter(rider =>
                !rider.payment ||
                (!rider.payment.status && !rider.payment.offlinePayment)
            );
        }

        if (search) {
            const searchLower = search.toLowerCase();
            filtered = filtered.filter(rider =>
                rider.name?.toLowerCase().includes(searchLower) ||
                rider.urn?.toLowerCase().includes(searchLower)
            );
        }

        setFilteredRiders(filtered);
    };

    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        filterRiders(riders, showOnlyUnpaid, newSearchTerm);
    };

    const toggleUnpaidFilter = () => {
        const newShowOnlyUnpaid = !showOnlyUnpaid;
        setShowOnlyUnpaid(newShowOnlyUnpaid);
        filterRiders(riders, newShowOnlyUnpaid, searchTerm);
    };

    const mutation = useMutation({
        mutationFn: async (userId) => {
            return axios.post(`/riders/${userId}/rides/${rideId}/attach-offline-payment`, {});
        },
        onSuccess: (_, userId) => {
            queryClient.invalidateQueries(['rideDetails', rideId]);
            setRiders(prevRiders => prevRiders.map(rider =>
                rider.userId === userId
                    ? { ...rider, payment: { status: 'paid', offlinePayment: true } }
                    : rider
            ));
            filterRiders(
                riders.map(rider =>
                    rider.userId === userId
                        ? { ...rider, payment: { status: 'paid', offlinePayment: true } }
                        : rider
                ),
                showOnlyUnpaid,
                searchTerm
            );
            setShowConfirmDialog(false);
            setSelectedRider(null);
        },
    });

    const handleConfirmPayment = (rider) => {
        setSelectedRider(rider);
        setShowConfirmDialog(true);
    };

    const processPayment = () => {
        if (selectedRider) {
            mutation.mutate(selectedRider.userId);
        }
    };

    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });

        const sorted = [...filteredRiders].sort((a, b) => {
            if (key === 'payment') {
                const statusA = getPaymentStatus(a);
                const statusB = getPaymentStatus(b);
                return direction === 'asc'
                    ? statusA.localeCompare(statusB)
                    : statusB.localeCompare(statusA);
            }

            if (!a[key]) return direction === 'asc' ? 1 : -1;
            if (!b[key]) return direction === 'asc' ? -1 : 1;
            return direction === 'asc'
                ? a[key].toString().localeCompare(b[key].toString())
                : b[key].toString().localeCompare(a[key].toString());
        });

        setFilteredRiders(sorted);
    };

    const getPaymentStatus = (rider) => {
        if (rider.payment) {
            if (rider.payment.offlinePayment) return 'Paid (Offline)';
            if (rider.payment.status === 'paid' || rider.payment.status === 'true') return 'Paid';
        }
        return 'Unpaid';
    };

    return (
        <AdminAccessCheck isAdmin={isClubOwner}>
            <AdminLayout title="Offline Payment Management">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                    <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
                        <div className="flex items-center gap-3">
                            <div className="p-2 bg-blue-100 rounded-lg">
                                <FaFileInvoiceDollar className="text-blue-600 text-xl" />
                            </div>
                            <div>
                                <p className="text-sm text-gray-600">Total Riders</p>
                                <p className="text-xl font-bold text-gray-900">{riders.length}</p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
                        <div className="flex items-center gap-3">
                            <div className="p-2 bg-green-100 rounded-lg">
                                <FaCheck className="text-green-600 text-xl" />
                            </div>
                            <div>
                                <p className="text-sm text-gray-600">Paid Riders</p>
                                <p className="text-xl font-bold text-gray-900">{totalPaidCount}</p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
                        <div className="flex items-center gap-3">
                            <div className="p-2 bg-yellow-100 rounded-lg">
                                <FaMoneyBill className="text-yellow-600 text-xl" />
                            </div>
                            <div>
                                <p className="text-sm text-gray-600">Pending Payments</p>
                                <p className="text-xl font-bold text-gray-900">{totalUnpaidCount}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-4 mb-6">
                    <div className="flex-grow relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <FaSearch className="text-gray-400" />
                        </div>
                        <input
                            type="text"
                            placeholder="Search by name or URN..."
                            className="w-full pl-10 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <button
                        onClick={toggleUnpaidFilter}
                        className={`flex items-center gap-2 px-4 py-2 rounded-lg font-medium transition-colors
                            ${showOnlyUnpaid
                                ? 'bg-blue-100 text-blue-800 border border-blue-300'
                                : 'bg-gray-100 text-gray-800 border border-gray-300'
                            }`}
                    >
                        <FaFilter />
                        {showOnlyUnpaid ? 'Showing Unpaid Only' : 'Showing All Riders'}
                    </button>
                </div>

                <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden">
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none"
                                        onClick={() => handleSort('urn')}>
                                        URN <FaSort className="inline ml-1" />
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none"
                                        onClick={() => handleSort('name')}>
                                        Name <FaSort className="inline ml-1" />
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none"
                                        onClick={() => handleSort('payment')}>
                                        Payment Status <FaSort className="inline ml-1" />
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {filteredRiders.map((rider) => (
                                    <tr key={rider.userId} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {rider.urn}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {rider.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                                ${rider.payment?.offlinePayment
                                                    ? 'bg-yellow-100 text-yellow-800'
                                                    : rider.payment?.status === 'paid' || rider.payment?.status === 'true'
                                                        ? 'bg-green-100 text-green-800'
                                                        : 'bg-red-100 text-red-800'
                                                }`}>
                                                {getPaymentStatus(rider)}
                                                {mutation.isLoading && selectedRider?.userId === rider.userId && (
                                                    <span className="ml-2 inline-block animate-spin">⟳</span>
                                                )}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {(!rider.payment || (!rider.payment.status && !rider.payment.offlinePayment)) && (
                                                <button
                                                    onClick={() => handleConfirmPayment(rider)}
                                                    className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                    disabled={mutation.isLoading}
                                                >
                                                    <FaCheck className="mr-2" />
                                                    Mark as Paid
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Confirm Offline Payment</AlertDialogTitle>
                            <AlertDialogDescription>
                                Are you sure you want to mark the payment as received for {selectedRider?.name}?
                                This action will record an offline payment for URN: {selectedRider?.urn}.
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                                onClick={processPayment}
                                className="bg-blue-600 hover:bg-blue-700 text-white"
                            >
                                Confirm Payment
                            </AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </AdminLayout>
        </AdminAccessCheck>
    );
}

export default OfflinePayments;
import React from 'react';

const LoadingSkeleton = () => (
  <div className="min-h-screen bg-club-background py-8">
    <div className="max-w-4xl mx-auto form-container mt-8">
      <div className="space-y-6">
        {[...Array(3)].map((_, index) => (
          <div key={index} className="p-6 bg-club-secondary rounded-lg shadow animate-pulse flex items-center">
            <div className="w-20 h-20 rounded-full bg-gray-200 mr-6"></div>
            <div className="flex-1 space-y-4">
              <div className="h-6 bg-gray-200 rounded w-1/3"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
              <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default LoadingSkeleton;

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AdminLayout from './AdminLayout';
import AdminAccessCheck from './AdminAccessCheck';
import {
    FaSave,
    FaUndo,
    FaExclamationTriangle,
    FaCheckCircle,
    FaMapMarkedAlt,
    FaExternalLinkAlt,
} from 'react-icons/fa';

const AUTOSAVE_DELAY = 2000; // 2 seconds

function RouteDescription() {
    const { rideId } = useParams();
    const queryClient = useQueryClient();
    const [detail, setDetail] = useState('');
    const [clubId, setClubId] = useState('');
    const [routeMapUrl, setRouteMapUrl] = useState('');
    const [isClubOwner, setIsClubOwner] = useState(false);
    const [saveStatus, setSaveStatus] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const editorRef = useRef(null);
    const autosaveTimeout = useRef(null);

    // New state variables for route map URL editing
    const [isEditingRouteMapUrl, setIsEditingRouteMapUrl] = useState(false);
    const [routeMapUrlEdit, setRouteMapUrlEdit] = useState('');
    const [routeMapUrlSaveStatus, setRouteMapUrlSaveStatus] = useState('');

    const { data: rideDetail, error, isLoading } = useQuery({
        queryKey: ['rideDetail', rideId],
        queryFn: () => axios.get(`/rides/${rideId}`).then((res) => res.data),
    });

    useEffect(() => {
        if (rideDetail) {
            setDetail(rideDetail.routeDetails || '');
            setClubId(rideDetail.clubId);
            setRouteMapUrl(rideDetail.route || '');
            if (rideDetail.user) {
                setIsClubOwner(rideDetail.user.isClubOwner);
            }
        }
    }, [rideDetail]);

    // Mutation for saving the route description (auto-save)
    const mutation = useMutation({
        mutationFn: async () => {
            return axios.patch(`/rides/${rideId}`, {
                routeDetails: detail,
                clubId,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['rideDetail', rideId]);
            setSaveStatus('saved');
            setIsDirty(false);
            setTimeout(() => setSaveStatus(''), 3000);
        },
        onError: (error) => {
            setSaveStatus('error');
            console.error('Error saving:', error);
        },
    });

    // Mutation for saving the route map URL (manual save)
    const routeMapUrlMutation = useMutation({
        mutationFn: async () => {
            return axios.patch(`/rides/${rideId}`, {
                route: routeMapUrlEdit,
                clubId,
            });
        },
        onMutate: () => {
            setRouteMapUrlSaveStatus('saving');
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['rideDetail', rideId]);
            setRouteMapUrl(routeMapUrlEdit);
            setIsEditingRouteMapUrl(false);
            setRouteMapUrlSaveStatus('saved');
            setTimeout(() => setRouteMapUrlSaveStatus(''), 3000);
        },
        onError: (error) => {
            console.error('Error saving route map URL:', error);
            setRouteMapUrlSaveStatus('error');
        },
    });

    // Handler for route description changes (with auto-save)
    const handleDetailChange = (content) => {
        setDetail(content);
        setIsDirty(true);
        setSaveStatus('editing');

        // Clear existing timeout
        if (autosaveTimeout.current) {
            clearTimeout(autosaveTimeout.current);
        }

        // Set new autosave timeout
        autosaveTimeout.current = setTimeout(() => {
            mutation.mutate();
        }, AUTOSAVE_DELAY);
    };

    // Manual save for route description
    const handleManualSave = () => {
        mutation.mutate();
    };

    // Reset handler for route description
    const handleReset = () => {
        if (window.confirm('Are you sure you want to reset your changes?')) {
            setDetail(rideDetail?.routeDetails || '');
            setIsDirty(false);
            setSaveStatus('');
        }
    };

    // Quill editor modules
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['link'],
            ['clean'],
        ],
    };

    if (isLoading)
        return (
            <AdminLayout title="Loading...">
                <div className="animate-pulse">
                    <div className="h-32 bg-gray-200 rounded-lg mb-4"></div>
                    <div className="h-64 bg-gray-200 rounded-lg"></div>
                </div>
            </AdminLayout>
        );

    if (error)
        return (
            <AdminLayout title="Error">
                <div className="bg-red-50 border-l-4 border-red-500 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <FaExclamationTriangle className="h-5 w-5 text-red-400" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-red-700">
                                Error loading route description. Please try again later.
                            </p>
                        </div>
                    </div>
                </div>
            </AdminLayout>
        );

    return (
        <AdminAccessCheck isAdmin={isClubOwner}>
            <AdminLayout title="Route Information">
                <div className="min-h-screen bg-gray-50">
                    <div className="max-w-5xl mx-auto py-6 space-y-6">
                        {/* Save Status and Buttons */}
                        <div className="sticky top-0 z-10 bg-white shadow-sm border-b border-gray-200">
                            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="py-3 flex items-center justify-between">
                                    <div className="flex items-center">
                                        {saveStatus === 'editing' && (
                                            <span className="text-yellow-600 flex items-center">
                                                <span className="animate-pulse">●</span>
                                                <span className="ml-2">Editing...</span>
                                            </span>
                                        )}
                                        {saveStatus === 'saved' && (
                                            <span className="text-green-600 flex items-center">
                                                <FaCheckCircle className="mr-2" />
                                                Saved successfully
                                            </span>
                                        )}
                                        {saveStatus === 'error' && (
                                            <span className="text-red-600 flex items-center">
                                                <FaExclamationTriangle className="mr-2" />
                                                Error saving changes
                                            </span>
                                        )}
                                    </div>
                                    <div className="flex space-x-3">
                                        <button
                                            onClick={handleReset}
                                            disabled={!isDirty}
                                            className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium
                      ${isDirty
                                                    ? 'text-gray-700 bg-white hover:bg-gray-50 border border-gray-300'
                                                    : 'text-gray-400 bg-gray-100 cursor-not-allowed'
                                                }`}
                                        >
                                            <FaUndo className="mr-2" />
                                            Reset
                                        </button>
                                        <button
                                            onClick={handleManualSave}
                                            disabled={!isDirty || mutation.isLoading}
                                            className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium
                      ${isDirty && !mutation.isLoading
                                                    ? 'text-white bg-blue-600 hover:bg-blue-700'
                                                    : 'text-gray-400 bg-gray-100 cursor-not-allowed'
                                                }`}
                                        >
                                            <FaSave className="mr-2" />
                                            {mutation.isLoading ? 'Saving...' : 'Save'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Route Map Section */}
                        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                            <div className="p-6">
                                <div className="flex items-center justify-between mb-4">
                                    <div className="flex items-center">
                                        <FaMapMarkedAlt className="text-blue-500 text-xl mr-2" />
                                        <h2 className="text-lg font-medium text-gray-900">Route Map</h2>
                                    </div>
                                    {!isEditingRouteMapUrl && (
                                        <button
                                            onClick={() => {
                                                setIsEditingRouteMapUrl(true);
                                                setRouteMapUrlEdit(routeMapUrl);
                                            }}
                                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-600 hover:text-blue-800"
                                        >
                                            Edit
                                        </button>
                                    )}
                                </div>

                                {/* Save Status for Route Map URL */}
                                {routeMapUrlSaveStatus === 'saving' && (
                                    <span className="text-yellow-600 flex items-center mb-2">
                                        <span className="animate-pulse">●</span>
                                        <span className="ml-2">Saving...</span>
                                    </span>
                                )}
                                {routeMapUrlSaveStatus === 'saved' && (
                                    <span className="text-green-600 flex items-center mb-2">
                                        <FaCheckCircle className="mr-2" />
                                        Saved successfully
                                    </span>
                                )}
                                {routeMapUrlSaveStatus === 'error' && (
                                    <span className="text-red-600 flex items-center mb-2">
                                        <FaExclamationTriangle className="mr-2" />
                                        Error saving route map URL
                                    </span>
                                )}

                                {!isEditingRouteMapUrl ? (
                                    <>
                                        {routeMapUrl ? (
                                            <div>
                                                <a
                                                    href={routeMapUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                                                >
                                                    <FaExternalLinkAlt className="mr-2" />
                                                    View Route Map
                                                </a>
                                            </div>
                                        ) : (
                                            <p className="text-sm text-gray-600">No route map URL provided.</p>
                                        )}
                                    </>
                                ) : (
                                    <div>
                                        <input
                                            type="text"
                                            value={routeMapUrlEdit}
                                            onChange={(e) => setRouteMapUrlEdit(e.target.value)}
                                            className="w-full border border-gray-300 rounded-md px-3 py-2"
                                            placeholder="Enter route map URL"
                                        />
                                        <div className="mt-4 flex space-x-2">
                                            <button
                                                onClick={() => {
                                                    routeMapUrlMutation.mutate();
                                                }}
                                                disabled={
                                                    routeMapUrlEdit === routeMapUrl || routeMapUrlMutation.isLoading
                                                }
                                                className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium
                          ${routeMapUrlEdit !== routeMapUrl && !routeMapUrlMutation.isLoading
                                                        ? 'text-white bg-blue-600 hover:bg-blue-700'
                                                        : 'text-gray-400 bg-gray-100 cursor-not-allowed'
                                                    }`}
                                            >
                                                {routeMapUrlMutation.isLoading ? 'Saving...' : 'Save'}
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setIsEditingRouteMapUrl(false);
                                                }}
                                                className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 border border-gray-300"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Route Description Editing */}
                        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                            <div className="p-6">
                                <div className="mb-4">
                                    <h2 className="text-lg font-medium text-gray-900">Route Description</h2>
                                    <p className="mt-1 text-sm text-gray-500">
                                        Provide detailed route information including important landmarks, turns, and any
                                        specific navigation instructions.
                                    </p>
                                </div>
                                <div className="border rounded-lg">
                                    <ReactQuill
                                        ref={editorRef}
                                        value={detail}
                                        onChange={handleDetailChange}
                                        modules={modules}
                                        theme="snow"
                                        className="h-[500px] mb-12"
                                        placeholder="Enter route description here..."
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Preview */}
                        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                            <div className="p-6">
                                <h2 className="text-lg font-medium text-gray-900 mb-4">Preview</h2>
                                <div
                                    className="prose max-w-none"
                                    dangerouslySetInnerHTML={{ __html: detail }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </AdminAccessCheck>
    );
}

export default RouteDescription;

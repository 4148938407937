import React from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import RideCard from './RideCard';

const MonthSection = ({ month, rides, isExpanded, onToggle, onRideClick }) => (
  <div id={`month-${month}`} className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
    <button
      onClick={() => onToggle(month)}
      className="w-full px-4 py-3 flex items-center justify-between hover:bg-gray-50 transition-colors duration-200"
    >
      <h2 className="text-lg font-medium text-gray-900">{month}</h2>
      {isExpanded ? (
        <FaChevronDown className="text-gray-400" />
      ) : (
        <FaChevronRight className="text-gray-400" />
      )}
    </button>
    {isExpanded && (
      <div className="divide-y divide-gray-100">
        {rides.map((ride) => (
          <RideCard
            key={ride.rideId}
            ride={ride}
            onClick={(e) => onRideClick(ride.rideId, e)}
          />
        ))}
      </div>
    )}
  </div>
);

export default MonthSection;

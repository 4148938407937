import React, { useState } from 'react';
import { Check, AlertCircle, Loader2, ChevronDown, ChevronUp } from 'lucide-react';
import CategoryPopup from '../CategorySelector/CategoryPopup';

const RegistrationSteps = ({
  isRegistered,
  hasPaid,
  hasWaiver,
  payment,
  registrationClosingDateTime,
  onRegister,
  onUnregister,
  onPaymentClick,
  onWaiverClick,
  registering,
  isProcessingPayment,
  isAuthenticated,
  categoriesEnabled,
  selectedCategory,
  onCategorySelect,
  categories
}) => {
  const [isCategoryPopupOpen, setIsCategoryPopupOpen] = useState(false);
  const [showAllSteps, setShowAllSteps] = useState(false);
  const currentDateTime = new Date();
  const closingDateTime = new Date(registrationClosingDateTime);
  const isRegistrationClosed = currentDateTime > closingDateTime;
  const isFreeRide = !payment?.amount || payment?.amount === 0;

  const handleCategoryClick = () => {
    if (isRegistered) {
      setIsCategoryPopupOpen(true);
    }
  };

  const selectedCategoryName = categories?.find(c => c.id === selectedCategory)?.name;

  const baseSteps = [
    {
      title: "Register",
      description: isRegistered 
        ? "Successfully registered" 
        : "Add your name to riders list and get access to ride chat",
      status: isRegistered ? "complete" : isRegistrationClosed ? "disabled" : "current",
      button: {
        onClick: isRegistered ? onUnregister : onRegister,
        text: registering ? "Processing..." : (isRegistered ? (onUnregister ? "Unregister" : "Registered") : "Register Now"),
        disabled: registering || isRegistrationClosed || (isRegistered && !onUnregister),
        loading: registering
      }
    }
  ];

  if (categoriesEnabled && categories?.length > 0) {
    baseSteps.push({
      title: "Category",
      description: selectedCategory
        ? `Selected: ${selectedCategoryName}`
        : "Select racing category",
      status: selectedCategory ? "complete" : !isRegistered ? "disabled" : "current",
      button: {
        onClick: handleCategoryClick,
        text: selectedCategory ? "Change Category" : "Select Category",
        disabled: !isRegistered
      }
    });
  }

  if (!isFreeRide) {
    baseSteps.push({
      title: "Payment",
      description: hasPaid
        ? "Payment completed"
        : `Pay ${payment?.amount || 0} ${payment?.currency || 'INR'}`,
      status: hasPaid ? "complete" : !isRegistered ? "disabled" : "current",
      button: {
        onClick: onPaymentClick,
        text: isProcessingPayment ? "Processing..." : (hasPaid ? "Paid" : "Pay Now"),
        disabled: !isRegistered || hasPaid || isProcessingPayment || (categoriesEnabled && !selectedCategory),
        loading: isProcessingPayment
      }
    });
  }

  baseSteps.push({
    title: "Waiver",
    description: hasWaiver ? "Waiver submitted" : "Accept terms & conditions",
    status: hasWaiver ? "complete" : !isRegistered ? "disabled" : "current",
    button: {
      onClick: onWaiverClick,
      text: hasWaiver ? "Submitted" : "Sign Waiver",
      disabled: !isRegistered || (!isFreeRide && !hasPaid) || (categoriesEnabled && !selectedCategory)
    }
  });

  const completedSteps = baseSteps.filter(step => step.status === 'complete').length;
  const totalSteps = baseSteps.length;
  const progressText = `Completed ${completedSteps} of ${totalSteps} steps`;

  const allStepsComplete = completedSteps === totalSteps;

  if (allStepsComplete) {
    return (
      <div className="relative space-y-3">
        <div className="bg-success/10 rounded-lg p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="bg-success rounded-full p-1.5">
                <Check className="w-4 h-4 text-white" />
              </div>
              <span className="font-medium text-success">All Set! You're registered for the ride</span>
            </div>
            <button
              onClick={() => setShowAllSteps(!showAllSteps)}
              className="text-success/80 hover:text-success"
            >
              {showAllSteps ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
            </button>
          </div>

          {showAllSteps && (
            <div className="mt-4 pt-4 border-t border-success/20 space-y-2">
              {baseSteps.map((step, index) => (
                <div key={step.title}
                  className="flex items-center space-x-3 text-sm text-success pl-7">
                  <Check className="w-4 h-4" />
                  <span className="font-medium">{step.title}:</span>
                  <span>{step.button.text}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        
        {categoriesEnabled && selectedCategory && (
          <div className="flex items-center bg-white rounded-lg p-4 border border-gray-200">
            <div className="flex items-center space-x-3">
              <span className="text-sm text-gray-500">Selected Category:</span>
              <span className="font-medium text-gray-900">{selectedCategoryName}</span>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="space-y-3">
      <div className="flex items-center justify-between mb-4 px-2">
        <div className="flex space-x-2">
          {baseSteps.map((step, index) => (
            <div
              key={index}
              className={`w-2 h-2 rounded-full ${step.status === 'complete' ? 'bg-success' :
                  step.status === 'current' ? 'bg-accent' :
                    'bg-gray-300'
                }`}
            />
          ))}
        </div>
        <button
          onClick={() => setShowAllSteps(!showAllSteps)}
          className="text-gray-500 hover:text-gray-700 text-sm flex items-center"
        >
          {showAllSteps ? "Show less" : "Show all"}
          {showAllSteps ? <ChevronUp className="w-4 h-4 ml-1" /> : <ChevronDown className="w-4 h-4 ml-1" />}
        </button>
      </div>

      <div className="flex items-center space-x-2 px-4 py-2 bg-gray-50 rounded-lg">
        <span className="text-sm text-gray-600">
          {progressText}
        </span>
      </div>

      {categoriesEnabled && selectedCategory && (
        <div className="flex items-center bg-white rounded-lg p-4 border border-gray-200">
          <div className="flex items-center space-x-3">
            <span className="text-sm text-gray-500">Selected Category:</span>
            <span className="font-medium text-gray-900">{selectedCategoryName}</span>
          </div>
        </div>
      )}

      {baseSteps.map((step, index) => {
        if (!showAllSteps && step.status !== 'current') return null;

        return (
          <div
            key={step.title}
            className={`rounded-lg p-4 ${step.status === 'current' ? 'bg-white shadow-md' : 'bg-gray-50'
              }`}
          >
            <div className="flex items-center mb-2">
              <div className={`
                w-6 h-6 rounded-full flex items-center justify-center mr-3
                ${step.status === 'complete' ? 'bg-success' :
                  step.status === 'current' ? 'bg-accent' :
                    'bg-gray-300'}
                text-white text-sm font-medium
              `}>
                {step.status === 'complete' ? (
                  <Check className="w-4 h-4" />
                ) : (
                  index + 1
                )}
              </div>
              <h3 className="font-medium">{step.title}</h3>
            </div>

            <p className="text-sm text-gray-600 mb-3 ml-9">
              {step.description}
            </p>

            <div className="ml-9">
              <button
                onClick={step.button.onClick}
                disabled={step.button.disabled}
                className={`
                  px-4 py-2 rounded-full text-sm font-medium
                  transition-colors duration-200
                  ${step.button.disabled
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : step.status === 'complete'
                      ? 'bg-success/10 text-success hover:bg-success/20'
                      : 'bg-accent text-white hover:bg-accent-dark'
                  }
                  flex items-center justify-center space-x-2
                `}
              >
                {step.button.loading && (
                  <Loader2 className="w-4 h-4 animate-spin mr-2" />
                )}
                <span>{step.button.text}</span>
              </button>

              {!isAuthenticated && index === 0 && (
                <div className="flex items-center text-error mt-2 bg-error/10 px-3 py-1 rounded-full text-sm">
                  <AlertCircle className="w-4 h-4 mr-2" />
                  <span>Please login first</span>
                </div>
              )}
            </div>
          </div>
        );
      })}

      <CategoryPopup
        isOpen={isCategoryPopupOpen}
        onClose={() => setIsCategoryPopupOpen(false)}
        categories={categories || []}
        selectedCategory={selectedCategory}
        onCategorySelect={(categoryId) => {
          onCategorySelect(categoryId);
          setIsCategoryPopupOpen(false);
        }}
        isUserRegistered={isRegistered}
      />
    </div>
  );
};

export default RegistrationSteps;

import React from 'react';

function Legend() {
  return (
    <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
      <h3 className="font-semibold text-gray-700 mb-3">Legend</h3>
      <div className="flex flex-col md:flex-row md:space-x-8 space-y-2 md:space-y-0 text-gray-600">
        <div className="flex items-center"><span className="w-16 font-medium">DNS:</span>Did Not Start</div>
        <div className="flex items-center"><span className="w-16 font-medium">DNF:</span>Did Not Finish</div>
        <div className="flex items-center"><span className="w-16 font-medium">LF:</span>Late Finish</div>
      </div>
    </div>
  );
}

export default Legend;

export const loadRazorpay = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

export const initRazorpay = async () => {
  if (typeof window.Razorpay === 'undefined') {
    await loadRazorpay('https://checkout.razorpay.com/v1/checkout.js');
  }
  return window.Razorpay;
};

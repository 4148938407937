import React, { useState } from 'react';
import { forgotPassword, confirmForgotPassword } from '../lib/cognitoAuth';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState('');

  const handleSendCode = async () => {
    const response = await forgotPassword(email);
    setMessage(response.message);
    if (response.success) {
      setStep(2);
    }
  };

  const handleResetPassword = async () => {
    const response = await confirmForgotPassword(email, code, newPassword);
    setMessage(response.message);
    if (response.success) {
      setStep(3);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md w-full p-6 bg-white shadow-md rounded-lg">
        {step === 1 && (
          <div>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 text-center">Reset Password</h2>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md mb-4"
            />
            <button
              onClick={handleSendCode}
              className="w-full py-3 rounded-md bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200"
            >
              Send Code
            </button>
            {message && <p className="mt-4 text-red-600 text-center">{message}</p>}
          </div>
        )}
        {step === 2 && (
          <div>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 text-center">Enter Verification Code</h2>
            <input
              type="text"
              placeholder="Verification Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md mb-4"
            />
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md mb-4"
            />
            <button
              onClick={handleResetPassword}
              className="w-full py-3 rounded-md bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200"
            >
              Reset Password
            </button>
            {message && <p className="mt-4 text-red-600 text-center">{message}</p>}
          </div>
        )}
        {step === 3 && (
          <div>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 text-center">{message}</h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
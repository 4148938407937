import React from 'react';
import { Link } from 'react-router-dom';
import SimpleBadge from './SimpleBadge';

const RideHistory = ({ pastRides }) => {
  return (
    <div className="w-full">
      <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-center md:text-left text-primary">Ride History</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {pastRides.map((ride) => (
          <div key={ride.RideID} className="p-4 rounded-lg bg-white shadow-md">
            <div className="mb-2">
              <h3 className="text-lg sm:text-xl font-bold text-primary">
                <Link to={`/rides/${ride.RideID}`} className="text-accent hover:underline">
                  {ride.rideName || 'Ride Name not available'}
                </Link>
              </h3>
            </div>
            <div className="flex justify-between items-center">
              <div>
                <p className="text-textSecondary"><strong>Distance:</strong> {ride.rideDistance} km</p>
                <p className="text-textSecondary"><strong>Date:</strong> {new Date(ride.rideStartDateTime).toLocaleDateString()}</p>
                <p className="text-textSecondary"><strong>Club:</strong> {ride.ClubName || 'Club Name not available'}</p>
                {ride.Result && <p className="text-textSecondary"><strong>Result:</strong> {ride.Result}</p>}
                {ride.TotalTime && <p className="text-textSecondary"><strong>Total Time:</strong> {ride.TotalTime}</p>}
              </div>
              <div className="flex items-center ml-4">
                <SimpleBadge ride={ride} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RideHistory;
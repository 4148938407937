export const CURATED_TIMEZONES = [
 {
   name: 'Asia/Kolkata',
   label: 'IST - India (GMT+05:30)',
   abbr: 'IST',
   offset: '+05:30'
 },
 {
   name: 'America/Los_Angeles',
   label: 'PST - Pacific Time (GMT-08:00)',
   abbr: 'PST',
   offset: '-08:00'
 },
 {
   name: 'America/New_York',
   label: 'EST - Eastern Time (GMT-05:00)',
   abbr: 'EST',
   offset: '-05:00'
 },
 {
   name: 'Europe/London',
   label: 'GMT - London (GMT+00:00)',
   abbr: 'GMT',
   offset: '+00:00'
 },
 {
   name: 'Europe/Paris',
   label: 'CET - Central Europe (GMT+01:00)',
   abbr: 'CET',
   offset: '+01:00'
 },
 {
   name: 'Asia/Dubai',
   label: 'GST - Dubai (GMT+04:00)',
   abbr: 'GST',
   offset: '+04:00'
 },
 {
   name: 'Asia/Singapore',
   label: 'SGT - Singapore (GMT+08:00)',
   abbr: 'SGT',
   offset: '+08:00'
 },
 {
   name: 'Asia/Tokyo',
   label: 'JST - Japan (GMT+09:00)',
   abbr: 'JST',
   offset: '+09:00'
 },
 {
   name: 'Australia/Sydney',
   label: 'AEST - Sydney (GMT+10:00)',
   abbr: 'AEST',
   offset: '+10:00'
 },
 {
   name: 'Pacific/Auckland',
   label: 'NZST - New Zealand (GMT+12:00)',
   abbr: 'NZST',
   offset: '+12:00'
 }
];

export const formatTimezone = (timezoneName) => {
 const timezone = CURATED_TIMEZONES.find(tz => tz.name === timezoneName);
 if (!timezone) return timezoneName;
 return `${timezone.abbr} - ${timezone.name} (GMT${timezone.offset})`;
};

export const getDefaultTimezone = () => {
 const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
 const matchedTimezone = CURATED_TIMEZONES.find(tz => tz.name === browserTimezone);
 return matchedTimezone?.name || 'Asia/Kolkata';
};

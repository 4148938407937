import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Circle, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

function ControlPointsTab({ controlPoints: rawControlPoints }) {
  const mapRef = useRef(null);

  const controlPoints = React.useMemo(() => {
    try {
      return typeof rawControlPoints === 'string' ? JSON.parse(rawControlPoints) : rawControlPoints;
    } catch (e) {
      console.error('Error parsing control points:', e);
      return [];
    }
  }, [rawControlPoints]);

  const validateCoordinates = (coordinates) => {
    if (!coordinates) return false;
    const [lat, lng] = coordinates.split(',').map(coord => parseFloat(coord.trim()));
    return !isNaN(lat) && !isNaN(lng);
  };

  const handleClick = (lat, lng) => {
    const map = mapRef.current;
    if (map) {
      map.setView([lat, lng], 15);
    }
  };

  if (!Array.isArray(controlPoints) || controlPoints.length === 0) {
    return (
      <div className="control-points max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg mt-8">
        <h1 className="text-3xl font-semibold text-textPrimary mb-2">Time Stations</h1>
        <p>No control points specified.</p>
      </div>
    );
  }

  const hasValidCoordinates = controlPoints.some(point => point.gpsCoordinates && validateCoordinates(point.gpsCoordinates));
  const initialCoordinates = hasValidCoordinates 
    ? controlPoints.find(point => point.gpsCoordinates && validateCoordinates(point.gpsCoordinates))?.gpsCoordinates?.split(',').map(coord => parseFloat(coord.trim()))
    : [28.6139, 77.2090];

  return (
    <div className="control-points max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg mt-8">
      <h1 className="text-3xl font-semibold text-textPrimary mb-2">Time Stations</h1>
      
      {hasValidCoordinates && (
        <MapContainer
          center={initialCoordinates}
          zoom={13}
          style={{ height: '400px', width: '100%' }}
          whenCreated={(mapInstance) => {
            mapRef.current = mapInstance;
          }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {controlPoints.map((point, index) => {
            if (!point.gpsCoordinates || !validateCoordinates(point.gpsCoordinates)) return null;
            
            const [latitude, longitude] = point.gpsCoordinates.split(',').map(coord => parseFloat(coord.trim()));
            return (
              <Circle
                key={index}
                center={[latitude, longitude]}
                radius={50}
                color="red"
              >
                <Popup>
                  <b>{point.description || `Control Point ${index + 1}`}</b>
                  <br />
                  Distance: {point.distance} km
                </Popup>
              </Circle>
            );
          })}
        </MapContainer>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        {controlPoints.map((point, index) => {
          const previousDistance = index === 0 ? 0 : controlPoints[index - 1].distance;
          const partialKms = (point.distance - previousDistance).toFixed(1);
          const googleMapsLink = point.gpsCoordinates 
            ? `https://www.google.com/maps/search/?api=1&query=${point.gpsCoordinates}`
            : null;

          return (
            <div
              key={index}
              className={`p-4 border rounded-lg shadow-md ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'}`}
              onClick={() => {
                if (point.gpsCoordinates && validateCoordinates(point.gpsCoordinates)) {
                  const [lat, lng] = point.gpsCoordinates.split(',').map(coord => parseFloat(coord.trim()));
                  handleClick(lat, lng);
                }
              }}
              style={{ cursor: point.gpsCoordinates && validateCoordinates(point.gpsCoordinates) ? 'pointer' : 'default' }}
            >
              <h2 className="text-xl font-semibold mb-2 text-textPrimary">
                {`${index + 1}. ${point.description || `Control Point ${index + 1}`}`}
              </h2>
              <p className="text-textSecondary">Distance: {point.distance} km</p>
              <p className="text-textSecondary">Partial KMs: {partialKms}</p>
              {index !== 0 && point.closeTime && (
                <p className="text-textSecondary">
                  Close Time:{' '}
                  {new Date(point.closeTime).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  }) + ', ' + new Date(point.closeTime).toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })}
                </p>
              )}
              {index !== 0 && point.totalTime && (
                <p className="text-textSecondary">Total Time: {point.totalTime}</p>
              )}
              {googleMapsLink && (
                <a 
                  href={googleMapsLink} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-accent hover:underline"
                >
                  View Map
                </a>
              )}
              {point.description && (
                <div className="text-sm text-textSecondary mt-2">
                  {point.description}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ControlPointsTab;

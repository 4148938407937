import React from 'react';
import AdminLayout from '../AdminLayout';

function RaceStandings() {
 return (
   <AdminLayout title="Race Standings">
     <div className="bg-warning/20 border border-warning rounded-lg p-4 mb-6">
       <p className="text-textSecondary">
         Race standings management coming soon. Features will include:
       </p>
       <ul className="list-disc ml-6 mt-2 text-textSecondary">
         <li>Category-wise standings</li>
         <li>Real-time position updates</li>
         <li>Age group classifications</li>
         <li>Auto-generated rankings</li>
       </ul>
     </div>
   </AdminLayout>
 );
}

export default RaceStandings;

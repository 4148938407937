import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from '../config/axiosConfig';

const RideHeader = () => {
    const { rideId } = useParams();
    const [ride, setRide] = useState(null);

    useEffect(() => {
        const fetchRide = async () => {
            try {
                const response = await axios.get(`/rides/${rideId}`);
                setRide(response.data);
            } catch (error) {
                console.error('Error fetching ride:', error);
            }
        };

        if (rideId) {
            fetchRide();
        }
    }, [rideId]);

    if (!ride) return null;

    return (
        <div className="bg-white border-b">
            <div className="max-w-6xl mx-auto px-6 py-4">
                <div className="flex justify-between items-center">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">{ride.name}</h1>
                        <p className="text-sm text-gray-500">
                            {new Date(ride.startDateTime).toLocaleDateString()} • {ride.distance}km
                        </p>
                    </div>
                    <div className="flex items-center space-x-4">
                        <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                            ride.timeControlType === 'race' 
                                ? 'bg-red-100 text-red-800' 
                                : 'bg-blue-100 text-blue-800'
                        }`}>
                            {ride.timeControlType === 'race' ? 'Race' : 'Audax'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RideHeader;

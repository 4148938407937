import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import axios from '../../config/axiosConfig';
import { FaCamera } from 'react-icons/fa';

function LogoUploader({ clubId, setClub }) {
  const editorRef = useRef(null);
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [imageScale, setImageScale] = useState(1);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogo(file);
      setLogoUrl(URL.createObjectURL(file));
      setIsEditing(true);
    }
  };

  const uploadLogo = async () => {
    if (!editorRef.current) return;

    const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
    try {
      setUploading(true);
      const response = await axios.patch(`/clubs/myclub`, { logo: canvas });

      setClub(response.data);
      setIsEditing(false);
      setLogo(null);
      setLogoUrl(null);
    } catch (error) {
      console.error('Error uploading logo:', error);
      setError('Error uploading logo.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="mt-4 text-center">
      {isEditing ? (
        <>
          <AvatarEditor
            ref={editorRef}
            image={logoUrl}
            width={200}
            height={200}
            border={50}
            borderRadius={100}
            scale={imageScale}
            rotate={0}
          />
          <input
            type="range"
            min="1"
            max="2"
            step="0.01"
            value={imageScale}
            onChange={(e) => setImageScale(parseFloat(e.target.value))}
            className="slider my-2"
          />
          <div className="flex justify-center space-x-2">
            <button
              onClick={uploadLogo}
              className="bg-primary text-white py-2 px-4 rounded mt-2"
              disabled={uploading}
            >
              {uploading ? 'Uploading...' : 'Upload Logo'}
            </button>
            <button
              onClick={() => setIsEditing(false)}
              className="bg-gray-400 text-white py-2 px-4 rounded mt-2"
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="relative w-32 h-32 mx-auto cursor-pointer" onClick={() => document.getElementById('logoUpload').click()}>
            {logoUrl ? (
              <img src={logoUrl} alt="Club Logo" className="w-full h-full rounded-full object-cover" />
            ) : (
              <div className="w-full h-full rounded-full bg-gray-200 flex items-center justify-center">
                <FaCamera className="text-gray-500 text-3xl" />
              </div>
            )}
            <div className="absolute bottom-2 right-2 bg-black bg-opacity-75 rounded-full p-2">
              <FaCamera className="text-white text-xl" />
            </div>
          </div>
          <input
            type="file"
            onChange={handleLogoChange}
            className="hidden"
            id="logoUpload"
            accept="image/*"
          />
        </>
      )}
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
}

export default LogoUploader;

import React, { createContext, useReducer, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocalStorage, clearLocalStorage, safeParseJSON } from '../lib/storage';
import { refreshToken } from '../lib/cognitoAuth';

const AuthContext = createContext();

const initialState = {
  isAuthenticated: !!getLocalStorage('isLoggedIn'),
  user: safeParseJSON(getLocalStorage('user')) || null,
  idToken: getLocalStorage('idToken') || null,
  idTokenPayload: safeParseJSON(getLocalStorage('idTokenPayload')) || null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        idToken: action.payload.idToken,
        idTokenPayload: action.payload.idTokenPayload,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        idToken: null,
        idTokenPayload: null,
      };
    case 'UPDATE_PROFILE':
      refreshToken(true)
      return {
        ...state,
        idTokenPayload: action.payload.idTokenPayload,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();

  const logout = () => {
    clearLocalStorage();
    dispatch({ type: 'LOGOUT' });
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ state, dispatch, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt, FaUserCircle, FaBicycle, FaCalendar, FaUsers, FaComments, FaDownload } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import PDFDownloadButton from './Profile/PDFDownloadButton';

function Dashboard() {
    const navigate = useNavigate();
    const { state, logout } = useAuth();
    const { user, idTokenPayload } = state;

    const urn = idTokenPayload?.['custom:userId'];
    const name = idTokenPayload?.name;

    const handleProfileClick = () => {
        navigate('/profile', { state: { name: user?.email, urn } });
    };

    const handlePublicProfileClick = () => {
        navigate(`/athletes/${urn}/profile`);
    };

    const buttonClasses = "w-full border border-primary text-primary py-2 px-6 rounded-full hover:bg-primary hover:text-white transition-colors flex items-center justify-center";

    return (
        <div className="min-h-screen bg-lightGray p-4">
            <div className="max-w-5xl mx-auto p-6 bg-lightContainer rounded-lg shadow-lg">
                <h1 className="text-4xl font-bold text-center text-textPrimary mb-6">Dashboard</h1>
                <p className="text-lg text-center text-textSecondary mb-4">Welcome to your dashboard. Manage your profile and explore rides.</p>
                {name && (
                    <p className="text-lg text-center text-textSecondary mb-6">Hi <span className="font-bold">{name}</span></p>
                )}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg border border-primary transition-shadow duration-200">
                        <div className="flex flex-col items-center">
                            <FaUserCircle className="text-5xl text-primary mb-4" />
                            <h2 className="text-xl font-semibold text-textPrimary mb-2">Profile</h2>
                            <p className="text-textSecondary mb-4 text-center">Manage your profile information and settings.</p>
                            <p className="text-lg text-center text-textSecondary mb-4">URN: <span className="font-bold">{urn}</span></p>
                            <div className="flex flex-col items-center w-full max-w-xs space-y-3">
                                <button
                                    onClick={handleProfileClick}
                                    className={buttonClasses}
                                >
                                    Edit
                                </button>
                                <button
                                    onClick={handlePublicProfileClick}
                                    className={buttonClasses}
                                >
                                    View Profile
                                </button>
                                <PDFDownloadButton
                                    leftSideData={{
                                        customUserId: urn || '',
                                        name: name || ''
                                    }}
                                    idTokenPayload={idTokenPayload}
                                    className={buttonClasses}
                                >
                                    <div className="flex items-center justify-center w-full">
                                        <FaDownload className="mr-2" />
                                        Download Rider Card
                                    </div>
                                </PDFDownloadButton>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg border border-primary transition-shadow duration-200">
                        <div className="flex flex-col items-center">
                            <FaBicycle className="text-5xl text-primary mb-4" />
                            <h2 className="text-xl font-semibold text-textPrimary mb-2">My Rides</h2>
                            <p className="text-textSecondary mb-4 text-center">Check your upcoming and past rides.</p>
                            <div className="flex flex-col items-center w-full max-w-xs space-y-3">
                                <button
                                    onClick={() => navigate('/myrides')}
                                    className={buttonClasses}
                                >
                                    View My Rides
                                </button>
                                <button
                                    onClick={() => navigate('/mychats')}
                                    className={buttonClasses}
                                >
                                    <FaComments className="mr-2" /> Ride Chats
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg border border-primary transition-shadow duration-200">
                        <div className="flex flex-col items-center">
                            <FaCalendar className="text-5xl text-primary mb-4" />
                            <h2 className="text-xl font-semibold text-textPrimary mb-2">Rides Calendar</h2>
                            <p className="text-textSecondary mb-4 text-center">View all past and upcoming rides.</p>
                            <div className="flex flex-col items-center w-full max-w-xs">
                                <button
                                    onClick={() => navigate('/rides')}
                                    className={buttonClasses}
                                >
                                    View Calendar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg border border-primary transition-shadow duration-200">
                        <div className="flex flex-col items-center">
                            <FaUsers className="text-5xl text-primary mb-4" />
                            <h2 className="text-xl font-semibold text-textPrimary mb-2">Owner Dashboard</h2>
                            <p className="text-textSecondary mb-4 text-center">Create and manage your own club.</p>
                            <div className="flex flex-col items-center w-full max-w-xs">
                                <button
                                    onClick={() => navigate('/owner-dashboard')}
                                    className={buttonClasses}
                                >
                                    Go to Owner Dashboard
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-8">
                    <div className="inline-block max-w-xs w-full">
                        <button
                            onClick={logout}
                            className="w-full border border-error text-error py-3 px-8 rounded-full hover:bg-error hover:text-white transition-colors flex items-center justify-center"
                        >
                            <FaSignOutAlt className="mr-2" />
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AdminLayout from './AdminLayout';
import AdminAccessCheck from './AdminAccessCheck';
import { FaSave, FaUndo, FaExclamationTriangle, FaCheckCircle } from 'react-icons/fa';

const AUTOSAVE_DELAY = 2000;

function RulesAndRegulationsEditor() {
    const { rideId } = useParams();
    const queryClient = useQueryClient();
    const [rules, setRules] = useState('');
    const [clubId, setClubId] = useState('');
    const [isClubOwner, setIsClubOwner] = useState(false);
    const [saveStatus, setSaveStatus] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const editorRef = useRef(null);
    const autosaveTimeout = useRef(null);

    const { data: rideDetail, error, isLoading } = useQuery({
        queryKey: ['rideDetail', rideId],
        queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
    });

    useEffect(() => {
        if (rideDetail) {
            setRules(rideDetail.rulesAndRegulations || '');
            setClubId(rideDetail.clubId);
            if (rideDetail.user) {
                setIsClubOwner(rideDetail.user.isClubOwner);
            }
        }
    }, [rideDetail]);

    const mutation = useMutation({
        mutationFn: async () => {
            return axios.patch(`/rides/${rideId}`, {
                rulesAndRegulations: rules,
                clubId,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['rideDetail', rideId]);
            setSaveStatus('saved');
            setIsDirty(false);
            setTimeout(() => setSaveStatus(''), 3000);
        },
        onError: (error) => {
            setSaveStatus('error');
            console.error('Error saving:', error);
        }
    });

    const handleChange = (content) => {
        setRules(content);
        setIsDirty(true);
        setSaveStatus('editing');

        if (autosaveTimeout.current) {
            clearTimeout(autosaveTimeout.current);
        }

        autosaveTimeout.current = setTimeout(() => {
            mutation.mutate();
        }, AUTOSAVE_DELAY);
    };

    const handleManualSave = () => {
        mutation.mutate();
    };

    const handleReset = () => {
        if (window.confirm('Are you sure you want to reset your changes?')) {
            setRules(rideDetail?.rulesAndRegulations || '');
            setIsDirty(false);
            setSaveStatus('');
        }
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            ['link'],
            ['clean']
        ]
    };

    if (isLoading) return (
        <AdminLayout title="Loading...">
            <div className="animate-pulse">
                <div className="h-32 bg-gray-200 rounded-lg mb-4"></div>
                <div className="h-64 bg-gray-200 rounded-lg"></div>
            </div>
        </AdminLayout>
    );

    if (error) return (
        <AdminLayout title="Error">
            <div className="bg-red-50 border-l-4 border-red-500 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <FaExclamationTriangle className="h-5 w-5 text-red-400" />
                    </div>
                    <div className="ml-3">
                        <p className="text-sm text-red-700">
                            Error loading rules and regulations. Please try again later.
                        </p>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );

    return (
        <AdminAccessCheck isAdmin={isClubOwner}>
            <AdminLayout title="Edit Rules and Regulations">
                <div className="min-h-screen bg-gray-50">
                    <div className="max-w-5xl mx-auto py-6 space-y-6">
                        <div className="sticky top-0 z-10 bg-white shadow-sm border-b border-gray-200">
                            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="py-3 flex items-center justify-between">
                                    <div className="flex items-center">
                                        {saveStatus === 'editing' && (
                                            <span className="text-yellow-600 flex items-center">
                                                <span className="animate-pulse">●</span>
                                                <span className="ml-2">Editing...</span>
                                            </span>
                                        )}
                                        {saveStatus === 'saved' && (
                                            <span className="text-green-600 flex items-center">
                                                <FaCheckCircle className="mr-2" />
                                                Saved successfully
                                            </span>
                                        )}
                                        {saveStatus === 'error' && (
                                            <span className="text-red-600 flex items-center">
                                                <FaExclamationTriangle className="mr-2" />
                                                Error saving changes
                                            </span>
                                        )}
                                    </div>
                                    <div className="flex space-x-3">
                                        <button
                                            onClick={handleReset}
                                            disabled={!isDirty}
                                            className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium
                                                ${isDirty
                                                    ? 'text-gray-700 bg-white hover:bg-gray-50 border border-gray-300'
                                                    : 'text-gray-400 bg-gray-100 cursor-not-allowed'
                                                }`}
                                        >
                                            <FaUndo className="mr-2" />
                                            Reset
                                        </button>
                                        <button
                                            onClick={handleManualSave}
                                            disabled={!isDirty || mutation.isLoading}
                                            className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium
                                                ${isDirty && !mutation.isLoading
                                                    ? 'text-white bg-blue-600 hover:bg-blue-700'
                                                    : 'text-gray-400 bg-gray-100 cursor-not-allowed'
                                                }`}
                                        >
                                            <FaSave className="mr-2" />
                                            {mutation.isLoading ? 'Saving...' : 'Save'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                            <div className="p-6">
                                <div className="mb-4">
                                    <h2 className="text-lg font-medium text-gray-900">Rules and Regulations</h2>
                                    <p className="mt-1 text-sm text-gray-500">
                                        Use the editor below to update the rules and regulations. Changes are automatically saved after you stop typing.
                                    </p>
                                </div>
                                <div className="border rounded-lg">
                                    <ReactQuill
                                        ref={editorRef}
                                        value={rules}
                                        onChange={handleChange}
                                        modules={modules}
                                        theme="snow"
                                        className="h-[500px] mb-12"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                            <div className="p-6">
                                <h2 className="text-lg font-medium text-gray-900 mb-4">Preview</h2>
                                <div 
                                    className="prose max-w-none"
                                    dangerouslySetInnerHTML={{ __html: rules }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </AdminAccessCheck>
    );
}

export default RulesAndRegulationsEditor;

import React, { useState } from 'react';
import ControlPointIcon from './ControlPointIcon';
import { FaSave, FaTimes } from 'react-icons/fa';

const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3;
    const toRad = (value) => (value * Math.PI) / 180;
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon1 - lon2);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
};

const determineFinalStatus = (rider, controlPoints) => {
    if (!controlPoints || controlPoints.length === 0 || !rider.ControlPoints) {
        return 'DNS';
    }

    if (rider.ControlPoints.length === 0) {
        return 'DNS';
    }

    const firstControl = controlPoints[0];
    if (!firstControl || !firstControl.description) {
        console.error('First control point or description is missing');
        return 'DNS';
    }

    const firstCheckIn = rider.ControlPoints.find(cp => cp.ControlPointNumber === firstControl.number);
    if (!firstCheckIn) {
        return 'DNS';
    }

    const allCheckIns = controlPoints.map((point, idx) => {
        if (!point || !point.description) {
            console.error(`Control point or description is missing at index ${idx}`);
            return false;
        }

        const checkIn = rider.ControlPoints.find(cp => cp.ControlPointNumber === point.number);
        if (!checkIn) {
            return false;
        }

        const expectedCoordinates = point.gpsCoordinates.split(',').map(coord => parseFloat(coord.trim()));
        let checkedIn = false;

        if (checkIn) {
            const distance = calculateDistance(
                expectedCoordinates[0],
                expectedCoordinates[1],
                checkIn.Latitude,
                checkIn.Longitude
            );
            checkedIn = distance <= 200;
        }

        return checkedIn;
    });

    if (allCheckIns.every(checkedIn => checkedIn)) {
        return 'Finished';
    } else if (allCheckIns.some(checkedIn => !checkedIn)) {
        return 'DNF';
    } else {
        return 'Late Finish';
    }
};

const RiderRow = ({
    user,
    controlPoints,
    handleControlClick,
    handleStatusChange,
    handleClearResult,
    index,
    showToast
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [status, setStatus] = useState(user.Result || '');
    const [editedTime, setEditedTime] = useState('');
    const [isTimeEditing, setIsTimeEditing] = useState(false);
    const [error, setError] = useState('');

    const safeControlPoints = controlPoints || [];
    const safeUserControlPoints = user.ControlPoints || [];

    const calculateProvisionalTime = (start, end) => {
        const startTime = new Date(start);
        const endTime = new Date(end);
        const duration = (endTime - startTime) / 1000;
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        return `${hours}h ${minutes}m`;
    };

    const startTime = safeControlPoints[0]?.closeTime;
    const lastCheckInTime = safeUserControlPoints[safeUserControlPoints.length - 1]?.Timestamp;
    const provisionalTime = editedTime || (lastCheckInTime && startTime ? calculateProvisionalTime(startTime, lastCheckInTime) : '');

    const handleStatusChangeInternal = async (newStatus) => {
        const lastControl = safeControlPoints[safeControlPoints.length - 1];
        const effectiveTime = editedTime || provisionalTime;
        const isProvisionalTimeLate = effectiveTime && lastControl && new Date(effectiveTime) > new Date(lastControl.closeTime);

        if (newStatus === 'Finished' && isProvisionalTimeLate) {
            setError('Cannot set status to Finished. The last control check-in time is later than the last control closing time.');
            return;
        }

        setError('');
        await handleStatusChange(user.sub, newStatus, effectiveTime);
        showToast('Status updated successfully!');
        setIsEditing(false);
    };

    const handleTimeChange = (e) => {
        setEditedTime(e.target.value);
    };

    const saveTime = () => {
        handleStatusChangeInternal(status);
        showToast('Time updated successfully!');
    };

    const lastControl = safeControlPoints[safeControlPoints.length - 1];
    const isProvisionalTimeLate = provisionalTime && lastControl && new Date(provisionalTime) > new Date(lastControl.closeTime);

    return (
        <tr style={{ backgroundColor: isEditing ? '#bfdbfe' : (index % 2 === 0 ? '#f9fafb' : '#ffffff') }}>
            <td className='py-2 px-4 border-b border-gray-200 sticky left-0 bg-white z-10'>{index + 1}</td>
            <td className='py-2 px-4 border-b border-gray-200 sticky left-12 bg-white z-10'>{user.URN}</td>
            <td className='py-2 px-4 border-b border-gray-200 sticky left-28 bg-white z-10'>{user.Name}</td>
            <td className='py-2 px-4 border-b border-gray-200 text-center'>
                <div className='flex items-center justify-center space-x-2'>
                    {safeControlPoints.map((point, idx) => {
                        const checkedIn = safeUserControlPoints.find(cp => cp.ControlPointNumber == idx + 1);
                        const expectedCoordinates = point.gpsCoordinates
                            ? point.gpsCoordinates.split(',').map(coord => parseFloat(coord.trim()))
                            : [];
                        const distance = checkedIn ? calculateDistance(
                            expectedCoordinates[0],
                            expectedCoordinates[1],
                            checkedIn.Latitude,
                            checkedIn.Longitude
                        ) : null;

                        return (
                            <div
                                key={idx}
                                className='cursor-pointer'
                                onClick={() => handleControlClick(checkedIn || null, point, user, idx)}
                                title={`TS${idx + 1}`}
                            >
                                <ControlPointIcon checkedIn={checkedIn} distance={distance} closeTime={point.closeTime} />
                            </div>
                        );
                    })}
                </div>
            </td>
            <td className='py-2 px-4 border-b border-gray-200'>{user.Result}</td>
            <td className='py-2 px-4 border-b border-gray-200'>{user.TotalTime}</td>
            <td className='py-2 px-4 border-b border-gray-200'>{determineFinalStatus(user, safeControlPoints)}</td>
            <td className={`py-2 px-4 border-b border-gray-200 ${!status && 'bg-red-100'} flex items-center`}>
                {isEditing ? (
                    <>
                        <select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            className='border border-gray-300 rounded p-1'
                        >
                            <option value=''>Select Status</option>
                            <option value='DNF'>DNF</option>
                            <option value='DNS'>DNS</option>
                            <option value='Late Finish'>Late Finish</option>
                            <option value='Finished'>Finished</option>
                        </select>
                        <button
                            onClick={() => handleStatusChangeInternal(status)}
                            className='ml-2 bg-blue-500 text-white px-2 py-1 rounded flex items-center'
                            title="Save"
                        >
                            <FaSave />
                        </button>
                        <button
                            onClick={() => setIsEditing(false)}
                            className='ml-2 bg-gray-500 text-white px-2 py-1 rounded flex items-center'
                            title="Cancel"
                        >
                            <FaTimes />
                        </button>
                    </>
                ) : (
                    <span
                        onClick={() => setIsEditing(true)}
                        className='cursor-pointer underline text-blue-500'>
                        {status || 'Edit'}
                    </span>
                )}
            </td>
            <td className={`py-2 px-4 border-b border-gray-200 ${isProvisionalTimeLate ? 'bg-red-200' : ''} flex items-center`}>
                {isTimeEditing ? (
                    <>
                        <input
                            type="text"
                            value={editedTime}
                            onChange={handleTimeChange}
                            className='border border-gray-300 rounded p-1'
                        />
                        <button
                            onClick={saveTime}
                            className='ml-2 bg-blue-500 text-white px-2 py-1 rounded flex items-center'
                            title="Save"
                        >
                            <FaSave />
                        </button>
                        <button
                            onClick={() => setIsTimeEditing(false)}
                            className='ml-2 bg-gray-500 text-white px-2 py-1 rounded flex items-center'
                            title="Cancel"
                        >
                            <FaTimes />
                        </button>
                    </>
                ) : (
                    <span
                        onClick={() => setIsTimeEditing(true)}
                        className='cursor-pointer underline text-blue-500'>
                        {provisionalTime || 'N/A'}
                    </span>
                )}
            </td>
            {status && (
                <td className='py-2 px-4 border-b border-gray-200 text-center'>
                    <button
                        onClick={() => handleClearResult(user.sub)}
                        className='text-red-500 hover:text-red-700 focus:outline-none'
                    >
                        &times;
                    </button>
                </td>
            )}
            {error && (
                <td colSpan="12" className='py-2 px-4 border-b border-gray-200 text-red-500'>
                    {error}
                </td>
            )}
        </tr>
    );
};

export default RiderRow;
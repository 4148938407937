import React from 'react';

const AdminAccessCheck = ({ isAdmin, children }) => {
  if (!isAdmin) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">Access Denied</h2>
          <p className="text-red-500">You don't have access to this page.</p>
        </div>
      </div>
    );
  }
  return children;
};

export default AdminAccessCheck;
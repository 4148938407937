import React from 'react';
import { timezones } from '../utils/timezoneData';
import DateTimePicker from '../../../components/ui/date-time-picker';

const Step1BasicDetails = ({ formData, handleInputChange, errors }) => {
    const handleDateTimeChange = (field, date) => {
        if (!date) {
            handleInputChange(field, '');
            return;
        }
        
        if (field === 'startDateTime') {
            handleInputChange('date', date.toISOString().split('T')[0]);
            handleInputChange('time', date.toTimeString().slice(0, 5));
        } else if (field === 'registrationClosingDateTime') {
            handleInputChange('registrationClosingDate', date.toISOString().split('T')[0]);
            handleInputChange('registrationClosingTime', date.toTimeString().slice(0, 5));
        }
    };

    const getDateTime = (date, time) => {
        if (!date || !time) return null;
        return new Date(`${date}T${time}`);
    };

    return (
        <div className="space-y-6">
            <div className="space-y-2">
                <label className="block text-sm font-medium">Ride Type</label>
                <select
                    className={`w-full p-2 border rounded-md ${errors.rideType ? 'border-red-500' : ''}`}
                    value={formData.rideType || ''}
                    onChange={(e) => handleInputChange('rideType', e.target.value)}
                >
                    <option value="">Select ride type</option>
                    <option value="audax">Audax (BRM/Populaire)</option>
                    <option value="race">Race</option>
                    <option value="time-trial">Time Trial</option>
                </select>
                {errors.rideType && <p className="text-red-500 text-sm">{errors.rideType}</p>}
            </div>

            <div className="space-y-2">
                <label className="block text-sm font-medium">Ride Name</label>
                <input
                    type="text"
                    className={`w-full p-2 border rounded-md ${errors.name ? 'border-red-500' : ''}`}
                    placeholder="Enter ride name"
                    value={formData.name || ''}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                />
                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
            </div>

            <div className="space-y-4">
                <DateTimePicker
                    label="Start Date and Time"
                    selected={getDateTime(formData.date, formData.time)}
                    onChange={(date) => handleDateTimeChange('startDateTime', date)}
                    minDate={new Date()}
                    error={errors.date || errors.time}
                    placeholderText="Select start date and time"
                />

                <DateTimePicker
                    label="Registration Closing"
                    selected={getDateTime(formData.registrationClosingDate, formData.registrationClosingTime)}
                    onChange={(date) => handleDateTimeChange('registrationClosingDateTime', date)}
                    minDate={new Date()}
                    error={errors.registrationClosingDate || errors.registrationClosingTime}
                    placeholderText="Select registration closing date and time"
                />
            </div>

            <div className="space-y-2">
                <label className="block text-sm font-medium">Timezone</label>
                <select
                    className={`w-full p-2 border rounded-md ${errors.timezone ? 'border-red-500' : ''}`}
                    value={formData.timezone || ''}
                    onChange={(e) => handleInputChange('timezone', e.target.value)}
                >
                    <option value="">Select timezone</option>
                    {timezones.map(tz => (
                        <option key={tz.value} value={tz.value}>{tz.label}</option>
                    ))}
                </select>
                {errors.timezone && <p className="text-red-500 text-sm">{errors.timezone}</p>}
            </div>

            <div className="space-y-2">
                <label className="block text-sm font-medium">Responsible Person Details</label>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <input
                        type="text"
                        className={`w-full p-2 border rounded-md ${errors.responsibleName ? 'border-red-500' : ''}`}
                        placeholder="Full Name"
                        value={formData.responsibleName || ''}
                        onChange={(e) => handleInputChange('responsibleName', e.target.value)}
                    />
                    <input
                        type="email"
                        className={`w-full p-2 border rounded-md ${errors.responsibleEmail ? 'border-red-500' : ''}`}
                        placeholder="Email"
                        value={formData.responsibleEmail || ''}
                        onChange={(e) => handleInputChange('responsibleEmail', e.target.value)}
                    />
                    <input
                        type="tel"
                        className={`w-full p-2 border rounded-md ${errors.responsiblePhone ? 'border-red-500' : ''}`}
                        placeholder="Phone Number"
                        value={formData.responsiblePhone || ''}
                        onChange={(e) => handleInputChange('responsiblePhone', e.target.value)}
                    />
                </div>
                {(errors.responsibleName || errors.responsibleEmail || errors.responsiblePhone) && (
                    <div className="text-red-500 text-sm">
                        {errors.responsibleName && <p>{errors.responsibleName}</p>}
                        {errors.responsibleEmail && <p>{errors.responsibleEmail}</p>}
                        {errors.responsiblePhone && <p>{errors.responsiblePhone}</p>}
                    </div>
                )}
            </div>

            <div className="space-y-2">
                <label className="block text-sm font-medium">Rules and Regulations</label>
                <textarea
                    className={`w-full p-2 border rounded-md ${errors.rulesAndRegulations ? 'border-red-500' : ''}`}
                    rows={3}
                    placeholder="Enter ride rules and regulations"
                    value={formData.rulesAndRegulations || ''}
                    onChange={(e) => handleInputChange('rulesAndRegulations', e.target.value)}
                />
                {errors.rulesAndRegulations && <p className="text-red-500 text-sm">{errors.rulesAndRegulations}</p>}
            </div>

            <div className="space-y-2">
                <label className="block text-sm font-medium">Route Details</label>
                <textarea
                    className={`w-full p-2 border rounded-md ${errors.routeDetails ? 'border-red-500' : ''}`}
                    rows={3}
                    placeholder="Enter detailed route description"
                    value={formData.routeDetails || ''}
                    onChange={(e) => handleInputChange('routeDetails', e.target.value)}
                />
                {errors.routeDetails && <p className="text-red-500 text-sm">{errors.routeDetails}</p>}
            </div>

            <div className="space-y-2">
                <label className="block text-sm font-medium">WhatsApp Group Link (Optional)</label>
                <input
                    type="url"
                    className={`w-full p-2 border rounded-md ${errors.whatsappGroupUrl ? 'border-red-500' : ''}`}
                    placeholder="Enter WhatsApp group link"
                    value={formData.whatsappGroupUrl || ''}
                    onChange={(e) => handleInputChange('whatsappGroupUrl', e.target.value)}
                />
                {errors.whatsappGroupUrl && <p className="text-red-500 text-sm">{errors.whatsappGroupUrl}</p>}
            </div>
        </div>
    );
};

export default Step1BasicDetails;

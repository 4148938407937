import React from 'react';
import { Link } from 'react-router-dom';
import { FaTrophy, FaStar } from 'react-icons/fa';

const UpcomingRides = ({ upcomingRides, rideHistory }) => {
  const getBadgeImage = (distance, timeControlType) => {
    switch (distance) {
      case 20:
        return timeControlType === 'Race' ? '/20kRaceElevatedRoad.png' : null;
      case 100:
        return timeControlType !== 'BRM' && timeControlType !== 'N/A' ? '/100kBadge_challenge.png' : '/100kBadge.png';
      case 150:
        return '/150kBadge.png';
      case 200:
        return '/200kBadge.png';
      case 250:
        return timeControlType !== 'BRM' && timeControlType !== 'N/A' ? '/250Badge12h.png' : '/250kBadge.png';
      case 300:
        return '/300kBadge.png';
      default:
        return null;
    }
  };

  const getBadgeColorClass = (distance, timeControlType) => {
    const type = timeControlType === 'N/A' ? 'BRM' : timeControlType;
    switch (`${distance}_${type}`) {
      case '20_Race':
        return 'text-orange-600 border-orange-600';
      case '100_BRM':
        return 'text-yellow-600 border-yellow-600';
      case '100_Challenge':
        return 'text-green-600 border-green-600';
      case '150_BRM':
        return 'text-green-600 border-green-600';
      case '200_BRM':
        return 'text-red-600 border-red-600';
      case '250_BRM':
      case '250_12h':
        return 'text-blue-600 border-blue-600';
      case '300_BRM':
        return 'text-purple-600 border-purple-600';
      default:
        return 'text-gray-600 border-gray-600';
    }
  };

  const getBadgeCount = (distance, timeControlType) => {
    if (!rideHistory) return 0;
    const type = timeControlType === 'N/A' ? 'BRM' : timeControlType;
    return rideHistory.filter(ride =>
      ride.Result === 'Finished' &&
      ride.rideDistance === distance &&
      (ride.timeControlType === 'N/A' ? 'BRM' : ride.timeControlType) === type
    ).length;
  };

  const shouldShowTimingControlType = (timeControlType) => {
    return timeControlType && timeControlType !== 'BRM';
  };

  return (
    <div className="w-full">
      <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-center md:text-left text-primary">
        Upcoming Rides
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {upcomingRides.map((ride) => (
          <div key={ride.RideID} className="p-4 rounded-lg bg-white shadow-md">
            <div className="flex justify-between items-start">
              <div className="flex-grow">
                <h3 className="text-lg sm:text-xl font-bold text-primary">
                  <Link to={`/rides/${ride.RideID}`} className="text-accent hover:underline">
                    {ride.rideName || 'Ride Name not available'}
                  </Link>
                </h3>
                <p className="text-textSecondary">
                  <strong>Distance:</strong> {ride.rideDistance} km
                  {shouldShowTimingControlType(ride.timeControlType) && (
                    <span className="ml-2 inline-block bg-yellow-100 text-yellow-800 text-xs px-2 py-1 rounded">
                      {ride.timeControlType}
                    </span>
                  )}
                </p>
                <p className="text-textSecondary">
                  <strong>Date:</strong> {new Date(ride.rideStartDateTime).toLocaleDateString()}
                </p>
                <p className="text-textSecondary">
                  <strong>Club:</strong> {ride.ClubName || 'Club Name not available'}
                </p>
              </div>
              {getBadgeImage(ride.rideDistance, ride.timeControlType) && (
                <div className="flex-shrink-0 ml-4">
                  <div className="relative flex flex-col items-center">
                    <img
                      src={getBadgeImage(ride.rideDistance, ride.timeControlType)}
                      alt={`${ride.rideDistance}k Badge`}
                      className="w-20 h-20 sm:w-24 sm:h-24 object-contain"
                    />
                    <div className="absolute -top-2 -right-2 bg-white rounded-full w-8 h-8 flex items-center justify-center font-bold border-2 text-xs sm:text-sm">
                      {getBadgeCount(ride.rideDistance, ride.timeControlType)}
                    </div>
                    <div className="absolute top-2 left-2 text-gray-900 bg-yellow-200 bg-opacity-70 px-2 py-0.5 rounded transform rotate-12 text-xs font-bold">
                      Achievable
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpcomingRides;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';
import AdminLayout from '../AdminLayout';
import AdminAccessCheck from '../AdminAccessCheck';
import { FaUser } from 'react-icons/fa';

function ResponsiblePersonEditor() {
   const { rideId } = useParams();
   const queryClient = useQueryClient();
   const [isEditing, setIsEditing] = useState(false);
   const [isClubOwner, setIsClubOwner] = useState(false);
   const [formData, setFormData] = useState({
       responsibleName: '',
       responsibleEmail: '',
       responsiblePhone: ''
   });
   const [error, setError] = useState('');
   const [success, setSuccess] = useState('');

   const { data: ride } = useQuery({
       queryKey: ['rideDetails', rideId],
       queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
   });

   useEffect(() => {
       if (ride) {
           setFormData({
               responsibleName: ride.responsibleName || '',
               responsibleEmail: ride.responsibleEmail || '',
               responsiblePhone: ride.responsiblePhone || ''
           });
           if (ride.user) {
               setIsClubOwner(ride.user.isClubOwner);
           }
       }
   }, [ride]);

   const mutation = useMutation({
       mutationFn: async (updatedData) => {
           return axios.patch(`/rides/${rideId}`, {
               responsibleName: updatedData.responsibleName,
               responsibleEmail: updatedData.responsibleEmail,
               responsiblePhone: updatedData.responsiblePhone,
               clubId: ride.clubId,
           });
       },
       onSuccess: () => {
           queryClient.invalidateQueries(['rideDetails', rideId]);
           setIsEditing(false);
           setSuccess('Responsible person details updated successfully!');
           setTimeout(() => setSuccess(''), 3000);
       },
       onError: (error) => {
           setError('Failed to update responsible person details');
       },
   });

   const handleSave = () => {
       if (!formData.responsibleName || !formData.responsiblePhone || !formData.responsibleEmail) {
           setError('All fields are required');
           return;
       }
       mutation.mutate(formData);
   };

   return (
       <AdminAccessCheck isAdmin={isClubOwner}>
           <AdminLayout title="Responsible Person Details">
               <div className="space-y-4">
                   {error && (
                       <div className="bg-red-50 border-l-4 border-red-500 p-4">
                           <p className="text-red-700">{error}</p>
                       </div>
                   )}

                   {success && (
                       <div className="bg-green-50 border-l-4 border-green-500 p-4">
                           <p className="text-green-700">{success}</p>
                       </div>
                   )}

                   <div className="grid gap-4">
                       <div>
                           <label className="block text-sm font-medium text-gray-700 mb-1">
                               Name
                           </label>
                           {isEditing ? (
                               <input
                                   type="text"
                                   value={formData.responsibleName}
                                   onChange={(e) => setFormData(prev => ({ ...prev, responsibleName: e.target.value }))}
                                   className="w-full px-4 py-2 border rounded-lg"
                               />
                           ) : (
                               <p className="text-gray-900 bg-gray-50 px-4 py-2 rounded-lg">
                                   {formData.responsibleName || 'Not set'}
                               </p>
                           )}
                       </div>

                       <div>
                           <label className="block text-sm font-medium text-gray-700 mb-1">
                               Email
                           </label>
                           {isEditing ? (
                               <input
                                   type="email"
                                   value={formData.responsibleEmail}
                                   onChange={(e) => setFormData(prev => ({ ...prev, responsibleEmail: e.target.value }))}
                                   className="w-full px-4 py-2 border rounded-lg"
                               />
                           ) : (
                               <p className="text-gray-900 bg-gray-50 px-4 py-2 rounded-lg">
                                   {formData.responsibleEmail || 'Not set'}
                               </p>
                           )}
                       </div>

                       <div>
                           <label className="block text-sm font-medium text-gray-700 mb-1">
                               Phone
                           </label>
                           {isEditing ? (
                               <input
                                   type="tel"
                                   value={formData.responsiblePhone}
                                   onChange={(e) => setFormData(prev => ({ ...prev, responsiblePhone: e.target.value }))}
                                   className="w-full px-4 py-2 border rounded-lg"
                               />
                           ) : (
                               <p className="text-gray-900 bg-gray-50 px-4 py-2 rounded-lg">
                                   {formData.responsiblePhone || 'Not set'}
                               </p>
                           )}
                       </div>
                   </div>

                   <div className="flex justify-end space-x-4 mt-6">
                       {isEditing ? (
                           <>
                               <button
                                   onClick={() => setIsEditing(false)}
                                   className="px-6 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
                               >
                                   Cancel
                               </button>
                               <button
                                   onClick={handleSave}
                                   className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                               >
                                   Save Changes
                               </button>
                           </>
                       ) : (
                           <button
                               onClick={() => setIsEditing(true)}
                               className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                           >
                               Edit Details
                           </button>
                       )}
                   </div>
               </div>
           </AdminLayout>
       </AdminAccessCheck>
   );
}

export default ResponsiblePersonEditor;

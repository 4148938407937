import React from 'react';
import { Link } from 'react-router-dom';
import { FaCheck, FaTimes, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

function RidersTable({ currentRiders, currentPage, ridersPerPage, payment, timeControlType, sortField, sortOrder, onSort }) {
  const getSortIcon = (field) => {
    if (sortField !== field) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  const isRace = timeControlType?.toLowerCase() === "race";

  return (
    <div className="table-container overflow-x-auto bg-white rounded-lg shadow-md">
      <table className="min-w-full">
        <thead className="bg-primary text-white">
          <tr>
            <th className="py-3 px-4 text-left">#</th>
            <th className="py-3 px-4 text-left">Profile</th>
            <th
              className="py-3 px-4 text-left cursor-pointer hover:bg-primary-dark transition-colors"
              onClick={() => onSort('name')}
            >
              Name <span className="inline-block ml-1">{getSortIcon('name')}</span>
            </th>
            <th className="py-3 px-4 text-left">URN</th>
            {payment && <th className="py-3 px-4 text-left">Paid</th>}
            {isRace ? (
              <th
                className="py-3 px-4 text-left cursor-pointer hover:bg-primary-dark transition-colors"
                onClick={() => onSort('rank')}
              >
                Rank <span className="inline-block ml-1">{getSortIcon('rank')}</span>
              </th>
            ) : (
              <th className="py-3 px-4 text-left">Results</th>
            )}
          </tr>
        </thead>
        <tbody>
          {currentRiders.map((user, index) => (
            <tr key={index} className="hover:bg-gray-50 transition-colors">
              <td className="py-3 px-4 border-b border-gray-200">
                {(currentPage - 1) * ridersPerPage + index + 1}
              </td>
              <td className="py-3 px-4 border-b border-gray-200">
                <img
                  src={`https://ultrarides-images.s3.ap-south-1.amazonaws.com/profiles/${user.urn}.jpg`}
                  alt={`Profile of ${user.name}`}
                  className="w-12 h-12 rounded-full shadow-sm"
                  onError={(e) => e.target.src = 'https://via.placeholder.com/150?text=No+Image'}
                />
              </td>
              <td className="py-3 px-4 border-b border-gray-200">
                <Link to={`/athletes/${user.urn}/profile`} className="text-accent hover:underline">
                  {user.name}
                </Link>
              </td>
              <td className="py-3 px-4 border-b border-gray-200">{user.urn}</td>
              {payment && (
                <td className="py-3 px-4 border-b border-gray-200">
                  {user.payment ? 
                    <FaCheck className="text-green-500" /> : 
                    <FaTimes className="text-red-500" />
                  }
                </td>
              )}
              {isRace ? (
                <td className="py-3 px-4 border-b border-gray-200">
                  {user.rank || '-'}
                </td>
              ) : (
                <td className="py-3 px-4 border-b border-gray-200">
                  {user.result || '-'}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RidersTable;

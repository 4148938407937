import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../../config/axiosConfig';
import LeaderboardCard from './LeaderboardCard';

function ClubDetails() {
    const { clubId } = useParams();
    const [club, setClub] = useState(null);
    const [coreTeam, setCoreTeam] = useState([]);
    const [supporters, setSupporters] = useState([]);
    const [leaderboard, setLeaderboard] = useState(null);

    useEffect(() => {
        const fetchClubDetails = async () => {
            try {
                const response = await axios.get(`/clubs/${clubId}`);
                setClub(response.data);

                if (response.data.coreTeam) {
                    setCoreTeam(response.data.coreTeam);
                }
                if (response.data.supporters) {
                    setSupporters(response.data.supporters);
                }

                // Fetch leaderboard data
                const leaderboardResponse = await fetch(
                    `https://ultra-rides-clubstats.s3.ap-south-1.amazonaws.com/clubs/${clubId}/leaderboard.json`
                );
                if (leaderboardResponse.ok) {
                    const leaderboardData = await leaderboardResponse.json();
                    setLeaderboard(leaderboardData);
                }
            } catch (error) {
                console.error('Error fetching club details:', error);
            }
        };

        fetchClubDetails();
    }, [clubId]);

    if (!club) {
        return <div>Loading...</div>;
    }

    const renderMembers = (members, title) => (
        <div className="mb-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
            <div className="flex space-x-4">
                {members.map((id) => (
                    <Link key={id} to={`/athletes/${id}/profile`}>
                        <img
                            src={`https://ultrarides-images.s3.ap-south-1.amazonaws.com/profiles/${id}.jpg`}
                            alt={`${title} ${id}`}
                            className="w-32 h-32 rounded-full object-cover"
                        />
                    </Link>
                ))}
            </div>
        </div>
    );

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
            <div className="flex justify-center mb-4">
                {club.logo ? (
                    <img src={club.logo} alt={`${club.name} Logo`} className="w-24 h-24 rounded-full object-cover" />
                ) : (
                    <div className="bg-gray-200 h-24 w-24 rounded-full flex items-center justify-center">
                        <span className="text-gray-400 text-2xl">Logo</span>
                    </div>
                )}
            </div>
            <h2 className="text-3xl font-semibold text-gray-800 mb-2">{club.name}</h2>
            <p className="text-gray-600 mb-4">{club.description}</p>
            <p className="text-gray-600 mb-4"><strong>Location:</strong> {club.location}</p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-6">
                <div>
                    <div className="space-x-4">
                        <Link to={`/clubs/${clubId}/rides`} className="inline-block bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-all">
                            View Club Rides
                        </Link>
                        <Link to={`/clubs/${clubId}/leaderboard`} className="inline-block bg-secondary text-white py-2 px-4 rounded hover:bg-secondary-dark transition-all">
                            Full Leaderboard
                        </Link>
                    </div>
                    
                    {coreTeam.length > 0 && renderMembers(coreTeam, 'Core Team')}
                    {supporters.length > 0 && renderMembers(supporters, 'Supporters')}
                </div>
                
                <div>
                    <LeaderboardCard leaderboard={leaderboard} />
                </div>
            </div>
        </div>
    );
}

export default ClubDetails;

import React from 'react';

function ClubsHeader() {
  return (
    <div>
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">Clubs</h1>
      <p className="text-md text-center text-gray-600 mb-6">Explore all clubs here.</p>
    </div>
  );
}

export default ClubsHeader;

import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      // Simulate an API call
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setMessage('Thank you for contacting us. We will get back to you shortly.');
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="bg-white max-w-md mx-auto p-6 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold text-center text-primary mb-4">Contact Us</h1>
        {message && <p className={message.includes('error') ? 'text-red-500' : 'text-green-500'}>{message}</p>}
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded"
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded"
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded"
          />
          <button
            type="submit"
            className="bg-primary text-white py-2 rounded hover:bg-primary-dark transition-all"
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send Message'}
          </button>
        </form>
        <div className="mt-6 text-gray-700">
          <p><strong>Phone:</strong> +91-9899054441</p>
          <p><strong>Email:</strong> rohan@ultra-rides.com</p>
          <p><strong>Address:</strong> 8/9, Sector 3, Rajendra Nagar, Ghaziabad, Uttar Pradesh 201005, India</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
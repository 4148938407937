import axios from 'axios';
import { refreshToken } from '../lib/cognitoAuth';

// Function to get and validate the token
const getToken = () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    const tokenPayload = JSON.parse(atob(token.split('.')[1]));
    const expiryTime = tokenPayload.exp * 1000; // Convert expiry time to milliseconds
    console.log('Token expiry time:', new Date(expiryTime).toLocaleString());
    if (Date.now() < expiryTime) {
      console.log('Token is valid.');
      return token;
    }
  }
  console.log('Token is invalid or expired.');
  return null;
};

// Creating Axios instance with new CloudFront base URL
const axiosInstance = axios.create({
  baseURL: 'https://dwq9s0jf64na8.cloudfront.net', // Updated base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  console.log('Request interceptor triggered.');
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  if (!isLoggedIn) {
    delete config.headers.Authorization;
    return config;
  }

  let token = getToken();
  if (!token) {
    console.log('Refreshing token...');
    try {
      token = await refreshToken();
    } catch (error) {
      console.error('Failed to refresh token:', error);
      // Redirect to login page or prompt the user to log in again
      window.location.href = '/login';
      return Promise.reject(error);
    }
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    console.log('Token added to request headers.');
  } else {
    console.error('Access token is missing or expired.');
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Response interceptor to handle token expiration and retry requests
axiosInstance.interceptors.response.use(response => {
  return response;
}, async error => {
  const originalRequest = error.config;
  console.log('Response error:', error);
  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const newToken = await refreshToken();
      if (newToken) {
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      }
    } catch (e) {
      console.error('Token refresh failed:', e);
      // Redirect to login page or prompt the user to log in again
      window.location.href = '/login';
    }
  }
  return Promise.reject(error);
});


export default axiosInstance;

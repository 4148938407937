import React, { useEffect, useState } from 'react';
import axios from '../../config/axiosConfig';
import { useParams } from 'react-router-dom';
import RideCard from '../RideDetails/RideCard';

function ClubRides() {
    const { clubId } = useParams();
    const [rides, setRides] = useState({ upcoming: [], past: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRides = async () => {
            try {
                const response = await axios.get(`/clubs/${clubId}/rides`);
                console.log('API Response:', response.data);

                if (!response.data.rides) {
                    throw new Error('Invalid response format: rides property missing');
                }

                const currentDate = new Date();

                const segregatedRides = response.data.rides.reduce((acc, ride) => {
                    const rideDate = new Date(Date.parse(ride.startDateTime));
                    if (rideDate >= currentDate) {
                        acc.upcoming.push(ride);
                    } else {
                        acc.past.push(ride);
                    }
                    return acc;
                }, { upcoming: [], past: [] });

                setRides(segregatedRides);
            } catch (error) {
                console.error('Error fetching rides:', error);
                setError('There was a problem fetching the rides.');
            } finally {
                setLoading(false);
            }
        };

        fetchRides();
    }, [clubId]);

    if (loading) {
        return <div>Loading rides...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="max-w-6xl mx-auto p-6 bg-club-background rounded-lg shadow-lg mt-8">
            <h1 className="text-4xl font-extrabold text-center text-primary mb-6">Rides</h1>
            <div>
                <h2 className="text-2xl font-semibold text-primary mb-4">Upcoming Rides</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                    {rides.upcoming.length > 0 ? (
                        rides.upcoming.map((ride) => (
                            <RideCard key={ride.rideId} ride={ride} />
                        ))
                    ) : (
                        <p>No upcoming rides found.</p>
                    )}
                </div>
            </div>
            <div>
                <h2 className="text-2xl font-semibold text-primary mb-4">Past Rides</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {rides.past.length > 0 ? (
                        rides.past.map((ride) => (
                            <RideCard key={ride.rideId} ride={ride} />
                        ))
                    ) : (
                        <p>No past rides found.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ClubRides;

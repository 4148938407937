import React from 'react';

function ProfileHeader() {
  return (
    <div className="text-center mb-8">
      <h1 className="text-3xl font-bold text-primary mb-2">User Profile</h1>
      <p className="text-lg text-primary">Manage your profile information and settings.</p>
    </div>
  );
}

export default ProfileHeader;

import React from 'react';

const OurMission = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="bg-white max-w-4xl mx-auto p-6 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold text-center text-primary mb-4">Our Mission</h1>
        <p className="text-lg text-gray-700 mb-6 text-center">
          At Ultra-Rides, our mission is to inspire a love for cycling, promote a healthy and active lifestyle, and foster a strong community of riders who support and motivate each other. We believe in the power of cycling to bring positive change to individuals and communities.
        </p>
        
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-primary mb-2">Promoting Healthy Lifestyles</h2>
          <p className="text-gray-600">
            Cycling is an excellent way to stay fit and healthy. We organize regular rides and events to encourage people of all ages and abilities to get on their bikes and enjoy the benefits of physical activity. Whether you're a seasoned cyclist or a beginner, we have something for everyone.
          </p>
        </div>
        
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-primary mb-2">Building a Strong Community</h2>
          <p className="text-gray-600">
            Ultra-Rides is more than just a cycling club; it's a community. We believe that cycling is more enjoyable when shared with others. Our events and rides are designed to create opportunities for riders to connect, share experiences, and build lasting friendships. Together, we support and inspire each other to achieve our cycling goals.
          </p>
        </div>
        
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-primary mb-2">Exploring the World</h2>
          <p className="text-gray-600">
            We are passionate about exploring new places and discovering the beauty of the world on two wheels. Our rides take us through scenic routes, challenging terrains, and beautiful landscapes. We believe that every ride is an adventure, and we aim to make each one memorable and exciting.
          </p>
        </div>
        
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-primary mb-2">Advocacy and Sustainability</h2>
          <p className="text-gray-600">
            We advocate for safer cycling infrastructure and promote sustainable transportation. By encouraging more people to cycle, we aim to reduce our carbon footprint and contribute to a cleaner, healthier environment. We work with local authorities and organizations to support cycling-friendly policies and initiatives.
          </p>
        </div>
        
        <div className="text-center">
          <a href="/contact" className="inline-block bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-colors mx-2">
            Join Us
          </a>
          <a href="/rides" className="inline-block bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-colors mx-2">
            Explore Rides
          </a>
        </div>
      </div>
    </div>
  );
};

export default OurMission;

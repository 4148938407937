import React, { useEffect, useState } from 'react';
import axios from '../config/axiosConfig';
import ClubsHeader from './Clubs/ClubsHeader';
import ClubCard from './Clubs/ClubCard';
import { Link } from 'react-router-dom';

function Clubs() {
    const [clubs, setClubs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchClubs = async () => {
            try {
                const response = await axios.get('/clubs');
                setClubs(response.data);
            } catch (error) {
                console.error('Error fetching clubs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchClubs();
    }, []);

    return (
        <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
            <ClubsHeader />
            <Link to="/clubs/new" className="mt-4 inline-block bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-all">
                Create New Club
            </Link>
            {loading ? (
                <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {[...Array(6)].map((_, index) => (
                        <div key={index} className="bg-gray-100 p-4 rounded-lg shadow animate-pulse">
                            <div className="h-24 bg-gray-200 rounded-full mb-4"></div>
                            <div className="h-6 bg-gray-200 rounded mb-2"></div>
                            <div className="h-4 bg-gray-200 rounded"></div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {clubs.filter(club => club.name !== 'Cunning Cycling Club').map((club) => (
                        <ClubCard key={club.clubId} club={club} />
                    ))}
                </div>
            )}
        </div>
    );
}

export default Clubs;

import React from 'react';
import { FaGlobe, FaLink } from 'react-icons/fa';

function RideVisibility({ rideCategory }) {
  const getVisibilityInfo = () => {
    if (rideCategory === 'allRides') {
      return {
        icon: <FaGlobe className="inline-block mr-1" />,
        label: 'Public',
        description: 'Visible in rides listing',
        bgColor: 'bg-green-50',
        textColor: 'text-green-700',
        borderColor: 'border-green-200'
      };
    } else {
      return {
        icon: <FaLink className="inline-block mr-1" />,
        label: 'Anyone with the link',
        description: 'Not listed publicly',
        bgColor: 'bg-yellow-50',
        textColor: 'text-yellow-700',
        borderColor: 'border-yellow-200'
      };
    }
  };

  const visibilityInfo = getVisibilityInfo();

  return (
    <>
      <div className={`inline-flex items-center px-3 py-1 rounded-full border ${visibilityInfo.borderColor} ${visibilityInfo.bgColor} ${visibilityInfo.textColor}`}>
        {visibilityInfo.icon}
        <span className="text-sm font-medium">{visibilityInfo.label}</span>
      </div>
      <div className="text-xs text-gray-500 mt-1">
        {visibilityInfo.description}
      </div>
    </>
  );
}

export default RideVisibility;

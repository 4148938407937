export const getBadgeImage = (distance, timeControlType) => {
  if (timeControlType === 'Race') {
    return `/20kRaceElevatedRoad.png`;
  }

  let badgeName = '';
  if (timeControlType && timeControlType !== 'BRM' && timeControlType !== 'N/A') {
    badgeName = `${distance}kBadge_${timeControlType.toLowerCase()}`;
  } else {
    badgeName = `${distance}kBadge`;
  }

  // Return null if badge doesn't match known patterns
  if (![20, 100, 150, 200, 250].includes(distance)) {
    return null;
  }

  return `/${badgeName}.png`;
};

export const shouldShowTimingControlType = (timeControlType) => {
  return timeControlType && timeControlType !== 'BRM' && timeControlType !== 'N/A';
};

export const groupRidesByMonth = (rides) => {
  if (!Array.isArray(rides)) return [];
  
  const grouped = rides.reduce((acc, ride) => {
    const date = new Date(ride.startDateTime);
    const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
    
    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(ride);
    return acc;
  }, {});

  // Sort rides within each month by date
  Object.keys(grouped).forEach(month => {
    grouped[month].sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));
  });

  // Sort months chronologically
  return Object.entries(grouped)
    .sort(([monthA], [monthB]) => {
      const dateA = new Date(monthA);
      const dateB = new Date(monthB);
      return dateA - dateB;
    });
};

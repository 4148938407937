import React from 'react';
import MapPicker from './MapPicker/MapPicker';

const Step3ControlPoints = ({ formData, updateControlPoint, addControlPoint, removeControlPoint, errors }) => {
    if (formData.rideType !== 'audax') {
        return (
            <div className="flex flex-col items-center justify-center py-12 px-4 border-2 border-dashed rounded-lg">
                <p className="text-lg text-gray-600 text-center mb-2">
                    Control points are only applicable for Audax rides.
                </p>
                <p className="text-sm text-gray-500 text-center">
                    You can proceed to the next step.
                </p>
            </div>
        );
    }

    const formatTime = (closeTime) => {
        if (!closeTime) return { time: '--:--', date: '--' };
        try {
            const date = new Date(closeTime);
            return {
                time: date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }),
                date: date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
            };
        } catch (e) {
            return { time: '--:--', date: '--' };
        }
    };

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <h3 className="font-medium">Control Points</h3>
                <button onClick={addControlPoint} className="px-4 py-2 text-sm bg-blue-500 text-white rounded-md">
                    Add Control Point
                </button>
            </div>

            {errors.controlPoints && (
                <p className="text-red-500 text-sm">{errors.controlPoints}</p>
            )}

            <div className="border rounded-md divide-y">
                {formData.controlPoints.map((point, index) => {
                    const isStart = index === 0;
                    const isEnd = index === formData.controlPoints.length - 1;
                    const timeFormatted = formatTime(point.closeTime);

                    return (
                        <div key={index} className={`p-4 space-y-4 ${(isStart || isEnd) ? 'bg-gray-50' : ''}`}>
                            <div className="flex items-center justify-between">
                                <span className="font-medium text-blue-600">
                                    {isStart ? 'Start Point' : isEnd ? 'End Point' : `Control Point ${index}`}
                                </span>
                                {!isStart && !isEnd && (
                                    <button onClick={() => removeControlPoint(index)} className="text-red-500 text-sm">
                                        Remove
                                    </button>
                                )}
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm text-gray-600">Distance (km)</label>
                                    <input
                                        type="number"
                                        className={`w-full p-2 border rounded-md ${
                                            isStart || isEnd ? 'bg-gray-100' : ''
                                        } ${errors[`controlPoint${index}Distance`] ? 'border-red-500' : ''}`}
                                        value={point.distance}
                                        onChange={(e) => updateControlPoint(index, 'distance', e.target.value)}
                                        disabled={isStart || isEnd}
                                    />
                                    {errors[`controlPoint${index}Distance`] && (
                                        <p className="text-red-500 text-sm">{errors[`controlPoint${index}Distance`]}</p>
                                    )}
                                </div>
                                <div>
                                    <label className="block text-sm text-gray-600">
                                        {isStart ? 'Open Time' : 'Close Time'}
                                    </label>
                                    <div className="w-full p-2 border rounded-md bg-gray-50">
                                        <div className="text-sm">{timeFormatted.time}</div>
                                        <div className="text-xs text-gray-500">{timeFormatted.date}</div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm text-gray-600">GPS Coordinates</label>
                                <MapPicker
                                    value={point.gpsCoordinates}
                                    onChange={(value) => updateControlPoint(index, 'gpsCoordinates', value)}
                                    className={errors[`controlPoint${index}GPS`] ? 'border-red-500' : ''}
                                />
                                {errors[`controlPoint${index}GPS`] && (
                                    <p className="text-red-500 text-sm">{errors[`controlPoint${index}GPS`]}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm text-gray-600">Description</label>
                                <input
                                    type="text"
                                    className={`w-full p-2 border rounded-md ${errors[`controlPoint${index}Description`] ? 'border-red-500' : ''}`}
                                    placeholder="Enter control point description"
                                    value={point.description}
                                    onChange={(e) => updateControlPoint(index, 'description', e.target.value)}
                                />
                                {errors[`controlPoint${index}Description`] && (
                                    <p className="text-red-500 text-sm">{errors[`controlPoint${index}Description`]}</p>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>

            {formData.controlPoints.length === 0 && (
                <div className="text-center text-gray-500 py-8">
                    No control points added yet. Click "Add Control Point" to add one.
                </div>
            )}
        </div>
    );
};

export default Step3ControlPoints;

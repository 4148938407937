import React from 'react';

const Step2RouteDetails = ({ formData, handleInputChange, errors }) => {
    const isAudax = formData.rideType === 'audax';
    const isRace = formData.rideType === 'race';
    const isTimeTrial = formData.rideType === 'time-trial';

    const getDistanceHelperText = () => {
        if (isRace || isTimeTrial) return 'Any distance greater than 0km';
        return 'For distances up to 250km: must be multiple of 50\nFor distances above 250km: must be multiple of 100\nSpecial distances allowed: 50km and 160km';
    };

    return (
        <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
                {isAudax && (
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Time Control Type</label>
                        <select
                            className={`w-full p-2 border rounded-md ${errors.timeControlType ? 'border-red-500' : ''}`}
                            value={formData.timeControlType || ''}
                            onChange={(e) => handleInputChange('timeControlType', e.target.value)}
                        >
                            <option value="">Select type</option>
                            <option value="BRM">BRM (15 km/h)</option>
                            <option value="Populaire">Populaire (12.5 km/h)</option>
                        </select>
                        {errors.timeControlType && <p className="text-red-500 text-sm">{errors.timeControlType}</p>}
                    </div>
                )}
                <div className={`space-y-2 ${!isAudax ? 'col-span-2' : ''}`}>
                    <label className="block text-sm font-medium">Distance (km)</label>
                    <input
                        type="number"
                        className={`w-full p-2 border rounded-md ${errors.distance ? 'border-red-500' : ''}`}
                        placeholder="Enter distance"
                        value={formData.distance || ''}
                        onChange={(e) => handleInputChange('distance', e.target.value)}
                    />
                    {errors.distance && <p className="text-red-500 text-sm">{errors.distance}</p>}
                    <p className="text-xs text-gray-500 mt-1 whitespace-pre-line">
                        {getDistanceHelperText()}
                    </p>
                </div>
            </div>

            <div className="space-y-2">
                <label className="block text-sm font-medium">Start Point (required)</label>
                <input
                    type="text"
                    className={`w-full p-2 border rounded-md ${errors.startPoint ? 'border-red-500' : ''}`}
                    placeholder="Enter start location"
                    value={formData.startPoint || ''}
                    onChange={(e) => handleInputChange('startPoint', e.target.value)}
                />
                {errors.startPoint && <p className="text-red-500 text-sm">{errors.startPoint}</p>}
            </div>

            <div className="space-y-2">
                <label className="block text-sm font-medium">Route Link </label>
                <input
                    type="url"
                    className={`w-full p-2 border rounded-md ${errors.route ? 'border-red-500' : ''}`}
                    placeholder="Paste Ride with GPS route URL"
                    value={formData.route || ''}
                    onChange={(e) => handleInputChange('route', e.target.value)}
                />
                {errors.route && <p className="text-red-500 text-sm">{errors.route}</p>}
                <p className="text-xs text-gray-500">Must be a valid Ride with GPS route link (e.g., https://ridewithgps.com/routes/12345678)</p>
            </div>
        </div>
    );
};

export default Step2RouteDetails;

import React from 'react';
import { MapPin } from 'lucide-react';

const LocationHeader = ({ startPoint }) => {
  if (!startPoint) return null;
  
  return (
    <div className="mt-4 flex items-start gap-2 bg-white/50 backdrop-blur-sm rounded-lg px-4 py-3 border border-gray-200/50">
      <MapPin className="w-5 h-5 text-gray-500 mt-0.5 shrink-0" />
      <div>
        <div className="text-sm font-medium text-gray-700 mb-1">Start Point</div>
        <div className="text-sm text-gray-600 whitespace-pre-line">
          {startPoint}
        </div>
      </div>
    </div>
  );
};

export default LocationHeader;

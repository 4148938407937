import React, { useState } from 'react';
import AdminLayout from '../AdminLayout';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';

export default function RaceResults() {
  const { rideId } = useParams();
  const queryClient = useQueryClient();
  const [riders, setRiders] = useState([]);
  const [editingResult, setEditingResult] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');

  const { data: rideDetails } = useQuery({
    queryKey: ['rideDetails', rideId],
    queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
    staleTime: 1000 * 60 * 5,
  });

  const mutation = useMutation({
    mutationFn: async ({ userId, result, rank }) => {
      return axios.post(`/rides/${rideId}/riders/${userId}/result`, {
        result,
        rank: result === 'Finished' ? parseInt(rank, 10) : null
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['rideDetails', rideId]);
      setEditingResult(null);
    }
  });

  return (
    <AdminLayout title="Race Results Management">
      <div className="flex flex-wrap gap-4 mb-6">
        <input
          type="text"
          placeholder="Search by name or URN..."
          className="px-4 py-2 border rounded-lg flex-grow"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        
        <select
          className="px-4 py-2 border rounded-lg"
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
        >
          <option value="all">All Categories</option>
          {rideDetails?.categories?.map(category => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">URN</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Result</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rank</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {riders.map((rider) => (
              <tr key={rider.userId}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{rider.urn}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{rider.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {rideDetails?.categories?.find(cat => cat.id === rider.categoryId)?.name || 'Uncategorized'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{rider.result || '-'}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{rider.rank || '-'}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => setEditingResult({
                      riderId: rider.userId,
                      result: rider.result || '',
                      rank: rider.rank || ''
                    })}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
}

import React from 'react';
import AdminLayout from '../AdminLayout';

function LiveTracking() {
    return (
        <AdminLayout title="Live Race Tracking">
            <div className="bg-warning/20 border border-warning rounded-lg p-4 mb-6">
                <p className="text-textSecondary">
                    Live race tracking features coming soon:
                </p>
                <ul className="list-disc ml-6 mt-2 text-textSecondary">
                    <li>Real-time participant locations</li>
                    <li>Dynamic race progress visualization</li>
                    <li>Checkpoint crossing notifications</li>
                    <li>Race pace analytics</li>
                </ul>
            </div>
        </AdminLayout>
    );
}

export default LiveTracking;

import React from 'react';
import axios from '../config/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight, FaFileDownload } from 'react-icons/fa';
import { format, isBefore } from 'date-fns';
import { useAuth } from '../context/AuthContext';
import { useQuery } from '@tanstack/react-query';
import ErrorMessage from './Message';

const fetchMyRides = async () => {
  const response = await axios.get('/riders/rides');
  return response.data;
};

function MyRides() {
  const navigate = useNavigate();
  const { state } = useAuth();

  const { data: myRides, error, isLoading } = useQuery({
    queryKey: ['myRides'],
    queryFn: fetchMyRides,
    enabled: state.isAuthenticated, // Only run query if authenticated
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  if (!state.isAuthenticated) {
    navigate(`/login?redirect=/myrides`);
    return null;
  }

  if (isLoading) return <p>Loading...</p>;

  if (error) {
    console.error('Error fetching rides:', error);
    const errorMessage = error.response
      ? `Error (${error.response.status}): ${error.response.data?.message || JSON.stringify(error.response.data)}`
      : `Error: ${error.message}`;
    return <ErrorMessage message={errorMessage} />;
  }

  console.log('User state:', state.user);

  const handleRowClick = (rideId) => {
    navigate(`/rides/${rideId}`);
  };

  const handleCertificateDownload = (certificateToken) => {
    if (!certificateToken) {
      console.error('Invalid certificate token:', certificateToken);
      return;
    }
    const certificateUrl = `https://dwq9s0jf64na8.cloudfront.net/rides/certificates/${certificateToken}`;
    window.open(certificateUrl, '_blank');
  };

  const currentDate = new Date();
  const upcomingRides = myRides
    .filter(ride => isBefore(currentDate, new Date(ride.rideDetails.date)))
    .sort((a, b) => new Date(a.rideDetails.date) - new Date(b.rideDetails.date));

  const completedRides = myRides
    .filter(ride => !isBefore(currentDate, new Date(ride.rideDetails.date)))
    .sort((a, b) => new Date(b.rideDetails.date) - new Date(a.rideDetails.date));

  const renderRidesTable = (rides, title) => (
    <div className="mt-8">
      <h2 className="text-2xl font-semibold text-primary mb-4">{title}</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border rounded-lg overflow-hidden shadow-lg">
          <thead className="bg-primary text-white">
            <tr>
              <th className="py-1 px-2 border-b-2 border-primary text-left">Ride Name</th>
              <th className="py-1 px-2 border-b-2 border-primary text-left">Date</th>
              <th className="py-1 px-2 border-b-2 border-primary text-left">Distance (km)</th>
              <th className="py-1 px-2 border-b-2 border-primary text-left">Result</th>
            </tr>
          </thead>
          <tbody>
            {rides.map((ride, index) => (
              <tr
                key={index}
                className="hover:bg-gray-100 cursor-pointer transition duration-150 ease-in-out"
                onClick={() => handleRowClick(ride.rideDetails.rideId)}
              >
                <td className="py-2 px-2 border-b border-gray-200 capitalize text-gray-700">
                  <div className="flex items-center justify-between">
                    <span>{ride.rideDetails.name}</span>
                    <FaArrowRight
                      className="text-primary hover:text-secondary ml-2"
                      style={{ fontSize: '1.2rem' }} // Adjusted size for theme consistency
                    />
                  </div>
                </td>
                <td className="py-2 px-2 border-b border-gray-200 text-gray-700">
                  {ride.rideDetails.date ? format(new Date(ride.rideDetails.date), 'dd MMM yyyy') : 'N/A'}
                </td>
                <td className="py-2 px-2 border-b border-gray-200 text-gray-700">{ride.rideDetails.distance}</td>
                <td className="py-2 px-2 border-b border-gray-200 text-gray-700">
                  <div className="flex items-center justify-between">
                    <span>
                      {ride.result}
                      {ride.totalTime ? ` (${ride.totalTime})` : ''}
                    </span>
                    {ride.result === 'Finished' && ride.certificateToken && (
                      <FaFileDownload
                        className="text-primary hover:text-secondary cursor-pointer"
                        style={{ fontSize: '1.5rem' }} // Adjusted size for prominence
                        title="Download Certificate"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCertificateDownload(ride.certificateToken);
                        }}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-6 bg-white rounded-lg shadow-lg mt-8">
      <h1 className="text-3xl font-semibold text-primary mb-4">My Rides</h1>
      {renderRidesTable(upcomingRides, 'Upcoming Rides')}
      {renderRidesTable(completedRides, 'Completed Rides')}
    </div>
  );
}

export default MyRides;

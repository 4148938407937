import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../../config/axiosConfig';
import { useAuth } from '../../context/AuthContext';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, Window, ChannelHeader, MessageList, MessageInput, Thread } from 'stream-chat-react';
import { FaComments, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { format, isBefore, subDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from './Message.js';

const fetchMyRides = async () => {
    const response = await axiosInstance.get('/riders/rides');
    return response.data;
};

function AllChats() {
    const { state } = useAuth();
    const navigate = useNavigate();
    const [client, setClient] = useState(null);
    const [channel, setChannel] = useState(null);
    const [selectedRideName, setSelectedRideName] = useState('');
    const [error, setError] = useState(null);
    const [showPastRides, setShowPastRides] = useState(false);

    const { data: myRides, error: fetchError, isLoading } = useQuery({
        queryKey: ['myRides'],
        queryFn: fetchMyRides,
        enabled: state.isAuthenticated,
        staleTime: 1000 * 60 * 5,
    });

    useEffect(() => {
        const initChat = async () => {
            try {
                const idTokenPayload = JSON.parse(localStorage.getItem('idTokenPayload'));
                const urn = idTokenPayload?.['custom:userId'];
                const name = idTokenPayload?.name || 'User';

                if (!urn) {
                    throw new Error('URN not found in ID token');
                }

                const validUserId = urn.replace(/\./g, '_');

                const response = await axiosInstance.get('/generate-chat-token', {
                    params: { user_id: validUserId }
                });
                const userToken = response.data.token;

                if (!userToken) {
                    throw new Error('Token not received from server');
                }

                const chatClient = StreamChat.getInstance('mjf6ztvrx7zd');
                await chatClient.connectUser(
                    {
                        id: validUserId,
                        name: name,
                    },
                    userToken
                );

                setClient(chatClient);
            } catch (error) {
                console.error('Error initializing chat:', error);
                setError('Failed to load chat. Please try again later.');
            }
        };

        initChat();

        return () => {
            if (client) {
                client.disconnectUser();
            }
        };
    }, [state.isAuthenticated]);

    if (!state.isAuthenticated) {
        navigate(`/login?redirect=/mychats`);
        return null;
    }

    if (isLoading) return <p>Loading...</p>;

    if (fetchError) {
        console.error('Error fetching rides:', fetchError);
        const errorMessage = fetchError.response
            ? `Error (${fetchError.response.status}): ${fetchError.response.data?.message || JSON.stringify(fetchError.response.data)}`
            : `Error: ${fetchError.message}`;
        return <ErrorMessage message={errorMessage} />;
    }

    const currentDate = new Date();
    const pastRides = myRides.filter(ride => isBefore(new Date(ride.rideDetails.date), subDays(currentDate, 1)));
    const upcomingRides = myRides.filter(ride => !isBefore(new Date(ride.rideDetails.date), subDays(currentDate, 1)));

    const handleChatClick = async (rideId, rideName) => {
        try {
            setSelectedRideName(rideName);
            const rideChannel = client.channel('messaging', `ride-${rideId}`, {
                name: `Chat for ${rideName}`,
                members: [client.userID],
            });
            await rideChannel.watch();
            setChannel(rideChannel);
        } catch (error) {
            console.error('Error opening chat:', error);
            setError('Failed to load chat. Please try again later.');
        }
    };

    const renderRidesTable = (rides, title) => (
        <div className="mt-8">
            <h2 className="text-2xl font-semibold text-primary mb-4">{title}</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border rounded-lg overflow-hidden shadow-lg">
                    <thead className="bg-primary text-white">
                        <tr>
                            <th className="py-2 px-4 border-b-2 border-primary text-left">Ride Name</th>
                            <th className="py-2 px-4 border-b-2 border-primary text-left">Date</th>
                            <th className="py-2 px-4 border-b-2 border-primary text-left">Distance (km)</th>
                            <th className="py-2 px-4 border-b-2 border-primary text-left">Chat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rides.map((ride, index) => (
                            <tr
                                key={index}
                                className="hover:bg-gray-100 cursor-pointer transition duration-150 ease-in-out"
                            >
                                <td className="py-2 px-4 border-b border-gray-200 capitalize text-gray-700">
                                    <span
                                        onClick={() => navigate(`/rides/${ride.rideDetails.rideId}`)}
                                        className="font-semibold text-primary hover:underline cursor-pointer"
                                    >
                                        {ride.rideDetails.name.length > 20
                                            ? `${ride.rideDetails.name.substring(0, 20)}...`
                                            : ride.rideDetails.name}
                                    </span>
                                </td>
                                <td className="py-2 px-4 border-b border-gray-200 text-gray-700">
                                    {ride.rideDetails.date ? format(new Date(ride.rideDetails.date), 'dd MMM yyyy') : 'N/A'}
                                </td>
                                <td className="py-2 px-4 border-b border-gray-200 text-gray-700">{ride.rideDetails.distance}</td>
                                <td className="py-2 px-4 border-b border-gray-200 text-gray-700">
                                    <button
                                        onClick={() => handleChatClick(ride.rideDetails.rideId, ride.rideDetails.name)}
                                        className="text-primary hover:text-secondary flex items-center"
                                    >
                                        <FaComments className="mr-2" /> Open Chat
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

    return (
        <div className="max-w-6xl mx-auto p-4 sm:p-6 bg-white rounded-lg shadow-lg mt-8">
            <h1 className="text-3xl font-semibold text-primary mb-4">My Ride Chats</h1>
            {renderRidesTable(upcomingRides, 'Upcoming Ride Chats')}
            <div className="mt-8">
                <h2 className="text-2xl font-semibold text-primary mb-4 flex items-center justify-between">
                    Past Ride Chats
                    <button onClick={() => setShowPastRides(!showPastRides)} className="text-primary hover:text-secondary flex items-center">
                        {showPastRides ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                </h2>
                {showPastRides && renderRidesTable(pastRides, '')}
            </div>
            {channel && (
                <div className="mt-8 bg-gray-100 p-4 rounded-lg shadow-lg">
                    <div className="h-96"> {/* Fixed height for chat container */}
                        <Chat client={client} theme="messaging light">
                            <Channel channel={channel}>
                                <Window>
                                    <ChannelHeader title={`Chat for ${selectedRideName}`} />
                                    <MessageList className="overflow-y-auto" /> {/* Add overflow-y-auto for scrolling */}
                                    <MessageInput />
                                </Window>
                                <Thread />
                            </Channel>
                        </Chat>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AllChats;
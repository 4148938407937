import React, { useState, useRef, useEffect } from 'react';

const CategoryActionDropdown = ({ currentCategory, categories, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const getCurrentCategoryName = () => {
        const category = categories.find((cat) => cat.id === currentCategory);
        return category ? category.name : null;
    };

    return (
        <div className="relative inline-block text-left w-full" ref={dropdownRef}>
            <span
                onClick={() => setIsOpen(!isOpen)}
                className={`cursor-pointer text-sm font-medium ${currentCategory ? 'text-blue-700' : 'text-gray-700'
                    } hover:underline`}
            >
                {getCurrentCategoryName() || 'Assign Category'}
            </span>

            {isOpen && (
                <div className="absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                    <div className="py-1 max-h-60 overflow-auto" role="menu">
                        {categories.map((category) => (
                            <button
                                key={category.id}
                                onClick={() => {
                                    onSelect(category.id);
                                    setIsOpen(false);
                                }}
                                className={`${currentCategory === category.id
                                        ? 'bg-blue-50 text-blue-700'
                                        : 'text-gray-700 hover:bg-gray-50'
                                    } group flex items-center w-full px-4 py-2 text-sm transition-colors duration-150 ease-in-out`}
                                role="menuitem"
                            >
                                <span className="truncate">{category.name}</span>
                                {currentCategory === category.id && (
                                    <span className="flex-shrink-0 ml-2 text-blue-500">✓</span>
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CategoryActionDropdown;

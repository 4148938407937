import React, { useState, useCallback, memo } from 'react';
import { Trophy, Medal, Award, Star } from 'lucide-react';

const ProfileImage = memo(({ rider, size, onError }) => {
  const [hasError, setHasError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [src, setSrc] = useState(`https://ultrarides-images.s3.ap-south-1.amazonaws.com/profiles/${rider.urn}.jpg`);

  const handleError = useCallback(() => {
    if (!hasError) {
      setHasError(true);
      setSrc(`/api/placeholder/${size}/${size}`);
      onError?.(rider.urn);
    }
  }, [hasError, size, onError, rider.urn]);

  return (
    <div className="relative">
      <div
        className={`
          absolute inset-0 rounded-full 
          ${rider.rank === 1 ? 'bg-gradient-to-r from-gold to-yellow-400' :
            rider.rank === 2 ? 'bg-gradient-to-r from-silver to-gray-400' :
              'bg-gradient-to-r from-bronze to-orange-400'}
          animate-pulse
        `}
      />
      <img
        src={src}
        alt={rider.name}
        className={`
          rounded-full relative
          transition-opacity duration-300
          ${isLoaded ? 'opacity-100' : 'opacity-0'}
          hover:scale-110 transition-transform duration-300
        `}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          objectFit: 'cover'
        }}
        onError={handleError}
        onLoad={() => setIsLoaded(true)}
        loading="lazy"
      />
      {!isLoaded && !hasError && (
        <div
          className="absolute inset-0 bg-secondary/20 rounded-full animate-pulse"
          style={{ width: `${size}px`, height: `${size}px` }}
        />
      )}
    </div>
  );
});

const WinnerCard = memo(({ winner, size }) => {
  const getRankIcon = (rank) => {
    switch (rank) {
      case 1: return <Trophy className="w-6 h-6 text-gold" />;
      case 2: return <Medal className="w-6 h-6 text-silver" />;
      case 3: return <Award className="w-6 h-6 text-bronze" />;
      default: return null;
    }
  };

  const getPoints = (rank) => {
    switch (rank) {
      case 1: return 100;
      case 2: return 80;
      case 3: return 60;
      default: return 0;
    }
  };

  return (
    <div className={`
      w-full rounded-lg shadow-md
      transform transition-all duration-300 
      hover:-translate-y-2 hover:shadow-lg
      ${winner.rank === 1 ? 'bg-warning/20' :
        winner.rank === 2 ? 'bg-secondary/10' :
          'bg-bronze/10'}
      sm:max-w-xs
      p-2 sm:p-4
    `}>
      <div className="text-center">
        <div className="flex justify-center">
          {getRankIcon(winner.rank)}
        </div>
        <h3 className="text-base sm:text-lg font-bold mt-1 sm:mt-2 text-textPrimary">{winner.name}</h3>
        <p className="text-textSecondary text-xs sm:text-sm">
          Rank #{winner.rank}
        </p>
      </div>
      <div className="flex flex-col items-center">
        <ProfileImage
          rider={winner}
          size={size}
        />
        <div className="mt-2 sm:mt-4 w-full">
          <div className="flex items-center justify-center gap-1 sm:gap-2 text-xs sm:text-sm font-medium text-accent">
            <Star className="w-3 h-3 sm:w-4 sm:h-4" />
            <span>{getPoints(winner.rank)} points</span>
          </div>
        </div>
      </div>
    </div>
  );
});

const PodiumDisplay = memo(({ winners }) => {
  const getResponsiveSizes = () => ({
    small: { first: 90, second: 80, third: 70 },
    large: { first: 120, second: 100, third: 90 }
  });

  const positions = [
    { rank: 2, offset: 'sm:mt-8' },
    { rank: 1, offset: 'mt-0' },
    { rank: 3, offset: 'sm:mt-16' }
  ];

  return (
    <div className="grid grid-cols-3 gap-1 sm:gap-4 py-2 sm:py-8">
      {positions.map(({ rank, offset }) => {
        const winner = winners.find(w => w.rank === rank);
        if (!winner) return null;

        const sizes = getResponsiveSizes();
        const size = rank === 1 ? sizes.small.first :
          rank === 2 ? sizes.small.second :
            sizes.small.third;

        return (
          <div key={rank} className={`${offset} flex justify-center`}>
            <WinnerCard
              winner={winner}
              size={size}
            />
          </div>
        );
      })}
    </div>
  );
});

const CategoryPodium = ({ categoryResults = [] }) => {
  const categorizedWinners = categoryResults.reduce((acc, result) => {
    if (!acc[result.categoryName]) {
      acc[result.categoryName] = [];
    }
    if (result.rank <= 3) {
      acc[result.categoryName].push(result);
    }
    return acc;
  }, {});

  const categories = Object.keys(categorizedWinners);

  // Initialize state before any conditional returns
  const [activeCategory, setActiveCategory] = useState(categories[0] || null);

  // Return null if no categories exist
  if (categories.length === 0) return null;

  return (
    <div className="w-full py-2 sm:py-4">
      <div className="w-full">
        <div className="flex flex-wrap gap-1 sm:gap-2 mb-3 sm:mb-6">
          {categories.map(category => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`
                px-2 sm:px-4 py-1 sm:py-2 rounded-full
                transition-all duration-300
                ${activeCategory === category ?
                  'bg-primary text-white scale-105 sm:scale-110' :
                  'bg-secondary/20 text-textSecondary hover:bg-secondary/30'}
                text-xs sm:text-sm font-medium
              `}
            >
              {category}
            </button>
          ))}
        </div>

        {categories.map(category => (
          <div
            key={category}
            className={`
              bg-white rounded-lg shadow-md mt-2 sm:mt-4
              transition-all duration-500
              transform
              ${activeCategory === category ? 'block scale-100' : 'hidden scale-95'}
            `}
          >
            <div className="p-2 sm:p-4">
              <h3 className="text-xl sm:text-2xl font-bold text-primary mb-3 sm:mb-6 text-center">
                {category} Winners
              </h3>
              <PodiumDisplay
                winners={categorizedWinners[category]}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryPodium;
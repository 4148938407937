import React from 'react';

const Testimonials = () => {
  return (
    <div className="mt-20">
      <h2 className="text-4xl font-extrabold text-center mb-12">What Our Riders Say</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <p className="text-gray-600 mb-6">"This platform has transformed my cycling experience. I've met so many amazing people!"</p>
          <h3 className="text-xl font-semibold">- Aditya B.</h3>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <p className="text-gray-600 mb-6">"I love the variety of rides available. There's always something new to explore."</p>
          <h3 className="text-xl font-semibold">- James T.</h3>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <p className="text-gray-600 mb-6">"Tracking my progress has never been easier. Highly recommend this platform!"</p>
          <h3 className="text-xl font-semibold">- Emily R.</h3>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

export const validateStep = (stepNumber, formData) => {
    const errors = {};

    if (stepNumber === 1) {
        if (!formData.name?.trim()) {
            errors.name = 'Ride name is required';
        }
        if (!formData.date) {
            errors.date = 'Date is required';
        } else {
            const selectedDate = new Date(formData.date + 'T00:00:00');
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            if (selectedDate < today) {
                errors.date = 'Date cannot be in the past';
            }
        }
    }

    if (stepNumber === 3 && formData.rideType === 'audax') {
        formData.controlPoints.forEach((point, index) => {
            if (!point.distance && point.distance !== 0) {
                errors[`controlPoint${index}Distance`] = 'Distance is required';
            }
            if (!point.description?.trim()) {
                errors[`controlPoint${index}Description`] = 'Description is required';
            }
            if (!point.gpsCoordinates?.trim()) {
                errors[`controlPoint${index}GPS`] = 'GPS coordinates are required';
            } else {
                const gpsPattern = /^-?\d+\.\d{5},\s*-?\d+\.\d{5}$/;
                if (!gpsPattern.test(point.gpsCoordinates)) {
                    errors[`controlPoint${index}GPS`] = 'Invalid GPS format. Use "latitude, longitude" with exactly 5 decimal places';
                }
            }
        });
    }
    
    return errors;
};

export const calculateControlTime = (distance, startTime, timeControlType, date) => {
    if (!distance || !startTime || !timeControlType || !date) {
        return null;
    }

    const numericDistance = parseFloat(distance);
    if (isNaN(numericDistance)) {
        return null;
    }

    const speed = timeControlType === 'BRM' ? 15 : 12.5;
    const timeInHours = numericDistance / speed;

    const [hours, minutes] = startTime.split(':');
    const closeTime = new Date(date + 'T00:00:00');
    closeTime.setHours(parseInt(hours, 10), parseInt(minutes, 10));
    closeTime.setTime(closeTime.getTime() + (timeInHours * 60 * 60 * 1000));
    
    // Return ISO string without timezone info
    return closeTime.toISOString().split('.')[0];
};

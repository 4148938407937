import React from 'react';
import { FaBrain, FaDumbbell, FaBalanceScale } from 'react-icons/fa';

const MeetOurTeam = () => {
  const teamMembers = [
    {
      name: 'Rohan Sharma',
      role: '',
      bio: 'Rohan is the mastermind behind all our innovative strategies. He ensures we are always ahead of the curve.',
      icon: <FaBrain className="text-6xl text-primary" />,
    },
    {
      name: 'Sanjeev Sharma',
      role: '',
      bio: 'Sanjeev is our go-to guy for all things physical. He keeps the team in top shape and ready for any challenge.',
      icon: <FaDumbbell className="text-6xl text-primary" />,
    },
    {
      name: 'Hitesh Sablok',
      role: '',
      bio: 'Hitesh is our legal eagle, making sure all our operations are above board and running smoothly.',
      icon: <FaBalanceScale className="text-6xl text-primary" />,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="bg-white max-w-4xl mx-auto p-6 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold text-center text-primary mb-4">Meet Our Team</h1>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
          <div className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200 col-span-1 md:col-span-6 md:col-start-4">
            <div className="flex flex-col items-center mb-2">
              {teamMembers[0].icon}
              <h2 className="text-xl font-semibold text-primary mt-4">{teamMembers[0].name}</h2>
            </div>
            <p className="text-gray-600 text-center">{teamMembers[0].bio}</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 col-span-1 md:col-span-12">
            {teamMembers.slice(1).map((member, index) => (
              <div key={index} className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
                <div className="flex flex-col items-center mb-2">
                  {member.icon}
                  <h2 className="text-xl font-semibold text-primary mt-4">{member.name}</h2>
                </div>
                <p className="text-gray-600 text-center">{member.bio}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetOurTeam;
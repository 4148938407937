import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';
import AdminLayout from '../AdminLayout';
import AdminAccessCheck from '../AdminAccessCheck';
import { FaSearch, FaFilter, FaSort, FaDownload } from 'react-icons/fa';

const RegistrationDetails = () => {
    const { rideId } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [showUnpaidOnly, setShowUnpaidOnly] = useState(false);
    const [showOfflineOnly, setShowOfflineOnly] = useState(false);
    const [showMissingWaiverOnly, setShowMissingWaiverOnly] = useState(false);
    const [filteredRiders, setFilteredRiders] = useState([]);

    const { data: rideDetails } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
    });

    useEffect(() => {
        if (rideDetails?.registeredRiders) {
            let filtered = [...rideDetails.registeredRiders];

            if (showUnpaidOnly) {
                filtered = filtered.filter(rider => !rider.payment?.status);
            }

            if (showOfflineOnly) {
                filtered = filtered.filter(rider => rider.payment?.offlinePayment);
            }

            if (showMissingWaiverOnly) {
                filtered = filtered.filter(rider => !rider.waiver);
            }

            if (searchTerm) {
                const search = searchTerm.toLowerCase();
                filtered = filtered.filter(rider =>
                    rider.name?.toLowerCase().includes(search) ||
                    rider.email?.toLowerCase().includes(search) ||
                    rider.phone?.toLowerCase().includes(search) ||
                    rider.urn?.toLowerCase().includes(search)
                );
            }

            setFilteredRiders(filtered);
        }
    }, [rideDetails, searchTerm, showUnpaidOnly, showOfflineOnly, showMissingWaiverOnly]);

    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });

        const sorted = [...filteredRiders].sort((a, b) => {
            if (!a[key]) return direction === 'asc' ? 1 : -1;
            if (!b[key]) return direction === 'asc' ? -1 : 1;
            return direction === 'asc'
                ? a[key].toString().localeCompare(b[key].toString())
                : b[key].toString().localeCompare(a[key].toString());
        });

        setFilteredRiders(sorted);
    };

    const getPaymentStats = () => {
        if (!rideDetails?.registeredRiders) return { total: 0, paid: 0, unpaid: 0, offline: 0 };

        const riders = rideDetails.registeredRiders;
        return {
            total: riders.length,
            paid: riders.filter(r => r.payment?.status && !r.payment?.offlinePayment).length,
            unpaid: riders.filter(r => !r.payment?.status).length,
            offline: riders.filter(r => r.payment?.offlinePayment).length
        };
    };

    const exportToCSV = () => {
        const headers = ['URN', 'Name', 'Email', 'Phone', 'Payment Status', 'Payment Type', 'Waiver Status'];
        const csvContent = [
            headers.join(','),
            ...filteredRiders.map(rider => [
                rider.urn,
                rider.name,
                rider.email,
                rider.phone || 'N/A',
                rider.payment?.status ? 'Paid' : 'Unpaid',
                rider.payment?.status ? (rider.payment?.offlinePayment ? 'Offline' : 'Online') : 'N/A',
                rider.waiver ? 'Submitted' : 'Missing'
            ].join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `registration-details-${new Date().toISOString()}.csv`;
        link.click();
    };

    const stats = getPaymentStats();

    const getPaymentTypeDisplay = (rider) => {
        if (!rider.payment?.status) {
            return (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-500">
                    N/A
                </span>
            );
        }
        return (
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                ${rider.payment?.offlinePayment
                    ? 'bg-blue-100 text-blue-800'
                    : 'bg-gray-100 text-gray-800'
                }`}
            >
                {rider.payment?.offlinePayment ? 'Offline' : 'Online'}
            </span>
        );
    };

    return (
        <AdminAccessCheck isAdmin={rideDetails?.user?.isClubOwner}>
            <AdminLayout title="Registration Details">
                <div className="space-y-4">
                    {/* Stats Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                            <p className="text-sm text-gray-600">Total Registrations</p>
                            <p className="text-2xl font-bold text-gray-900">{stats.total}</p>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                            <p className="text-sm text-gray-600">Online Paid</p>
                            <p className="text-2xl font-bold text-green-600">{stats.paid}</p>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                            <p className="text-sm text-gray-600">Offline Paid</p>
                            <p className="text-2xl font-bold text-blue-600">{stats.offline}</p>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                            <p className="text-sm text-gray-600">Pending Payments</p>
                            <p className="text-2xl font-bold text-red-600">{stats.unpaid}</p>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                            <p className="text-sm text-gray-600">Missing Waivers</p>
                            <p className="text-2xl font-bold text-yellow-600">
                                {rideDetails?.registeredRiders?.filter(r => !r.waiver).length || 0}
                            </p>
                        </div>
                    </div>

                    {/* Filters and Search */}
                    <div className="flex flex-wrap gap-4 items-center">
                        <div className="flex-grow relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <FaSearch className="text-gray-400" />
                            </div>
                            <input
                                type="text"
                                placeholder="Search by name, email, phone, or URN..."
                                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <button
                            onClick={() => {
                                setShowUnpaidOnly(!showUnpaidOnly);
                                setShowOfflineOnly(false);
                            }}
                            className={`flex items-center gap-2 px-4 py-2 rounded-lg font-medium transition-colors
                                ${showUnpaidOnly
                                    ? 'bg-red-100 text-red-800 border border-red-300'
                                    : 'bg-gray-100 text-gray-800 border border-gray-300'
                                }`}
                        >
                            <FaFilter />
                            {showUnpaidOnly ? 'Showing Unpaid Only' : 'Show All Payments'}
                        </button>
                        <button
                            onClick={() => {
                                setShowOfflineOnly(!showOfflineOnly);
                                setShowUnpaidOnly(false);
                            }}
                            className={`flex items-center gap-2 px-4 py-2 rounded-lg font-medium transition-colors
                                ${showOfflineOnly
                                    ? 'bg-blue-100 text-blue-800 border border-blue-300'
                                    : 'bg-gray-100 text-gray-800 border border-gray-300'
                                }`}
                        >
                            <FaFilter />
                            {showOfflineOnly ? 'Showing Offline Only' : 'Show All Payment Types'}
                        </button>
                        <button
                            onClick={() => setShowMissingWaiverOnly(!showMissingWaiverOnly)}
                            className={`flex items-center gap-2 px-4 py-2 rounded-lg font-medium transition-colors
                                ${showMissingWaiverOnly
                                    ? 'bg-yellow-100 text-yellow-800 border border-yellow-300'
                                    : 'bg-gray-100 text-gray-800 border border-gray-300'
                                }`}
                        >
                            <FaFilter />
                            {showMissingWaiverOnly ? 'Showing Missing Waiver Only' : 'Show All Waivers'}
                        </button>
                        <button
                            onClick={exportToCSV}
                            className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                        >
                            <FaDownload />
                            Export to CSV
                        </button>
                    </div>

                    {/* Table */}
                    <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden">
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                            onClick={() => handleSort('urn')}
                                        >
                                            URN <FaSort className="inline ml-1" />
                                        </th>
                                        <th
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                            onClick={() => handleSort('name')}
                                        >
                                            Name <FaSort className="inline ml-1" />
                                        </th>
                                        <th
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                            onClick={() => handleSort('email')}
                                        >
                                            Email <FaSort className="inline ml-1" />
                                        </th>
                                        <th
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                            onClick={() => handleSort('phone')}
                                        >
                                            Phone <FaSort className="inline ml-1" />
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Payment Status
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Payment Type
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Waiver Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {filteredRiders.map((rider) => (
                                        <tr key={rider.urn} className="hover:bg-gray-50">
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                {rider.urn}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {rider.name}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {rider.email}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {rider.phone || 'N/A'}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                                    ${rider.payment?.status
                                                        ? 'bg-green-100 text-green-800'
                                                        : 'bg-red-100 text-red-800'
                                                    }`}
                                                >
                                                    {rider.payment?.status ? 'Paid' : 'Unpaid'}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {getPaymentTypeDisplay(rider)}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                                    ${rider.waiver
                                                        ? 'bg-green-100 text-green-800'
                                                        : 'bg-yellow-100 text-yellow-800'
                                                    }`}
                                                >
                                                    {rider.waiver ? 'Submitted' : 'Missing'}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </AdminAccessCheck>
    );
};

export default RegistrationDetails;
import React from 'react';

function NoDataCheckIn() {
    return (
        <div className='text-center'>
            <div className='w-4 h-4 rounded-full bg-red-500' />
        </div>
    );
}

export default NoDataCheckIn;

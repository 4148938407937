import React from 'react';

function StakeholderForm({ formData, handleChange, nextStep, prevStep }) {
  return (
    <form onSubmit={(e) => { e.preventDefault(); nextStep(); }} className="space-y-4">
      <h2 className="text-xl font-bold">Stakeholder Information</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label>Stakeholder Name:</label>
          <input type="text" name="stakeholderName" value={formData.stakeholderName} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>Residential Street Address:</label>
          <input type="text" name="residentialStreet" value={formData.residentialStreet} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>Residential City:</label>
          <input type="text" name="residentialCity" value={formData.residentialCity} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>Residential State:</label>
          <input type="text" name="residentialState" value={formData.residentialState} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>Residential Postal Code:</label>
          <input type="text" name="residentialPostalCode" value={formData.residentialPostalCode} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>Residential Country:</label>
          <input type="text" name="residentialCountry" value={formData.residentialCountry} onChange={handleChange} className="input" required />
        </div>
        <div>
          <label>Stakeholder PAN Number:</label>
          <input type="text" name="stakeholderPan" value={formData.stakeholderPan} onChange={handleChange} className="input" required />
        </div>
      </div>
      <div className="flex justify-between">
        <button type="button" onClick={prevStep} className="btn-secondary">Back</button>
        <button type="submit" className="btn-primary">Next</button>
      </div>
    </form>
  );
}

export default StakeholderForm;

import React from 'react';
import { FaBan } from 'react-icons/fa';

function Settings() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 pt-16">
      <div className="bg-white w-full max-w-4xl p-6 rounded-lg shadow-lg text-center">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Settings</h1>
        <div className="text-gray-600 text-lg mb-6 flex flex-col items-center">
          <FaBan className="text-6xl text-primary mb-4" />
          <p>No settings editable at this time.</p>
        </div>
      </div>
    </div>
  );
}

export default Settings;

import React, { useState, useEffect } from 'react';
import AdminLayout from '../AdminLayout';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';
import ErrorMessage from '../../Message';
import AdminAccessCheck from '../AdminAccessCheck';
import { FaPlus } from 'react-icons/fa';

function RideCategories() {
 const { rideId } = useParams();
 const [isClubOwner, setIsClubOwner] = useState(false);
 const queryClient = useQueryClient();

 const { data: rideDetails, error, isLoading } = useQuery({
   queryKey: ['rideDetails', rideId],
   queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
   staleTime: 1000 * 60 * 5,
 });

 useEffect(() => {
   if (rideDetails?.user) {
     setIsClubOwner(rideDetails.user.isClubOwner);
   }
 }, [rideDetails]);

 if (isLoading) return <div>Loading...</div>;
 if (error) return <ErrorMessage message="Error fetching ride details." />;

 return (
   <AdminAccessCheck isAdmin={isClubOwner}>
     <AdminLayout title="Race Categories">
       <div className="mb-8">
         <div className="bg-white border border-gray-200 rounded-lg shadow-sm">
           <div className="px-6 py-4 border-b border-gray-200">
             <h3 className="text-lg font-semibold text-gray-800">Categories</h3>
           </div>
           <div className="p-6 bg-gray-50">
             <div className="flex flex-wrap gap-2">
               {rideDetails?.categories?.map(category => (
                 <div 
                   key={category.id}
                   className="relative px-4 py-2 text-sm font-medium transition-all duration-200 ease-in-out text-gray-600 bg-white border border-gray-300 hover:border-gray-400 hover:text-gray-800 rounded-md"
                 >
                   <span className="relative z-10">{category.name}</span>
                 </div>
               ))}
               <button
                 className="px-4 py-2 text-sm font-medium bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors duration-200 flex items-center gap-2"
               >
                 <FaPlus /> Add Category
               </button>
             </div>
           </div>
         </div>
       </div>
     </AdminLayout>
   </AdminAccessCheck>
 );
}

export default RideCategories;

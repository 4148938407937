import React from 'react';

function CategoryFilter({ categories, selectedCategory, setSelectedCategory, getCategoryCount, registeredRiders }) {
  return (
    <div className="flex flex-col space-y-4">
      <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium text-gray-700 mb-3">Filter by Category</h3>
        <div className="flex flex-wrap gap-3">
          <button
            onClick={() => setSelectedCategory(null)}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
              ${!selectedCategory
                ? 'bg-primary text-white shadow-sm'
                : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
              }`}
          >
            All Riders ({registeredRiders.length})
          </button>
          {categories.map(category => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
                ${selectedCategory === category.id
                  ? 'bg-primary text-white shadow-sm'
                  : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
                }`}
            >
              {category.name} ({getCategoryCount(category.id)})
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CategoryFilter;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import ErrorMessage from '../Message';
import AdminLayout from './AdminLayout';
import AdminAccessCheck from './AdminAccessCheck';
import { FaClock, FaCalendar, FaEdit, FaSave, FaTimes, FaGlobeAmericas } from 'react-icons/fa';
import Select from 'react-select';
import { CURATED_TIMEZONES, formatTimezone, getDefaultTimezone } from '../../utils/timezoneUtils';

function RideInformationEditor() {
   const { rideId } = useParams();
   const queryClient = useQueryClient();
   const [editMode, setEditMode] = useState(false);
   const [rideName, setRideName] = useState('');
   const [distance, setDistance] = useState('');
   const [isClubOwner, setIsClubOwner] = useState(false);
   const [saveSuccess, setSaveSuccess] = useState('');
   const [saveError, setSaveError] = useState('');
   const [timeControlType, setTimeControlType] = useState('');
   const [timezone, setTimezone] = useState(getDefaultTimezone());

   const [startDate, setStartDate] = useState('');
   const [startTime, setStartTime] = useState('');
   const [regClosingDate, setRegClosingDate] = useState('');
   const [regClosingTime, setRegClosingTime] = useState('');

   const timezoneOptions = CURATED_TIMEZONES.map(tz => ({
       value: tz.name,
       label: `${tz.abbr} - ${tz.name} (GMT${tz.offset})`
   }));

   const { data: ride, error, isLoading } = useQuery({
       queryKey: ['rideDetails', rideId],
       queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
       staleTime: 1000 * 60 * 5,
   });

   useEffect(() => {
       if (ride) {
           setRideName(ride.name || '');
           setDistance(ride.distance || '');
           setTimeControlType(ride.timeControlType || '');
           setIsClubOwner(ride.user?.isClubOwner || false);
           setTimezone(ride.timezone || getDefaultTimezone());

           const startDateTime = new Date(ride.startDateTime);
           setStartDate(startDateTime.toISOString().split('T')[0]);
           setStartTime(startDateTime.toTimeString().slice(0, 5));

           const regClosingDateTime = new Date(ride.registrationClosingDateTime);
           setRegClosingDate(regClosingDateTime.toISOString().split('T')[0]);
           setRegClosingTime(regClosingDateTime.toTimeString().slice(0, 5));
       }
   }, [ride]);

   const formatDateWithoutTimezone = (date, time) => {
       const [year, month, day] = date.split('-').map(Number);
       const [hours, minutes] = time.split(':').map(Number);
       const pad = (num) => num.toString().padStart(2, '0');
       return `${year}-${pad(month)}-${pad(day)}T${pad(hours)}:${pad(minutes)}:00`;
   };

   const mutation = useMutation({
       mutationFn: async (updatedData) => {
           const formattedStartDateTime = formatDateWithoutTimezone(startDate, startTime);
           const formattedRegClosingDateTime = formatDateWithoutTimezone(regClosingDate, regClosingTime);

           return axios.patch(`/rides/${rideId}`, {
               ...updatedData,
               startDateTime: formattedStartDateTime,
               registrationClosingDateTime: formattedRegClosingDateTime,
               timezone
           });
       },
       onSuccess: () => {
           queryClient.invalidateQueries(['rideDetails', rideId]);
           setEditMode(false);
           setSaveError('');
           setSaveSuccess('Ride details updated successfully!');
           setTimeout(() => setSaveSuccess(''), 3000);
       },
       onError: (error) => {
           const errorMessage = error.response?.data?.message || error.message || 'Failed to update ride';
           const errorDetails = error.response?.data?.error || '';
           setSaveError(`${errorMessage} ${errorDetails}`);
       }
   });

   const formatDisplayDateTime = (dateString) => {
       if (!dateString) return '';
       const date = new Date(dateString);
       return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
   };

   const handleSaveClick = () => {
       if (!startDate || !startTime || !regClosingDate || !regClosingTime) {
           setSaveError('Please fill in all date and time fields');
           return;
       }

       mutation.mutate({
           name: rideName,
           clubId: ride?.clubId,
           timeControlType,
           isTimeControlled: true
       });
   };

   const handleCancelClick = () => {
       setEditMode(false);
       if (ride) {
           const startDateTime = new Date(ride.startDateTime);
           const regClosingDateTime = new Date(ride.registrationClosingDateTime);
           
           setRideName(ride.name || '');
           setStartDate(startDateTime.toISOString().split('T')[0]);
           setStartTime(startDateTime.toTimeString().slice(0, 5));
           setRegClosingDate(regClosingDateTime.toISOString().split('T')[0]);
           setRegClosingTime(regClosingDateTime.toTimeString().slice(0, 5));
           setTimezone(ride.timezone || getDefaultTimezone());
       }
       setSaveError('');
   };

   if (isLoading) return <div>Loading...</div>;
   if (error) return <ErrorMessage message="Error fetching ride details." />;

   return (
       <AdminAccessCheck isAdmin={isClubOwner}>
           <AdminLayout title="Event Details">
               <div className="space-y-6">
                   {saveSuccess && (
                       <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded-lg">
                           <p className="text-green-700">{saveSuccess}</p>
                       </div>
                   )}

                   {saveError && (
                       <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-lg">
                           <p className="text-red-700">{saveError}</p>
                       </div>
                   )}

                   {!editMode && (
                       <div className="flex justify-end">
                           <button
                               onClick={() => setEditMode(true)}
                               className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors"
                           >
                               <FaEdit /> Edit Details
                           </button>
                       </div>
                   )}

                   <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                       <div className="flex items-center gap-2 text-gray-600 mb-2">
                           <FaGlobeAmericas className="text-lg" />
                           <span>Timezone: {formatTimezone(timezone)}</span>
                       </div>
                       {editMode && (
                           <div className="mt-2">
                               <Select
                                   options={timezoneOptions}
                                   value={timezoneOptions.find(option => option.value === timezone)}
                                   onChange={(option) => setTimezone(option.value)}
                                   isSearchable
                                   placeholder="Search for a timezone..."
                                   className="basic-single"
                                   classNamePrefix="select"
                               />
                           </div>
                       )}
                   </div>

                   <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                       <label className="block text-sm font-medium text-gray-700 mb-2">Ride Name</label>
                       {editMode ? (
                           <input
                               type="text"
                               value={rideName}
                               onChange={(e) => setRideName(e.target.value)}
                               className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                           />
                       ) : (
                           <p className="text-lg text-gray-900">{rideName}</p>
                       )}
                   </div>

                   <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                       <label className="block text-sm font-medium text-gray-700 mb-2">Start Date & Time</label>
                       <div className="flex flex-wrap gap-4">
                           <div className="flex-1 min-w-[200px]">
                               <div className="relative">
                                   <FaCalendar className="absolute left-3 top-3 text-gray-400" />
                                   <input
                                       type="date"
                                       value={startDate}
                                       onChange={(e) => setStartDate(e.target.value)}
                                       className={`w-full pl-10 pr-4 py-2 border rounded-lg ${!editMode ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                                       readOnly={!editMode}
                                   />
                               </div>
                           </div>
                           <div className="flex-1 min-w-[200px]">
                               <div className="relative">
                                   <FaClock className="absolute left-3 top-3 text-gray-400" />
                                   {editMode ? (
                                       <input
                                           type="time"
                                           value={startTime}
                                           onChange={(e) => setStartTime(e.target.value)}
                                           className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                                       />
                                   ) : (
                                       <div className="w-full pl-10 pr-4 py-2 border rounded-lg bg-gray-50">
                                           {formatDisplayDateTime(ride?.startDateTime)}
                                       </div>
                                   )}
                               </div>
                           </div>
                       </div>
                   </div>

                   <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                       <label className="block text-sm font-medium text-gray-700 mb-2">
                           Registration Closing Date & Time
                       </label>
                       <div className="flex flex-wrap gap-4">
                           <div className="flex-1 min-w-[200px]">
                               <div className="relative">
                                   <FaCalendar className="absolute left-3 top-3 text-gray-400" />
                                   {editMode ? (
                                       <input
                                           type="date"
                                           value={regClosingDate}
                                           onChange={(e) => setRegClosingDate(e.target.value)}
                                           className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                                       />
                                   ) : (
                                       <div className="w-full pl-10 pr-4 py-2 border rounded-lg bg-gray-50">
                                           {formatDisplayDateTime(ride?.registrationClosingDateTime)}
                                       </div>
                                   )}
                               </div>
                           </div>
                           <div className="flex-1 min-w-[200px]">
                               <div className="relative">
                                   <FaClock className="absolute left-3 top-3 text-gray-400" />
                                   {editMode ? (
                                       <input
                                           type="time"
                                           value={regClosingTime}
                                           onChange={(e) => setRegClosingTime(e.target.value)}
                                           className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                                       />
                                   ) : (
                                       <div className="w-full pl-10 pr-4 py-2 border rounded-lg bg-gray-50">
                                           {formatDisplayDateTime(ride?.registrationClosingDateTime)}
                                       </div>
                                   )}
                               </div>
                           </div>
                       </div>
                   </div>
                   {editMode && (
                       <div className="flex justify-end gap-4">
                           <button
                               onClick={handleSaveClick}
                               className="flex items-center gap-2 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                           >
                               <FaSave /> Save Changes
                           </button>
                           <button
                               onClick={handleCancelClick}
                               className="flex items-center gap-2 px-6 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors"
                           >
                               <FaTimes /> Cancel
                           </button>
                       </div>
                   )}
               </div>
           </AdminLayout>
       </AdminAccessCheck>
   );
}

export default RideInformationEditor;

import React, { useState } from 'react';
import axios from '../config/axiosConfig';
import { useNavigate, Link } from 'react-router-dom';
import userPool from '../config/cognitoConfig';

function NewClub() {
  const [clubData, setClubData] = useState({
    name: '',
    description: '',
    country: '',
    city: ''
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Function to get the current authenticated user's email
  const getUserEmail = () => {
    const user = userPool.getCurrentUser();
    if (user) {
      return new Promise((resolve, reject) => {
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            user.getUserAttributes((err, attributes) => {
              if (err) {
                reject(err);
              } else {
                const emailAttr = attributes.find(attr => attr.Name === 'email');
                resolve(emailAttr ? emailAttr.Value : null);
              }
            });
          }
        });
      });
    } else {
      return Promise.resolve(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClubData({ ...clubData, [name]: value });
  };

  const handleCreateClub = async () => {
    if (!clubData.name || !clubData.description || !clubData.country || !clubData.city) {
      setMessage('Please provide all required fields.');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      const userEmail = await getUserEmail();

      if (!userEmail) {
        setMessage('User email is missing. Please log in again.');
        setLoading(false);
        return;
      }

      const response = await axios.post('/clubs', {
        ...clubData,
        userId: userEmail // Include the email as the user ID in the request payload
      });

      if (response.status === 200 || response.status === 201) {
        setMessage('Club created successfully!');
        navigate('/owner-dashboard');
      } else {
        setMessage('Failed to create club. Please try again.');
      }
    } catch (error) {
      console.error('Error creating club:', error);
      setMessage('Error creating club. Please try again later.');
    } finally {
      setLoading(false);
      setClubData({
        name: '',
        description: '',
        country: '',
        city: ''
      });
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
      <h1 className="text-2xl font-semibold text-primary mb-4">Create New Club</h1>
      {message && <p className={message.includes('error') ? 'text-red-500' : 'text-green-500'}>{message}</p>}
      <div className="mb-4">
        <input
          type="text"
          name="name"
          placeholder="Club Name"
          value={clubData.name}
          onChange={handleChange}
          className="p-2 border border-gray-300 rounded mb-2 w-full"
        />
        <textarea
          name="description"
          placeholder="Club Description"
          value={clubData.description}
          onChange={handleChange}
          className="p-2 border border-gray-300 rounded mb-2 w-full"
          rows="4"
        />
        <input
          type="text"
          name="country"
          placeholder="Country"
          value={clubData.country}
          onChange={handleChange}
          className="p-2 border border-gray-300 rounded mb-2 w-full"
        />
        <input
          type="text"
          name="city"
          placeholder="City"
          value={clubData.city}
          onChange={handleChange}
          className="p-2 border border-gray-300 rounded mb-2 w-full"
        />
        <button
          onClick={handleCreateClub}
          className={`mt-2 bg-primary text-white py-2 px-4 rounded ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-dark transition-all'}`}
          disabled={loading}
        >
          {loading ? 'Creating...' : 'Create Club'}
        </button>
      </div>
      {/* Add a link to the list of existing clubs */}
      <div className="mt-4">
        <Link to="/clubs" className="text-blue-500 hover:underline">
          View Existing Clubs
        </Link>
      </div>
    </div>
  );
}

export default NewClub;

import React from 'react';
import { X } from 'lucide-react';
import WaiverForm from './WaiverForm';
import WaiverText from './WaiverText';

const WaiverModal = ({ isOpen, onClose, onSubmit, user }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/* Background overlay */}
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" onClick={onClose} />

        {/* Modal panel */}
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="relative bg-white rounded-lg max-w-4xl w-full mx-4 p-6">
              {/* Close button */}
              <button
                onClick={onClose}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
              >
                <X className="w-6 h-6" />
              </button>

              {/* Modal content */}
              <div className="mt-3 sm:mt-0 sm:text-left">
                <WaiverForm 
                  onSubmit={(formData) => {
                    onSubmit(formData);
                    onClose();
                  }} 
                  user={user}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaiverModal;

import React, { useState } from 'react';
import AccountCreationForm from './AccountCreationForm';
import StakeholderForm from './StakeholderForm';
import BankDetailsForm from './BankDetailsForm';
import axios from 'axios';

function MultiStepForm() {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        phone: '',
        legalBusinessName: '',
        businessType: '',
        contactName: '',
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        panNumber: '',
        gstNumber: '',
        stakeholderName: '',
        residentialStreet: '',
        residentialCity: '',
        residentialState: '',
        residentialPostalCode: '',
        residentialCountry: '',
        stakeholderPan: '',
        bankAccountNumber: '',
        ifscCode: '',
        beneficiaryName: '',
        termsAccepted: false
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const nextStep = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const prevStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/create-account', formData);
            console.log('Account created successfully', response.data);
        } catch (error) {
            console.error('Error creating account', error);
        }
    };

    switch (currentStep) {
        case 1:
            return (
                <AccountCreationForm
                    formData={formData}
                    handleChange={handleChange}
                    nextStep={nextStep}
                />
            );
        case 2:
            return (
                <StakeholderForm
                    formData={formData}
                    handleChange={handleChange}
                    nextStep={nextStep}
                    prevStep={prevStep}
                />
            );
        case 3:
            return (
                <BankDetailsForm
                    formData={formData}
                    handleChange={handleChange}
                    prevStep={prevStep}
                    handleSubmit={handleSubmit}
                />
            );
        default:
            return (
                <AccountCreationForm
                    formData={formData}
                    handleChange={handleChange}
                    nextStep={nextStep}
                />
            );
    }
}

export default MultiStepForm;

import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="min-h-screen bg-gray-100 p-8">
            <div className="bg-white max-w-4xl mx-auto p-6 rounded-lg shadow-lg">
                <h1 className="text-4xl font-bold text-center text-primary mb-4">Privacy Policy</h1>
                <p className="text-lg text-gray-700 mb-6">
                    At Ultra-Rides, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information.
                </p>
                <h2 className="text-2xl font-semibold text-primary mb-2">Information We Collect</h2>
                <p className="text-gray-600 mb-4">
                    We collect information that you provide to us directly, such as when you create an account, register for a ride, or contact us. This may include your name, email address, phone number, and payment information.
                </p>
                <h2 className="text-2xl font-semibold text-primary mb-2">How We Use Your Information</h2>
                <p className="text-gray-600 mb-4">
                    We use your information to provide and improve our services, process transactions, communicate with you, and for marketing purposes. We may also use your information to comply with legal obligations and protect our rights.
                </p>
                <h2 className="text-2xl font-semibold text-primary mb-2">Sharing Your Information</h2>
                <p className="text-gray-600 mb-4">
                    We do not sell your personal information to third parties. We may share your information with service providers who assist us in operating our services, conducting our business, or serving our users, as long as those parties agree to keep this information confidential.
                </p>
                <h2 className="text-2xl font-semibold text-primary mb-2">Security</h2>
                <p className="text-gray-600 mb-4">
                    We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no internet or email transmission is ever fully secure or error-free.
                </p>
                <h2 className="text-2xl font-semibold text-primary mb-2">Your Choices</h2>
                <p className="text-gray-600 mb-4">
                    You can update your account information and communication preferences at any time by logging into your account. You can also opt-out of receiving promotional emails from us by following the instructions in those emails.
                </p>
                <h2 className="text-2xl font-semibold text-primary mb-2">Contact Us</h2>
                <p className="text-gray-600 mb-4">
                    If you have any questions or concerns about our Privacy Policy, please contact us at privacy@ultra-rides.com.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;

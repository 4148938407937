import React, { useState } from 'react';
import { FaPencilAlt, FaPhone, FaUser, FaTint } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Toast from './Toast';

function ContactInformationForm({
  phoneNumber: initialPhoneNumber,
  emergencyContactName: initialEmergencyContactName,
  emergencyContactPhone: initialEmergencyContactPhone,
  bloodGroup: initialBloodGroup,
  handleSave,
  message,
  error
}) {
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [emergencyContactName, setEmergencyContactName] = useState(initialEmergencyContactName);
  const [emergencyContactPhone, setEmergencyContactPhone] = useState(initialEmergencyContactPhone);
  const [bloodGroup, setBloodGroup] = useState(initialBloodGroup);

  const [formError, setFormError] = useState('');

  const [editingField, setEditingField] = useState(null);

  const formatPhoneNumber = (number) => {
    if (number && !number.startsWith('+')) {
      return `+${number}`;
    }
    return number;
  };

  const validateForm = () => {
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    const formattedEmergencyContactPhone = formatPhoneNumber(emergencyContactPhone);

    const phoneNumberRegex = /^\+[1-9]\d{1,14}$/;
    if (!phoneNumberRegex.test(formattedPhoneNumber)) {
      setFormError('Phone number must be in international format (e.g., +123456789).');
      return false;
    }
    if (!phoneNumberRegex.test(formattedEmergencyContactPhone)) {
      setFormError('Emergency contact phone number must be in international format (e.g., +123456789).');
      return false;
    }
    if (emergencyContactName.length < 4 || emergencyContactName.length > 20) {
      setFormError('Emergency contact name must be between 4 and 20 characters.');
      return false;
    }
    if (bloodGroup.length < 0 || bloodGroup.length > 5) {
      setFormError('Blood group must be between 0 and 5 characters.');
      return false;
    }
    setFormError('');
    return true;
  };

  const onSave = () => {
    if (validateForm()) {
      const data = {
        phone_number: formatPhoneNumber(phoneNumber),
        'custom:ec_name': emergencyContactName,
        'custom:ec_phone_number': formatPhoneNumber(emergencyContactPhone),
        'custom:blood_group': bloodGroup
      };
      handleSave(data);
      setEditingField(null);
    }
  };

  return (
    <div className="bg-lightContainer p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 text-black border border-gray-300">
      <div className="text-sm text-gray-600 mb-4">
        Note: This information is not public.
      </div>
      <h2 className="text-lg font-semibold mb-4">Contact Details</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="flex items-center mb-4">
          <FaPhone className="mr-2 text-secondary" />
          <label className="text-sm font-medium">Phone Number</label>
        </div>
        <div className="flex items-center mb-4">
          {editingField === 'phoneNumber' ? (
            <PhoneInput
              country={'us'}
              value={phoneNumber}
              onChange={setPhoneNumber}
              containerStyle={{ flex: 1 }}
              inputStyle={{ width: '100%', color: 'black' }}  // Ensuring text color is black
            />
          ) : (
            <div className="flex items-center flex-1">
              <p className="flex-1">{formatPhoneNumber(phoneNumber) || 'Click to add phone number'}</p>
              <FaPencilAlt className="ml-2 text-secondary cursor-pointer" onClick={() => setEditingField('phoneNumber')} />
            </div>
          )}
        </div>

        <div className="flex items-center mb-4">
          <FaUser className="mr-2 text-secondary" />
          <label className="text-sm font-medium">Emergency Contact Name</label>
        </div>
        <div className="flex items-center mb-4">
          {editingField === 'emergencyContactName' ? (
            <input
              type="text"
              value={emergencyContactName}
              onChange={(e) => setEmergencyContactName(e.target.value || '')}
              className="text-gray-700 w-full p-2 border border-gray-300 rounded"
            />
          ) : (
            <div className="flex items-center flex-1">
              <p className="flex-1">{emergencyContactName || 'Click to add emergency contact name'}</p>
              <FaPencilAlt className="ml-2 text-secondary cursor-pointer" onClick={() => setEditingField('emergencyContactName')} />
            </div>
          )}
        </div>

        <div className="flex items-center mb-4">
          <FaPhone className="mr-2 text-secondary" />
          <label className="text-sm font-medium">Emergency Contact Phone Number</label>
        </div>
        <div className="flex items-center mb-4">
          {editingField === 'emergencyContactPhone' ? (
            <PhoneInput
              country={'us'}
              value={emergencyContactPhone}
              onChange={setEmergencyContactPhone}
              containerStyle={{ flex: 1 }}
              inputStyle={{ width: '100%', color: 'black' }}  // Ensuring text color is black
            />
          ) : (
            <div className="flex items-center flex-1">
              <p className="flex-1">{formatPhoneNumber(emergencyContactPhone) || 'Click to add emergency contact phone'}</p>
              <FaPencilAlt className="ml-2 text-secondary cursor-pointer" onClick={() => setEditingField('emergencyContactPhone')} />
            </div>
          )}
        </div>

        <div className="flex items-center mb-4">
          <FaTint className="mr-2 text-secondary" />
          <label className="text-sm font-medium">Blood Group</label>
        </div>
        <div className="flex items-center mb-4">
          {editingField === 'bloodGroup' ? (
            <select
              value={bloodGroup}
              onChange={(e) => setBloodGroup(e.target.value || '')}
              className="text-gray-700 w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select Blood Group</option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
            </select>
          ) : (
            <div className="flex items-center flex-1">
              <p className="flex-1">{bloodGroup || 'Click to add blood group'}</p>
              <FaPencilAlt className="ml-2 text-secondary cursor-pointer" onClick={() => setEditingField('bloodGroup')} />
            </div>
          )}
        </div>
      </div>
      {editingField && (
        <button
          onClick={onSave}
          className="bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-colors mt-4"
        >
          Save
        </button>
      )}

      {formError && <Toast message={formError} type="error" />}
      {message && <Toast message={message} type="success" />}
      {error && <Toast message={error} type="error" />}
    </div>
  );
}

export default ContactInformationForm;

import React, { useEffect, useState } from 'react';
import axios from '../config/axiosConfig';
import { useParams } from 'react-router-dom';
import Badges from './PublicProfile/Badges';
import UpcomingRides from './PublicProfile/UpcomingRides';
import RideHistory from './PublicProfile/RideHistory';
import TotalUltraRidesDistance from './PublicProfile/TotalUltraRidesDistance';
import LevelProgress from './LevelProgress'; // Import the new component
import { FaMedal } from 'react-icons/fa';

const PublicProfile = () => {
  const { userId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`/users/${userId}/profile`);
        setProfileData(response.data);
        document.title = response.data.name ? `${response.data.name}'s Profile` : 'Profile';
      } catch (error) {
        setError('Error fetching profile data');
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [userId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const currentDateTime = new Date();

  const upcomingRides = profileData?.rideHistory.filter(
    (ride) => new Date(ride.rideStartDateTime) > currentDateTime
  );

  const pastRides = profileData?.rideHistory.filter(
    (ride) => new Date(ride.rideStartDateTime) <= currentDateTime
  );

  const renderBadges = (ride) => {
    if (ride.rideDistance === 100 && ride.Result === 'Finished') {
      return (
        <div className="ml-4 flex items-center">
          <FaMedal className="text-yellow-500 text-2xl" />
          <span className="ml-2 text-sm text-gray-700">100k</span>
        </div>
      );
    }
    if (ride.rideDistance === 150 && ride.Result === 'Finished') {
      return (
        <div className="ml-4 flex items-center">
          <FaMedal className="text-yellow-500 text-2xl" />
          <span className="ml-2 text-sm text-gray-700">150k</span>
        </div>
      );
    }
    if (ride.rideDistance === 200 && ride.Result === 'Finished') {
      return (
        <div className="ml-4 flex items-center">
          <FaMedal className="text-yellow-500 text-2xl" />
          <span className="ml-2 text-sm text-gray-700">200k</span>
        </div>
      );
    }
    if (ride.rideDistance === 250 && ride.Result === 'Finished') {
      return (
        <div className="ml-4 flex items-center">
          <FaMedal className="text-yellow-500 text-2xl" />
          <span className="ml-2 text-sm text-gray-700">250k</span>
        </div>
      );
    }
    if (ride.rideDistance === 300 && ride.Result === 'Finished') {
      return (
        <div className="ml-4 flex items-center">
          <FaMedal className="text-yellow-500 text-2xl" />
          <span className="ml-2 text-sm text-gray-700">300k</span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="min-h-screen bg-background flex justify-center items-center p-4">
      <div className="max-w-4xl w-full p-4 sm:p-6 md:p-8 bg-white rounded-lg shadow-md">
        <div className="bg-white p-4 rounded-lg mb-6 flex flex-col items-center md:flex-row md:items-center md:justify-center">
          <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-6 text-center">
            {profileData?.ProfilePictureURL ? (
              <img
                src={profileData.ProfilePictureURL}
                alt={`${profileData?.name || 'User'}'s profile`}
                className="w-36 h-36 sm:w-44 sm:h-44 md:w-56 md:h-56 rounded-full border-4 border-primary object-cover mb-2"
              />
            ) : (
              <img
                src="/default-profile.png"
                className="w-36 h-36 sm:w-44 sm:h-44 md:w-56 md:h-56 rounded-full border-4 border-primary object-cover mb-2"
              />
            )}
            <div className="px-4 py-2 rounded-full font-bold text-xl border-2 border-primary text-primary">
              URN: {profileData?.URN || 'Not available'}
            </div>
          </div>
          <div className="flex flex-col items-center md:items-center md:flex-grow">
            <h1 className="text-3xl sm:text-5xl font-bold text-primary mb-2 sm:mb-4 w-full text-center">{profileData?.name || 'Name not available'}</h1>
            <div className="w-full text-center mt-4">
              <p className="text-textPrimary text-lg sm:text-xl italic">
                <span className="text-3xl text-accent">“</span>
                {profileData?.bio || 'Bio not available'}
                <span className="text-3xl text-accent">”</span>
              </p>
            </div>
          </div>
        </div>
        <hr className="border-t border-gray-300 w-full my-4" />
        <div className="flex flex-col md:flex-row md:items-start md:space-x-6">
          <div className="flex-shrink-0 w-full md:w-auto mb-4 md:mb-0">
            <TotalUltraRidesDistance rideHistory={profileData?.rideHistory} />
          </div>
          <div className="w-full mb-8">
            <Badges rideHistory={profileData?.rideHistory} />
          </div>
        </div>
        <div className="w-full my-4">
          <LevelProgress totalDistance={profileData?.rideHistory.reduce((total, ride) => ride.Result === 'Finished' ? total + ride.rideDistance : total, 0)} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mt-6">
          {upcomingRides && upcomingRides.length > 0 && (
            <UpcomingRides upcomingRides={upcomingRides} />
          )}
          {pastRides && pastRides.length > 0 && (
            <RideHistory pastRides={pastRides} renderBadges={renderBadges} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicProfile;

import React, { useState, useEffect } from 'react';
import { Legend } from './';
import { CategoryFilter } from './';
import { PaginationButtons } from './';
import { RidersTable } from './';

function RegisteredRidersTab({ registeredRiders, payment, categories, defaultCategoryId, timeControlType }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [sortField, setSortField] = useState('rank');
  const [sortOrder, setSortOrder] = useState('asc');
  const ridersPerPage = 25;

  // Filter riders by category
  const filteredRiders = selectedCategory
    ? registeredRiders.filter(rider => rider.categoryId === selectedCategory)
    : registeredRiders;

  // Sort riders by selected field
  const sortedRiders = [...filteredRiders].sort((a, b) => {
    if (sortField === 'name') {
      return sortOrder === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (sortField === 'rank') {
      const rankA = a.rank || Number.MAX_SAFE_INTEGER;
      const rankB = b.rank || Number.MAX_SAFE_INTEGER;
      return sortOrder === 'asc'
        ? rankA - rankB
        : rankB - rankA;
    }
    return 0;
  });

  // Get current riders for pagination
  const indexOfLastRider = currentPage * ridersPerPage;
  const indexOfFirstRider = indexOfLastRider - ridersPerPage;
  const currentRiders = sortedRiders.slice(indexOfFirstRider, indexOfLastRider);
  const totalPages = Math.ceil(sortedRiders.length / ridersPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getCategoryCount = (categoryId) => {
    return registeredRiders.filter(rider => rider.categoryId === categoryId).length;
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory]);

  return (
    <div className="space-y-6">
      {categories?.length > 0 && (
        <CategoryFilter
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          getCategoryCount={getCategoryCount}
          registeredRiders={registeredRiders}
        />
      )}
      
      {totalPages > 1 && (
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          position="top"
        />
      )}
      
      <RidersTable
        currentRiders={currentRiders}
        currentPage={currentPage}
        ridersPerPage={ridersPerPage}
        payment={payment}
        timeControlType={timeControlType}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={handleSort}
      />
      
      <Legend />
      
      {totalPages > 1 && (
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          position="bottom"
        />
      )}
    </div>
  );
}

export default RegisteredRidersTab;

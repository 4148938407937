import React, { useState } from 'react';

function SeriesParticipation() {
  const [seriesData, setSeriesData] = useState({
    riderID: '',
    year: new Date().getFullYear(),
    rideIDs: []
  });

  const handleChange = (e) => {
    setSeriesData({ ...seriesData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle series participation tracking logic here
    console.log('Series participation data:', seriesData);
  };

  return (
    <div>
      <h1>Track Series Participation</h1>
      <form onSubmit={handleSubmit} style={{ color: '#FF6600' }}>
        <input type='text' name='riderID' placeholder='Rider ID' value={seriesData.riderID} onChange={handleChange} required />
        <input type='number' name='year' placeholder='Year' value={seriesData.year} onChange={handleChange} required />
        <input type='text' name='rideIDs' placeholder='Ride IDs (comma-separated)' value={seriesData.rideIDs} onChange={handleChange} required />
        <button type='submit' style={{ backgroundColor: '#FF6600', color: '#FFF' }}>Track Series</button>
      </form>
    </div>
  );
}

export default SeriesParticipation;

import {
  CognitoIdentityProviderClient,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand,
  InitiateAuthCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { getLocalStorage, setLocalStorage } from '../lib/storage';

const client = new CognitoIdentityProviderClient({ region: "ap-south-1" });

const poolData = {
  UserPoolId: 'ap-south-1_LcERpQC3j', // Your User Pool ID
  ClientId: '5a3l5unl4a692qhv6v7q124730', // Your Client ID
};

export const forgotPassword = async (email) => {
  try {
    const command = new ForgotPasswordCommand({
      ClientId: poolData.ClientId,
      Username: email,
    });
    await client.send(command);
    return { success: true, message: "Code sent to your email" };
  } catch (error) {
    console.error('ForgotPassword Error:', error);
    return { success: false, message: error.message };
  }
};

export const confirmForgotPassword = async (email, code, newPassword) => {
  try {
    const command = new ConfirmForgotPasswordCommand({
      ClientId: poolData.ClientId,
      Username: email,
      ConfirmationCode: code,
      Password: newPassword,
    });
    await client.send(command);
    return { success: true, message: "Password reset successful" };
  } catch (error) {
    console.error('ConfirmForgotPassword Error:', error);
    return { success: false, message: error.message };
  }
};

function base64UrlToBase64(base64Url) {
  return base64Url.replace(/-/g, '+').replace(/_/g, '/');
}

export const refreshToken = async (forceRefresh = false) => {
  try {
    const storedRefreshToken = getLocalStorage('refreshToken');
    const storedAccessTokenPayload = JSON.parse(getLocalStorage('accessTokenPayload'));
    const currentTime = Math.floor(Date.now() / 1000);

    console.log('Stored Refresh Token:', storedRefreshToken);
    console.log('Stored Access Token Payload:', storedAccessTokenPayload);
    console.log('Current Time:', currentTime);

    if (!forceRefresh && storedAccessTokenPayload && storedAccessTokenPayload.exp > currentTime) {
      const storedAccessToken = getLocalStorage('accessToken');
      if (storedAccessToken) {
        console.log('Returning cached access token.');
        return storedAccessToken;
      }
    }

    if (!storedRefreshToken) {
      throw new Error('No refresh token available');
    }

    console.log('Sending InitiateAuthCommand with refresh token...');

    const command = new InitiateAuthCommand({
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      ClientId: poolData.ClientId,
      AuthParameters: {
        REFRESH_TOKEN: storedRefreshToken,
      },
    });

    const response = await client.send(command);
    console.log('InitiateAuthCommand Response:', response);

    const newAccessToken = response.AuthenticationResult.AccessToken;
    const newIdToken = response.AuthenticationResult.IdToken;

    console.log('New Access Token:', newAccessToken);
    console.log('New ID Token:', newIdToken);

    // Store new access token and payload
    setLocalStorage('accessToken', newAccessToken);
    const newAccessTokenPayload = JSON.parse(atob(base64UrlToBase64(newAccessToken.split('.')[1])));
    setLocalStorage('accessTokenPayload', JSON.stringify(newAccessTokenPayload));

    console.log('New Access Token Payload:', newAccessTokenPayload);

    // Store new ID token and payload
    if (newIdToken) {
      setLocalStorage('idToken', newIdToken);
      const newIdTokenPayload = JSON.parse(atob(base64UrlToBase64(newIdToken.split('.')[1])));
      setLocalStorage('idTokenPayload', JSON.stringify(newIdTokenPayload));

      console.log('New ID Token Payload:', newIdTokenPayload);
    }

    return newAccessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);

    if (error.name === 'NotAuthorizedException' && error.message === 'Refresh Token has expired') {
      console.error('The refresh token has expired. Redirecting to login...');
      // Clear stored tokens and payloads
      setLocalStorage('accessToken', '');
      setLocalStorage('refreshToken', '');
      setLocalStorage('accessTokenPayload', '');
      setLocalStorage('idToken', '');
      setLocalStorage('idTokenPayload', '');

      // Redirect to login page or notify the user
      // window.location.href = '/login';
      alert('Session expired. Please log in again.');
      throw new Error('Session expired. Please log in again.');
    }

    throw new Error('Error refreshing token');
  }
};


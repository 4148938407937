import { useState, useEffect } from 'react';
import { getTestData } from '../utils/testData';

export const useDevTools = (setFormData, setStep, setErrors) => {
    const [showDevTools, setShowDevTools] = useState(false);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key.toLowerCase() === 'd') {
                e.preventDefault();
                setShowDevTools(prev => !prev);
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, []);

    const loadTestData = (rideType) => {
        const testData = getTestData(rideType);
        setFormData(testData);
        setStep(1);
        setErrors({});
    };

    return {
        showDevTools,
        loadTestData
    };
};

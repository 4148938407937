// src/components/Faq.js
import React from 'react';

const Faq = () => {
    return (
        <div className="min-h-screen bg-gray-100 p-8">
            <div className="bg-white max-w-4xl mx-auto p-6 rounded-lg shadow-lg">
                <h1 className="text-4xl font-bold text-center text-primary mb-4">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    <div className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
                        <h2 className="text-xl font-semibold text-primary mb-2">What is Ultra-Rides?</h2>
                        <p className="text-gray-600">Ultra-Rides is a community of cycling enthusiasts who come together to embark on epic long-distance rides. Our mission is to promote a healthy lifestyle through cycling, build a strong community of riders, and explore the beauty of the world on two wheels.</p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
                        <h2 className="text-xl font-semibold text-primary mb-2">How can I join a ride?</h2>
                        <p className="text-gray-600">You can join a ride by registering on our website and signing up for upcoming rides listed on the Rides page. Make sure to check the details and requirements for each ride before signing up.</p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
                        <h2 className="text-xl font-semibold text-primary mb-2">Do I need to be an experienced cyclist to join?</h2>
                        <p className="text-gray-600">Not at all! Ultra-Rides welcomes cyclists of all levels. We offer rides of varying difficulty levels, so whether you're a beginner or a seasoned cyclist, you'll find something that suits your skill level.</p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
                        <h2 className="text-xl font-semibold text-primary mb-2">What should I bring on a ride?</h2>
                        <p className="text-gray-600">We recommend bringing a well-maintained bike, a helmet, water, snacks, and any necessary repair tools. It's also a good idea to wear comfortable clothing suitable for cycling and to check the weather forecast before the ride.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;
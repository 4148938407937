import React from 'react';
import { timezones } from '../utils/timezoneData';

const PreviewStep = ({ formData }) => {
    const formatTime = (closeTime, isStart = false) => {
        if (!closeTime) return '--';
        try {
            const date = new Date(closeTime);
            return `${date.toLocaleTimeString('en-US', { 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: true 
            })} (${date.toLocaleDateString('en-US', { 
                weekday: 'short',
                month: 'short', 
                day: 'numeric' 
            })})`;
        } catch (e) {
            return closeTime;
        }
    };

    const getTimezoneLabel = (value) => {
        const timezone = timezones.find(tz => tz.value === value);
        return timezone ? timezone.label : value;
    };

    const formatDateWithDay = (dateString) => {
        if (!dateString) return '--';
        try {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
        } catch (e) {
            return dateString;
        }
    };

    const formatDateTime = (date, time) => {
        if (!date || !time) return '--';
        try {
            const datetime = new Date(`${date}T${time}`);
            return `${datetime.toLocaleTimeString('en-US', { 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: true 
            })}`;
        } catch (e) {
            return `${time}`;
        }
    };

    return (
        <div className="space-y-6">
            <h3 className="font-medium text-lg">Ride Summary</h3>

            <div className="space-y-4">
                <div className="bg-gray-50 p-4 rounded-md">
                    <h4 className="font-medium mb-2">Basic Details</h4>
                    <div className="grid grid-cols-1 gap-2 text-sm">
                        <div>
                            <span className="text-gray-600">Type:</span>
                            <span className="ml-2 capitalize">{formData.rideType}</span>
                        </div>
                        <div>
                            <span className="text-gray-600">Name:</span>
                            <span className="ml-2">{formData.name}</span>
                        </div>
                        <div>
                            <span className="text-gray-600">Date:</span>
                            <span className="ml-2">{formatDateWithDay(formData.date)}</span>
                        </div>
                        <div>
                            <span className="text-gray-600">Start Time:</span>
                            <span className="ml-2">{formatDateTime(formData.date, formData.time)}</span>
                        </div>
                        <div>
                            <span className="text-gray-600">Timezone:</span>
                            <span className="ml-2">{getTimezoneLabel(formData.timezone)}</span>
                        </div>
                        <div>
                            <span className="text-gray-600">Registration Closes:</span>
                            <span className="ml-2">
                                {formatDateWithDay(formData.registrationClosingDate)} at {formatDateTime(formData.registrationClosingDate, formData.registrationClosingTime)}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-50 p-4 rounded-md">
                    <h4 className="font-medium mb-2">Responsible Person</h4>
                    <div className="grid grid-cols-1 gap-2 text-sm">
                        <div>
                            <span className="text-gray-600">Name:</span>
                            <span className="ml-2">{formData.responsibleName}</span>
                        </div>
                        <div>
                            <span className="text-gray-600">Email:</span>
                            <span className="ml-2">{formData.responsibleEmail}</span>
                        </div>
                        <div>
                            <span className="text-gray-600">Phone:</span>
                            <span className="ml-2">{formData.responsiblePhone}</span>
                        </div>
                    </div>
                </div>

                {formData.controlPoints?.length > 0 && (
                    <div className="bg-gray-50 p-4 rounded-md">
                        <h4 className="font-medium mb-2">Control Points</h4>
                        <div className="space-y-4">
                            {formData.controlPoints.map((point, index) => (
                                <div key={index} className="border-t pt-2 first:border-t-0 first:pt-0">
                                    <div className="grid grid-cols-1 gap-2 text-sm">
                                        <div>
                                            <span className="text-gray-600">Distance:</span>
                                            <span className="ml-2">{point.distance} km</span>
                                        </div>
                                        <div>
                                            <span className="text-gray-600">{index === 0 ? 'Open Time' : 'Close Time'}:</span>
                                            <span className="ml-2">{formatTime(point.closeTime)}</span>
                                        </div>
                                        <div>
                                            <span className="text-gray-600">GPS Coordinates:</span>
                                            <span className="ml-2">{point.gpsCoordinates}</span>
                                        </div>
                                        <div>
                                            <span className="text-gray-600">Description:</span>
                                            <span className="ml-2">{point.description}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {formData.rulesAndRegulations && (
                    <div className="bg-gray-50 p-4 rounded-md">
                        <h4 className="font-medium mb-2">Rules and Regulations</h4>
                        <div className="text-sm whitespace-pre-line">
                            {formData.rulesAndRegulations}
                        </div>
                    </div>
                )}

                {formData.routeDetails && (
                    <div className="bg-gray-50 p-4 rounded-md">
                        <h4 className="font-medium mb-2">Route Details</h4>
                        <div className="text-sm whitespace-pre-line">
                            {formData.routeDetails}
                        </div>
                    </div>
                )}

                {formData.whatsappGroupUrl && (
                    <div className="bg-gray-50 p-4 rounded-md">
                        <h4 className="font-medium mb-2">WhatsApp Group</h4>
                        <div className="text-sm break-all">
                            <a href={formData.whatsappGroupUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                                {formData.whatsappGroupUrl}
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PreviewStep;

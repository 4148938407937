import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

const HeroSection = () => {
  return (
    <div
      className="relative flex flex-col items-center justify-center text-center text-white h-[70vh] md:h-[50vh] w-screen"
      style={{
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <h1 className="text-5xl md:text-6xl font-bold mb-4">
        Welcome to UltraRides
      </h1>
      <p className="text-lg md:text-xl mb-8">
        Where Every Ride Counts
      </p>
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <Link
          to="/create-account"
          className="inline-block bg-primary text-white py-3 px-8 rounded-full font-semibold text-xl hover:bg-primary-dark transition-all flex items-center justify-center"
        >
          Join as a Club Owner
          <FaArrowRight className="ml-2" />
        </Link>
        <Link
          to="/create-account"
          className="inline-block bg-primary text-white py-3 px-8 rounded-full font-semibold text-xl hover:bg-primary-dark transition-all flex items-center justify-center"
        >
          Join as a Rider
          <FaArrowRight className="ml-2" />
        </Link>
      </div>
    </div>
  );
};

export default HeroSection;

export const timezones = [
    {
        value: "Asia/Kolkata",
        label: "(GMT+5:30) India Standard Time"
    },
    {
        value: "Asia/Dubai",
        label: "(GMT+4:00) Dubai"
    },
    {
        value: "Asia/Singapore",
        label: "(GMT+8:00) Singapore"
    },
    {
        value: "America/New_York",
        label: "(GMT-4:00) New York"
    },
    {
        value: "Europe/London",
        label: "(GMT+1:00) London"
    },
    {
        value: "Australia/Sydney",
        label: "(GMT+10:00) Sydney"
    }
];

import React, { useState, useRef, useEffect } from 'react';
import axiosInstance from '../../config/axiosConfig'; // Import the configured axios instance
import Webcam from 'react-webcam';

function CheckInButton({ rideId, control, index, checkedInStations, setCheckedInStations, isAvailable, setError }) {
    const [location, setLocation] = useState({ lat: null, lng: null });
    const [image, setImage] = useState(null);
    const [showCamera, setShowCamera] = useState(false);
    const [cameraError, setCameraError] = useState('');
    const [isCheckingIn, setIsCheckingIn] = useState(false);
    const [distanceWarning, setDistanceWarning] = useState('');
    const [distanceFromPoint, setDistanceFromPoint] = useState(null);
    const [showLocationError, setShowLocationError] = useState(false);
    const webcamRef = useRef(null);

    const haversineDistance = (lat1, lon1, lat2, lon2) => {
        const toRad = x => (x * Math.PI) / 180;
        const R = 6371e3; // Earth's radius in meters
        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon1 - lon2);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    const formatDistance = (distance) => {
        if (distance < 1000) {
            return `${Math.round(distance / 10) * 10} meters`;
        } else if (distance < 9000) {
            return `${(distance / 1000).toFixed(1)} kilometers`;
        } else {
            return `${Math.round(distance / 1000)} kilometers`;
        }
    };

    const handleLocationSuccess = (position) => {
        const userLat = position.coords.latitude;
        const userLng = position.coords.longitude;
        const controlLat = parseFloat(control.latitude);
        const controlLng = parseFloat(control.longitude);

        const distance = haversineDistance(userLat, userLng, controlLat, controlLng);

        setDistanceFromPoint(formatDistance(distance)); // Format the distance

        if (distance > 100) {
            setDistanceWarning('Distance is high, but you can continue.');
        } else {
            setDistanceWarning('');
        }

        setLocation({
            lat: userLat,
            lng: userLng,
        });
        setShowCamera(true); // Show the camera interface
        setCameraError(''); // Reset camera error
    };

    const handleLocationError = (error) => {
        setError('Error getting location');
        setShowLocationError(true);
        console.error('Geolocation error:', error);
        setIsCheckingIn(false); // Reset checking in state
    };

    const captureImage = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImage(imageSrc);
        setShowCamera(false);
        sendCheckInData(imageSrc);
    };

    const sendCheckInData = async (imageData) => {
        const controlNumber = index + 1;
        const payload = {
            rideId,
            controlName: control.description,
            controlNumber,
            latitude: location.lat,
            longitude: location.lng,
            image: imageData,
        };

        console.log('Payload size:', JSON.stringify(payload).length, 'bytes');

        try {
            const response = await axiosInstance.post(`/rides/${rideId}/check-in`, payload);
            console.log('Response status:', response.status);
            if (response.status === 200) {
                const newCheckedInControl = { controlPointNumber: controlNumber, imageUrl: response.data.imageUrl };
                setCheckedInStations((prev) => [...prev, newCheckedInControl]);
                setImage(response.data.imageUrl); // Keep the image visible
                setIsCheckingIn(false); // Reset checking in state after success
            } else {
                console.error('Failed to send data:', response);
                setIsCheckingIn(false); // Reset checking in state after failure
            }
        } catch (error) {
            console.error('Error sending check-in data:', error);
            setIsCheckingIn(false); // Reset checking in state after error
        }
    };

    const handleCheckIn = () => {
        if (!checkedInStations.some(station => station.controlPointNumber === index + 1) && !isCheckingIn) {
            setIsCheckingIn(true);
            navigator.geolocation.getCurrentPosition(
                handleLocationSuccess,
                handleLocationError
            );
        }
    };

    return (
        <div>
            <button
                onClick={handleCheckIn}
                className={`mt-4 px-4 py-2 rounded ${!isAvailable || checkedInStations.some(station => station.controlPointNumber === index + 1) || isCheckingIn ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'} text-white`}
                disabled={!isAvailable || checkedInStations.some(station => station.controlPointNumber === index + 1) || isCheckingIn}
            >
                {!isAvailable ? 'Check-In' : checkedInStations.some(station => station.controlPointNumber === index + 1) ? 'Checked In' : isCheckingIn ? 'Checking In...' : 'Check In'}
            </button>
            {showCamera && (
                <div className="camera-interface mt-4">
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width="640"
                        height="480"
                    />
                    {distanceFromPoint !== null && (
                        <p className="text-textSecondary mt-2">Distance from check-in point: {distanceFromPoint}</p>
                    )}
                    <button onClick={captureImage} className="mt-4 px-4 py-2 rounded bg-blue-500 hover:bg-blue-600 text-white">Capture and Submit Photo</button>
                    {cameraError && <p className="text-error mt-2">{cameraError}</p>}
                    {distanceWarning && <p className="text-red-700 bg-yellow-200 mt-2 p-2 rounded">{distanceWarning}</p>}
                </div>
            )}
            {image && (
                <div className="image-preview mt-4">
                    <img src={image} alt="Captured" className="rounded" />
                </div>
            )}
            {showLocationError && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-gray-100 p-6 rounded-lg shadow-lg max-w-md w-full">
                        <h2 className="text-xl font-semibold mb-4">Location Error</h2>
                        <p className="text-error mb-4">Error getting location. Please ensure location permissions are enabled in your browser settings.</p>
                        <button
                            onClick={() => setShowLocationError(false)}
                            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CheckInButton;
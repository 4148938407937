import React from 'react';
import { FaTrash } from 'react-icons/fa';
import MapPicker from './MapPicker';

function ControlPointCard({
  controlPoint,
  index,
  handleInputChange,
  handleRemoveControlPoint,
  totalPoints,
  totalDistance,
  errors = {},
  isStartOrEnd,
  unsavedFields = {},
}) {
  const formatDateTime = (isoString) => {
    if (!isoString) return '';
    return new Date(isoString).toLocaleString();
  };

  const highlightClass = 'border-yellow-500';

  // Helper function to check if a value is not null, undefined, or empty string
  const isValueAvailable = (value) => {
    return value !== null && value !== undefined && value !== '';
  };

  // Helper function to get distance value
  const getDistanceValue = () => {
    if (index === 0) {
      return isValueAvailable(controlPoint.distance)
        ? controlPoint.distance
        : 0;
    } else if (index === totalPoints - 1) {
      return totalDistance;
    } else {
      return isValueAvailable(controlPoint.distance)
        ? controlPoint.distance
        : '';
    }
  };

  // Helper function to handle GPS coordinate changes from MapPicker
  const handleGPSChange = (coordString) => {
    if (!coordString) return;

    // Remove any spaces around the comma and trim whitespace
    const cleanedCoordString = coordString.split(',')
      .map(coord => coord.trim())
      .join(',');

    const [lat, lng] = cleanedCoordString.split(',').map(coord => parseFloat(coord));

    if (!isNaN(lat) && !isNaN(lng)) {
      // Update both coordinates simultaneously
      handleInputChange(index, 'coordinates', {
        latitude: lat,
        longitude: lng
      });
    }
  };

  // Format current coordinates for MapPicker
  const getCurrentCoordinates = () => {
    if (isValueAvailable(controlPoint.latitude) && isValueAvailable(controlPoint.longitude)) {
      // Format to exactly 5 decimal places
      const lat = Number(controlPoint.latitude).toFixed(5);
      const lng = Number(controlPoint.longitude).toFixed(5);
      return `${lat}, ${lng}`;
    }
    return '';
  };

  return (
    <div className="bg-white p-3 rounded-md shadow-sm mb-3 border border-gray-200">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-base font-semibold text-gray-800">
          {index === 0
            ? 'Start Point'
            : index === totalPoints - 1
              ? 'End Point'
              : `Control Point ${index}`}
        </h2>
        {!isStartOrEnd && (
          <button
            onClick={() => handleRemoveControlPoint(index)}
            className="text-red-500 hover:text-red-700 transition-colors"
            title="Remove Control Point"
          >
            <FaTrash size={16} />
          </button>
        )}
      </div>

      <div className="flex flex-wrap gap-2">
        <div className="flex-1 min-w-[150px]">
          <label className="block text-xs font-medium text-gray-700">
            Description
          </label>
          <input
            type="text"
            value={controlPoint.description || ''}
            onChange={(e) =>
              handleInputChange(index, 'description', e.target.value)
            }
            className={`w-full p-1 border rounded-md text-xs ${errors.description
                ? 'border-red-500'
                : unsavedFields.description
                  ? highlightClass
                  : 'border-gray-300'
              }`}
            placeholder="Description"
          />
          {errors.description && (
            <p className="text-red-500 text-xs mt-1">{errors.description}</p>
          )}
        </div>

        <div className="w-24">
          <label className="block text-xs font-medium text-gray-700">
            Distance (km)
          </label>
          <input
            type="number"
            value={getDistanceValue()}
            onChange={(e) =>
              handleInputChange(index, 'distance', parseFloat(e.target.value))
            }
            className={`w-full p-1 border rounded-md text-xs ${errors.distance
                ? 'border-red-500'
                : unsavedFields.distance
                  ? highlightClass
                  : 'border-gray-300'
              }`}
            placeholder="Distance"
            disabled={isStartOrEnd}
            step="0.1"
          />
          {errors.distance && (
            <p className="text-red-500 text-xs mt-1">{errors.distance}</p>
          )}
        </div>

        <div className="flex-1 min-w-[200px]">
          <label className="block text-xs font-medium text-gray-700">
            GPS Location
          </label>
          <MapPicker
            value={getCurrentCoordinates()}
            onChange={handleGPSChange}
            className={`${(errors.latitude || errors.longitude)
                ? 'border-red-500'
                : (unsavedFields.latitude || unsavedFields.longitude)
                  ? highlightClass
                  : ''
              }`}
          />
          {(errors.latitude || errors.longitude) && (
            <p className="text-red-500 text-xs mt-1">
              {errors.latitude || errors.longitude}
            </p>
          )}
        </div>

        <div className="w-40">
          <label className="block text-xs font-medium text-gray-700">
            Close Time
          </label>
          <input
            type="text"
            value={formatDateTime(controlPoint.closeTime)}
            className={`w-full p-1 border rounded-md bg-gray-50 text-xs ${unsavedFields.closeTime ? highlightClass : 'border-gray-300'
              }`}
            readOnly
          />
        </div>
      </div>
    </div>
  );
}

export default ControlPointCard;
import React from 'react';

const RulesAndRegulations = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="bg-white max-w-4xl mx-auto p-6 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold text-center text-primary mb-4">Rules & Regulations</h1>
        <p className="text-lg text-gray-700 mb-6">
          Welcome to Ultra-Rides. The following rules and regulations are designed to ensure the safety and enjoyment of all participants.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 1: Eligibility</h2>
        <p className="text-gray-600 mb-4">
          Our rides are open to any amateur cyclist regardless of their cycling affiliations. Any form of human-powered vehicle is acceptable; the vehicle must be powered solely by the rider.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 2: Registration</h2>
        <p className="text-gray-600 mb-4">
          To participate in a ride, a rider must fill out the registration form, sign a waiver of liability, and pay the registration fee set by the event organiser.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 3: Insurance</h2>
        <p className="text-gray-600 mb-4">
          Each rider must be covered by liability insurance, either by a group policy or by a personal policy.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 4: Responsible Behaviour</h2>
        <p className="text-gray-600 mb-4">
          During the event, each rider is considered to be on a personal ride. All riders are required to conduct themselves in a civil manner and abide by all applicable traffic laws and regulations.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 5: Safety</h2>
        <p className="text-gray-600 mb-4">
          It is compulsory to wear a helmet for the entire duration of the ride. Riders without a helmet will not be permitted to start. The use of aero-bars is not permitted during a ride.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 6: Night Riding</h2>
        <p className="text-gray-600 mb-4">
          For night riding, bicycles must be equipped with front and rear lights attached firmly to the vehicle. Lights must be fully functional at all times. Riders must wear a reflective vest that places reflective material prominently on the front and back of the rider.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 7: Self-sufficiency</h2>
        <p className="text-gray-600 mb-4">
          Each rider must be self-sufficient. No support cars of any kind are permitted during the ride. Personal support is only allowed at designated checkpoints.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 8: Ride Card</h2>
        <p className="text-gray-600 mb-4">
          At the start of the ride, each rider will receive a ride card. Riders must stop at each checkpoint to have their ride card stamped.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 9: Time Limits</h2>
        <p className="text-gray-600 mb-4">
          Overall time limits vary for each ride according to the distance. Riders must reach the finish inside the respective time limit. Intermediate control times are advisory to help keep the rider inside the final time limit.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 10: Cheating</h2>
        <p className="text-gray-600 mb-4">
          Any instance of fraud, cheating, or deliberate violation of the ride rules will result in the exclusion of the rider from all events.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 11: Ride Completion Formalities</h2>
        <p className="text-gray-600 mb-4">
          After completing the ride successfully, the rider must sign their ride card and ensure it reaches the event organiser within 3 days of the event finish.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 12: Commemorative Medals</h2>
        <p className="text-gray-600 mb-4">
          Our rides are not competitive events, so no rider classifications or rankings are given. Commemorative medals are available for purchase.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 13: Super Rider</h2>
        <p className="text-gray-600 mb-4">
          The Super Rider title is earned by any rider who completes a series of rides (200 km, 300 km, 400 km, and 600 km) within the same calendar year.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 14</h2>
        <p className="text-gray-600 mb-4">
          A ride must be done alone and may not be counted as participation in, or as credit for, another event held in conjunction with it.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 15</h2>
        <p className="text-gray-600 mb-4">
          All criteria regarding the rides in a geographical area, such as classifications, awards, challenges, etc., are exclusively at the discretion of the Club Representative.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 16: Rider Details and Photographs</h2>
        <p className="text-gray-600 mb-4">
          When registering for a ride, the rider accepts and consents to the possible publication of their name, time result, and photograph by the organiser.
        </p>
        <h2 className="text-2xl font-semibold text-primary mb-2">Article 17: Complaints / Queries</h2>
        <p className="text-gray-600 mb-4">
          Any complaint or question concerning a ride or how it is organised must be submitted to the organiser in writing within 48 hours from the finish of the ride. The organiser will be the final arbiter of any complaints or questions.
        </p>
      </div>
    </div>
  );
};

export default RulesAndRegulations;

import React from 'react';

function RaceControlCenter() {
    return (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
            <h1 className="text-3xl font-bold text-primary mb-6">Race Control Center</h1>
            <div className="grid gap-6 mb-6">
                <div className="bg-warning/20 border border-warning rounded-lg p-4">
                    <p className="text-textSecondary">
                        Race control features coming soon:
                    </p>
                    <ul className="list-disc ml-6 mt-2 text-textSecondary">
                        <li>Emergency communications</li>
                        <li>Weather monitoring</li>
                        <li>Course condition updates</li>
                        <li>Official announcements</li>
                    </ul>
                </div>

                <div className="bg-background rounded-lg p-4 border border-secondary">
                    <h2 className="text-xl font-semibold text-primary mb-4">Race Status Dashboard</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div className="bg-white p-4 rounded-lg border border-secondary">
                            <h3 className="font-medium text-textSecondary">Participants</h3>
                            <p className="text-2xl font-bold text-primary">--</p>
                        </div>
                        <div className="bg-white p-4 rounded-lg border border-secondary">
                            <h3 className="font-medium text-textSecondary">Active</h3>
                            <p className="text-2xl font-bold text-primary">--</p>
                        </div>
                        <div className="bg-white p-4 rounded-lg border border-secondary">
                            <h3 className="font-medium text-textSecondary">Finished</h3>
                            <p className="text-2xl font-bold text-primary">--</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RaceControlCenter;

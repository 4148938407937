import React, { useState } from 'react';
import { FaCalendarAlt, FaClock, FaRegCalendarCheck, FaRoute } from 'react-icons/fa';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import axios from '../../config/axiosConfig';
import RegistrationSteps from './Registration/RegistrationSteps';
import CategoryPodium from './CategoryPodium';
import CategorySelector from './CategorySelector';
import { loadRazorpay, initRazorpay } from '../../utils/razorpayUtils';
import LocationHeader from './LocationHeader';

function RideHeader({
  ride,
  state,
  isUserRegistered,
  rideId,
  queryClient,
  navigate,
  onWaiverClick
}) {
  const [isRegistering, setIsRegistering] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const currentDateTime = new Date();
  const rideStartDateTime = new Date(ride.startDateTime);
  const isRideStarted = currentDateTime > rideStartDateTime;
  const hasUserPaid = ride?.user?.payment;

  const handleCategorySelect = async (categoryId) => {
    if (!ride.user?.status) {
      alert('Please register for the ride first to select a category.');
      return;
    }
    try {
      const userId = state.idTokenPayload?.sub;
      await axios.post(`/rides/${rideId}/riders/${userId}/category`, { categoryId });
      queryClient.invalidateQueries(['rideDetails', rideId]);
    } catch (error) {
      console.error('Error updating category:', error);
      alert('Failed to update category. Please try again.');
    }
  };

  const handleRegister = async () => {
    if (!state.isAuthenticated) {
      navigate(`/create-account?redirect=/rides/${rideId}`);
      return;
    }

    setIsRegistering(true);
    try {
      const response = await axios.post(`/rides/${rideId}/register`);
      if (response.status === 200) {
        queryClient.invalidateQueries(['rideDetails', rideId]);
        navigate('#waiver');
      }
    } catch (error) {
      console.error('Error registering for ride:', error);
    } finally {
      setIsRegistering(false);
    }
  };

  const handleUnregister = async () => {
    if (hasUserPaid) {
      alert('You cannot unregister from a ride you have already paid for. Please contact the organizer if you need assistance.');
      return;
    }

    if (!state.isAuthenticated) {
      navigate(`/create-account?redirect=/rides/${rideId}`);
      return;
    }

    const confirmUnregister = window.confirm('Are you sure you want to unregister from this ride?');
    if (!confirmUnregister) return;

    setIsRegistering(true);
    try {
      const response = await axios.post(`/rides/${rideId}/unregister`);
      if (response.status === 200) {
        queryClient.invalidateQueries(['rideDetails', rideId]);
      }
    } catch (error) {
      console.error('Error unregistering from ride:', error);
    } finally {
      setIsRegistering(false);
    }
  };

  const handlePayment = async () => {
    if (!isUserRegistered) {
      alert("Please complete the registration first.");
      return;
    }

    setIsProcessingPayment(true);
    try {
      const isRazorpayLoaded = await loadRazorpay('https://checkout.razorpay.com/v1/checkout.js');
      if (!isRazorpayLoaded) {
        throw new Error('Failed to load payment gateway');
      }

      const orderResponse = await axios.post('/payments/createOrder', {
        amount: ride.payment.amount * 100,
        currency: ride.payment.currency,
        rideId: rideId,
      });

      if (!orderResponse.data.orderId) {
        throw new Error('Failed to create payment order');
      }

      const RazorpayClass = await initRazorpay();
      if (!RazorpayClass) {
        throw new Error('Failed to initialize payment gateway');
      }

      const razorpayKey = state.idTokenPayload.email === 'rohanshar@gmail.com'
        ? 'rzp_test_HaGbACYm1j6H2a'
        : 'rzp_live_3twxUiPBqmGwwH';

      const options = {
        key: razorpayKey,
        amount: ride.payment.amount * 100,
        currency: ride.payment.currency,
        order_id: orderResponse.data.orderId,
        name: "Ultra Rides",
        description: `Payment for ${ride.name}`,
        handler: function (response) {
          queryClient.invalidateQueries(['rideDetails', rideId]);
        },
        prefill: {
          email: state.idTokenPayload.email || '',
        },
        theme: {
          color: "#F37254",
        },
      };

      const razorpay = new RazorpayClass(options);
      razorpay.open();
    } catch (error) {
      console.error('Payment error:', error);
      alert('Payment failed. Please try again.');
    } finally {
      setIsProcessingPayment(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-background text-textPrimary p-6 rounded-lg shadow-md border border-gray-300">
        <div className="flex flex-col gap-4">
          {/* Header Section */}
          <div className="flex flex-wrap items-center gap-4 mb-2">
            <h1 className="text-4xl font-bold">{ride.name}</h1>
            <div className="flex items-center gap-3">
              <div className={`${ride.timeControlType.toLowerCase() === "race" ? "bg-red-500 text-white" : "bg-gray-300"} font-semibold py-1 px-3 rounded-full text-sm`}>
                {ride.timeControlType === "race" ? "Race" : "Not a Race"}
              </div>
              <div className="flex items-center gap-2 bg-primary/10 text-primary px-3 py-1 rounded-full">
                <FaRoute className="text-lg" />
                <span className="font-semibold">{ride.distance} km</span>
              </div>
            </div>
          </div>

          {ride.timeControlType.toLowerCase() === "race" && isRideStarted && ride.categoryResults && ride.categoryResults.length > 0 && (
            <CategoryPodium categoryResults={ride.categoryResults} />
          )}

          <div className="space-y-4">
            <div className="flex items-center">
              <FaCalendarAlt className="mr-2 text-textSecondary" />
              <div className="flex flex-col sm:flex-row sm:items-center">
                <span className="font-semibold text-textSecondary sm:mr-2">Start Date & Time:</span>
                <span className="font-normal text-textPrimary">
                  {format(new Date(ride.startDateTime), 'EEEE, MMMM do, yyyy, h:mm a')}
                </span>
              </div>
            </div>

            <div className="flex items-center">
              <FaRegCalendarCheck className="mr-2 text-textSecondary" />
              <div className="flex flex-col sm:flex-row sm:items-center">
                <span className="font-semibold text-textSecondary sm:mr-2">Club:</span>
                <Link to={`/clubs/${ride.clubId}`} className="text-accent hover:underline text-xl font-semibold">
                  {ride.clubName}
                </Link>
              </div>
            </div>

            <div className="flex items-center">
              <FaClock className="mr-2 text-textSecondary" />
              <div className="flex flex-col sm:flex-row sm:items-center">
                <span className="font-semibold text-textSecondary sm:mr-2">Registration Closes:</span>
                <span className="font-normal text-textPrimary">
                  {ride.registrationClosingDateTime ?
                    format(new Date(ride.registrationClosingDateTime), 'EEEE, MMMM do, yyyy, h:mm a') :
                    'N/A'}
                </span>
              </div>
            </div>
          </div>

          <LocationHeader startPoint={ride.startPoint} />
        </div>
      </div>

      {!isRideStarted && (
        <RegistrationSteps
          isRegistered={isUserRegistered}
          hasPaid={ride?.user?.payment}
          hasWaiver={ride?.user?.waiver}
          payment={ride.payment}
          registrationClosingDateTime={ride.registrationClosingDateTime}
          onRegister={handleRegister}
          onUnregister={hasUserPaid ? null : handleUnregister}
          onPaymentClick={handlePayment}
          onWaiverClick={onWaiverClick}
          registering={isRegistering}
          isProcessingPayment={isProcessingPayment}
          isAuthenticated={state.isAuthenticated}
          categoriesEnabled={ride.categoriesEnabled}
          selectedCategory={ride?.user?.categoryId}
          onCategorySelect={handleCategorySelect}
          categories={ride.categories}
        />
      )}
    </div>
  );
}

export default RideHeader;

import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-background-light text-primary-dark py-4 border-t border-primary">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="flex flex-col items-center md:items-start">
          <p className="text-primary-dark text-lg font-medium mb-2">&copy; 2024 Ultra-Rides</p>
          <p className="text-primary-dark text-sm">All rights reserved.</p>
        </div>
        <div className="flex flex-col items-center md:items-center">
          <nav className="flex flex-col items-center mb-2 space-y-1">
            <Link to="/privacy-policy" className="text-primary-dark hover:text-secondary text-sm">Privacy Policy</Link>
            <Link to="/terms-of-service" className="text-primary-dark hover:text-secondary text-sm">Terms of Service</Link>
            <Link to="/faq" className="text-primary-dark hover:text-secondary text-sm">FAQ</Link>
            <Link to="/rules-and-regulations" className="text-primary-dark hover:text-secondary text-sm">Rules & Regulations</Link>
          </nav>
        </div>
        <div className="flex flex-col items-center md:items-end">
          <nav className="flex flex-col items-center md:items-end mb-2 space-y-1">
            <Link to="/contact" className="text-primary-dark hover:text-secondary text-sm">Contact Us</Link>
          </nav>
          <div className="flex justify-center md:justify-end">
            <a href="https://facebook.com" className="text-primary-dark hover:text-blue-400 mx-1" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={20} />
            </a>
            <a href="https://twitter.com" className="text-primary-dark hover:text-blue-200 mx-1" target="_blank" rel="noopener noreferrer">
              <FaTwitter size={20} />
            </a>
            <a href="https://instagram.com" className="text-primary-dark hover:text-pink-400 mx-1" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={20} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

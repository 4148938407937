import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from 'react-leaflet';
import { Map as MapIcon, X, Search } from 'lucide-react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const customIcon = new L.Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

const formatCoordinate = (coord) => {
  if (typeof coord !== 'number') {
    coord = parseFloat(coord);
  }
  return Number(coord.toFixed(5));
};

const validateCoordinates = (input) => {
  if (!input || !input.includes(',')) return false;
  const [lat, lng] = input.split(',').map(coord => parseFloat(coord.trim()));
  return !isNaN(lat) && !isNaN(lng);
};

const MapPicker = ({ value, onChange, className }) => {
  const defaultPosition = [28.61391, 77.20902]; // Delhi coordinates
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState(defaultPosition);
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [currentZoom, setCurrentZoom] = useState(13);

  useEffect(() => {
    if (validateCoordinates(value)) {
      const [lat, lng] = value.split(',').map(coord => parseFloat(coord.trim()));
      setPosition([lat, lng]);
    }
  }, [value]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchText.trim()) return;

    setIsSearching(true);
    setSearchError('');

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchText)}`
      );
      const data = await response.json();

      if (data.length > 0) {
        const lat = formatCoordinate(data[0].lat);
        const lng = formatCoordinate(data[0].lon);
        setPosition([lat, lng]);
        onChange(`${lat}, ${lng}`);
      } else {
        setSearchError('Location not found');
      }
    } catch (error) {
      setSearchError('Error searching location');
    } finally {
      setIsSearching(false);
    }
  };

  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        const lat = formatCoordinate(e.latlng.lat);
        const lng = formatCoordinate(e.latlng.lng);
        setPosition([lat, lng]);
        onChange(`${lat}, ${lng}`);
        // Get the current zoom level
        setCurrentZoom(map.getZoom());
      },
      zoomend() {
        // Update zoom level when user zooms
        setCurrentZoom(map.getZoom());
      }
    });

    return position ? <Marker position={position} icon={customIcon} /> : null;
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    onChange(inputValue);

    if (validateCoordinates(inputValue)) {
      const [lat, lng] = inputValue.split(',').map(coord => formatCoordinate(coord.trim()));
      setPosition([lat, lng]);
    }
  };

  return (
    <div className={`relative ${className}`}>
      <div className="flex gap-2">
        <input
          type="text"
          className="flex-1 p-1 border rounded-md text-xs"
          placeholder="latitude, longitude (e.g., 28.61391, 77.20902)"
          value={value || ''}
          onChange={handleInputChange}
        />
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center gap-1 text-xs"
        >
          {isOpen ? <X size={12} /> : <MapIcon size={12} />}
          {isOpen ? 'Close' : 'Map'}
        </button>
      </div>

      {isOpen && (
        <div className="absolute z-50 top-full left-0 right-0 mt-2">
          <div className="border-2 rounded-lg shadow-2xl bg-gray-50 p-4" style={{ width: '120%', marginLeft: '-10%' }}>
            <form onSubmit={handleSearch} className="mb-2 flex gap-2">
              <input
                type="text"
                placeholder="Search for a location..."
                className="flex-1 p-1 border rounded-md text-xs"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <button
                type="submit"
                className="px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center gap-1 text-xs"
                disabled={isSearching}
              >
                <Search size={12} />
                {isSearching ? 'Searching...' : 'Search'}
              </button>
            </form>

            {searchError && (
              <div className="mb-2 text-red-500 text-xs">{searchError}</div>
            )}

            <div className="border rounded-md overflow-hidden shadow-inner">
              <MapContainer
                center={position}
                zoom={currentZoom}
                className="h-[400px] w-full rounded-md"
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker />
                <ChangeView center={position} zoom={currentZoom} />
              </MapContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapPicker;
import React, { useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import Toast from './Toast';

function ProfileField({ label, field, value, onSave, editable, multiline }) {
  const [isEditing, setIsEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);
  const [formError, setFormError] = useState('');

  const handleSave = () => {
    if (fieldValue.trim() === '') {
      setFormError(`${label} cannot be empty.`);
      return;
    }
    onSave({ [field]: fieldValue });
    setIsEditing(false);
  };

  return (
    <div>
      <div className="text-left mb-4">
        <p className="text-theme-primary mb-1"><strong>{label}:</strong></p>
        {isEditing ? (
          <div className="flex flex-col">
            {multiline ? (
              <textarea
                value={fieldValue}
                onChange={(e) => setFieldValue(e.target.value)}
                className="text-gray-600 mb-2 w-full p-2 border border-gray-300 rounded"
                rows="4"
              />
            ) : (
              <input
                type="text"
                value={fieldValue}
                onChange={(e) => setFieldValue(e.target.value)}
                className="text-gray-600 mb-2 w-full p-2 border border-gray-300 rounded"
              />
            )}
            <button onClick={handleSave} className="bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-900 transition-colors">Save</button>
          </div>
        ) : (
          <p className="text-gray-700 cursor-pointer" onClick={() => editable && setIsEditing(true)}>
            {value} {editable && <FaPencilAlt className="inline ml-2 text-theme-primary" />}
          </p>
        )}
      </div>
      {formError && <Toast message={formError} type="error" />}
    </div>
  );
}

export default ProfileField;

import React from 'react';
import { useParams } from 'react-router-dom';
import { FaTools } from 'react-icons/fa';
import AdminAccessCheck from './AdminAccessCheck';

function PaymentHistory() {
    const { rideId } = useParams();
    const [isClubOwner, setIsClubOwner] = React.useState(false);

    return (
        <AdminAccessCheck isAdmin={isClubOwner}>
            <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
                <h1 className="text-3xl font-bold text-primary mb-6">Payment History</h1>

                <div className="bg-blue-50 border-l-4 border-blue-500 p-6 rounded-lg">
                    <div className="flex items-center space-x-4">
                        <FaTools className="text-4xl text-blue-500" />
                        <div>
                            <h2 className="text-xl font-semibold text-blue-900 mb-2">
                                Feature Under Development
                            </h2>
                            <p className="text-blue-700">
                                The payment history feature is currently being developed. This section will soon provide:
                            </p>
                            <ul className="list-disc ml-6 mt-2 text-blue-600 space-y-1">
                                <li>Complete transaction history</li>
                                <li>Payment status tracking</li>
                                <li>Refund management</li>
                                <li>Transaction reports and exports</li>
                                <li>Payment analytics and insights</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </AdminAccessCheck>
    );
}

export default PaymentHistory;

import React, { useEffect } from 'react';

function Toast({ message, type }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      const toast = document.getElementById('toast');
      if (toast) {
        toast.style.display = 'none';
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div id="toast" className={`fixed top-5 right-5 p-4 rounded shadow-lg z-50 ${type === 'error' ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}>
      {message}
    </div>
  );
}

export default Toast;

import React, { useEffect, useState } from 'react';
import axios from '../../config/axiosConfig';
import RidersTable from './AdminRidersListComponents/RidersTable';
import ControlPointDetailsPopup from './AdminRidersListComponents/ControlPointDetailsPopup';
import NoCheckInDetailsPopup from './AdminRidersListComponents/NoCheckInDetailsPopup';
import Toast from '../Toast';
import RegisterRiderByURN from './AdminRidersListComponents/RegisterRiderByURN';
import BulkActions from './AdminRidersListComponents/BulkActions';
import AdminLayout from './AdminLayout';

const safeParseJSON = (value) => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
};

function AdminRidersList() {
    const [riders, setRiders] = useState([]);
    const [controlPoints, setControlPoints] = useState([]);
    const [selectedControl, setSelectedControl] = useState(null);
    const [selectedRider, setSelectedRider] = useState(null);
    const [rideId, setRideId] = useState('');
    const [rideStartTime, setRideStartTime] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [hasAccess, setHasAccess] = useState(false);
    const [showRegisterRiderForm, setShowRegisterRiderForm] = useState(false);

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                const rideId = window.location.pathname.split('/')[2];
                setRideId(rideId);
                const rideDetailsResponse = await axios.get(`/rides/${rideId}`);

                const idTokenPayload = safeParseJSON(localStorage.getItem('idTokenPayload'));
                const userEmail = idTokenPayload ? idTokenPayload.email : '';

                const user = rideDetailsResponse.data.user;
                const hasAccess = user.isClubOwner || userEmail === 'rohanshar@gmail.com';
                setHasAccess(hasAccess);

                if (!hasAccess) {
                    return;
                }

                setControlPoints(rideDetailsResponse.data.controlPoints);
                setRideStartTime(rideDetailsResponse.data.startDateTime);

                const response = await axios.get(`/rides/${rideId}/all-checkins`);
                const ridersData = response.data.map(rider => ({
                    ...rider,
                    payment: rider.payment ? { ...rider.payment } : null,
                }));
                setRiders(ridersData);

            } catch (error) {
                console.error('Error fetching admin data:', error);
            }
        };

        fetchAdminData();

        const handleEscapeKeyPress = (event) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscapeKeyPress);

        return () => {
            document.removeEventListener('keydown', handleEscapeKeyPress);
        };
    }, []);

    const handleBulkUpdate = async (updates) => {
        try {
            await Promise.all(updates.map(update =>
                axios.post(`/riders/${update.sub}/rides/${rideId}/status`, {
                    result: update.status,
                    totalTime: update.totalTime
                })
            ));

            setRiders(prevRiders =>
                prevRiders.map(rider => {
                    const update = updates.find(u => u.sub === rider.sub);
                    if (update) {
                        return {
                            ...rider,
                            Result: update.status,
                            TotalTime: update.totalTime
                        };
                    }
                    return rider;
                })
            );

            showToast(`Successfully updated ${updates.length} riders!`);
        } catch (error) {
            console.error('Error performing bulk update:', error);
            showToast('Error performing bulk update.');
        }
    };

    const handleControlClick = (checkedIn, point, rider, idx) => {
        setSelectedRider(rider);

        const matchedControl = controlPoints[idx];

        if (matchedControl) {
            const riderControl = rider.ControlPoints.find(cp =>
                cp.ControlPointName === matchedControl.description && cp.ControlPointNumber === idx + 1
            );

            if (riderControl) {
                setSelectedControl({ ...riderControl, closeTime: matchedControl.closeTime });
            } else {
                setSelectedControl({
                    controlPointNumber: (idx + 1).toString(),
                    controlPointName: matchedControl.description
                });
            }
        }
    };

    const handleStatusChange = async (sub, newStatus, totalTime) => {
        try {
            const payload = { result: newStatus };
            if (totalTime) {
                payload.totalTime = totalTime;
            }
            await axios.post(`/riders/${sub}/rides/${rideId}/status`, payload);
            setRiders((prevRiders) =>
                prevRiders.map((rider) =>
                    rider.sub === sub ? { ...rider, Result: newStatus, TotalTime: totalTime || rider.TotalTime } : rider
                )
            );
        } catch (error) {
            console.error('Error updating rider status:', error);
        }
    };

    const handleClearResult = async (sub) => {
        try {
            const payload = { result: '', totalTime: '' };
            await axios.post(`/riders/${sub}/rides/${rideId}/status`, payload);
            setRiders((prevRiders) =>
                prevRiders.map((rider) =>
                    rider.sub === sub ? { ...rider, Result: '', TotalTime: '' } : rider
                )
            );
        } catch (error) {
            console.error('Error clearing rider result:', error);
        }
    };

    const handleClosePopup = () => {
        setSelectedControl(null);
        setSelectedRider(null);
    };

    const showToast = (message) => {
        setToastMessage(message);
        setTimeout(() => setToastMessage(''), 3000);
    };

    const handleRegisterRiderSuccess = (newRider) => {
        setRiders((prevRiders) => [...prevRiders, newRider]);
        showToast('Rider registered successfully!');
    };

    if (!hasAccess) {
        return <div className='text-center text-red-500'>You don't have access to this page.</div>;
    }

    return (
        <AdminLayout title="Result Management">
            <div className='overflow-x-auto'>
                <div className="mb-4 flex justify-between items-center">
                    <BulkActions
                        riders={riders}
                        controlPoints={controlPoints}
                        onBulkUpdate={handleBulkUpdate}
                    />

                    {hasAccess && (
                        <button
                            onClick={() => setShowRegisterRiderForm(true)}
                            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                        >
                            Register Rider
                        </button>
                    )}
                </div>

                <RidersTable
                    riders={riders}
                    setRiders={setRiders}
                    controlPoints={controlPoints}
                    handleControlClick={handleControlClick}
                    handleStatusChange={handleStatusChange}
                    handleClearResult={handleClearResult}
                    showToast={showToast}
                />

                {selectedControl && (
                    selectedControl.closeTime ? (
                        <ControlPointDetailsPopup
                            controlDetails={selectedControl}
                            onClose={handleClosePopup}
                            rideStartTime={rideStartTime}
                        />
                    ) : (
                        <NoCheckInDetailsPopup
                            onClose={handleClosePopup}
                            rideId={rideId}
                            controlPointName={selectedControl ? selectedControl.controlPointName : ''}
                            controlPointNumber={selectedControl ? selectedControl.controlPointNumber : ''}
                            rider={selectedRider}
                        />
                    )
                )}

                {showRegisterRiderForm && (
                    <RegisterRiderByURN
                        rideId={rideId}
                        onClose={() => setShowRegisterRiderForm(false)}
                        onSuccess={handleRegisterRiderSuccess}
                    />
                )}

                {toastMessage && <Toast message={toastMessage} />}
            </div>
        </AdminLayout>
    );
}

export default AdminRidersList;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../config/axiosConfig';
import CategoryActionDropdown from '../AdminRidersListComponents/CategoryActionDropdown';
import AdminLayout from '../AdminLayout';
import { FaSort, FaMoneyBill, FaDownload, FaFilter } from 'react-icons/fa';

function RiderCategoryManager() {
    const [riders, setRiders] = useState([]);
    const [filteredRiders, setFilteredRiders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [categories, setCategories] = useState([]);
    const { rideId } = useParams();
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: 'asc'
    });
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showUnpaid, setShowUnpaid] = useState(false);
    const [showUnassigned, setShowUnassigned] = useState(false);
    const [showOnlyPaid, setShowOnlyPaid] = useState(true);  // New state for paid filter

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`/rides/${rideId}`);
                const rideData = response.data;
                const allRiders = rideData.registeredRiders || [];

                setRiders(allRiders);
                const paidRiders = allRiders.filter(rider =>
                    rider.payment && (rider.payment.status === 'paid' || rider.payment.status === 'true' || rider.payment.offlinePayment)
                );
                setFilteredRiders(paidRiders);
                setCategories(rideData.categories || []);
            } catch (err) {
                setError('Failed to fetch data');
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [rideId]);

    useEffect(() => {
        let filtered = riders;

        // Apply paid filter
        if (showOnlyPaid) {
            filtered = filtered.filter(rider =>
                rider.payment && (rider.payment.status === 'paid' || rider.payment.status === 'true' || rider.payment.offlinePayment)
            );
        }

        // Apply category filters
        if (showUnassigned) {
            filtered = filtered.filter(rider => !rider.categoryId);
        } else if (selectedCategory) {
            filtered = filtered.filter(rider => rider.categoryId === selectedCategory);
        }

        setFilteredRiders(filtered);
    }, [selectedCategory, riders, showOnlyPaid, showUnassigned]);
    const handleCategoryChange = async (userId, categoryId) => {
        try {
            await axios.post(`/rides/${rideId}/riders/${userId}/category`, {
                categoryId: categoryId
            });

            setRiders(prevRiders => prevRiders.map(rider => {
                if (rider.userId === userId) {
                    return { ...rider, categoryId: categoryId };
                }
                return rider;
            }));

        } catch (err) {
            console.error('Failed to update category:', err);
            setError('Failed to update category');
        }
    };

    const handleExportCSV = () => {
        const headers = ['URN', 'Name', 'Email', 'Category', 'Payment Status'];
        const csvContent = [
            headers.join(','),
            ...filteredRiders.map(rider => [
                rider.urn,
                rider.name,
                rider.email,
                categories.find(cat => cat.id === rider.categoryId)?.name || 'Unassigned',
                rider.payment?.status ? (rider.payment.offlinePayment ? 'Paid (Offline)' : 'Paid') : 'Unpaid'
            ].join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `rider-categories-${new Date().toISOString()}.csv`;
        link.click();
    };

    const getCategoryCount = (categoryId) => {
        let ridersToCount = riders;
        if (!showUnpaid) {
            ridersToCount = riders.filter(rider =>
                rider.payment && (rider.payment.status === 'paid' || rider.payment.status === 'true' || rider.payment.offlinePayment)
            );
        }
        return ridersToCount.filter(rider => rider.categoryId === categoryId).length;
    };

    const getUnassignedCount = () => {
        let ridersToCount = riders;
        if (!showUnpaid) {
            ridersToCount = riders.filter(rider =>
                rider.payment && (rider.payment.status === 'paid' || rider.payment.status === 'true' || rider.payment.offlinePayment)
            );
        }
        return ridersToCount.filter(rider => !rider.categoryId).length;
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedRiders = [...filteredRiders].sort((a, b) => {
            if (key === 'category') {
                const categoryA = categories.find(cat => cat.id === a.categoryId)?.name || '';
                const categoryB = categories.find(cat => cat.id === b.categoryId)?.name || '';
                return direction === 'asc'
                    ? categoryA.localeCompare(categoryB)
                    : categoryB.localeCompare(categoryA);
            }

            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredRiders(sortedRiders);
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return (
                <FaSort className={`inline ml-1 ${sortConfig.direction === 'asc' ? 'text-gray-400' : 'text-gray-600'}`} />
            );
        }
        return <FaSort className="inline ml-1 text-gray-400" />;
    };

    const getTotalPaidCount = () => {
        return riders.filter(rider =>
            rider.payment && (rider.payment.status === 'paid' || rider.payment.status === 'true' || rider.payment.offlinePayment)
        ).length;
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div className="text-red-500">{error}</div>;

    return (
        <AdminLayout title="Rider Categories">
            <div className="space-y-4">
                {/* Stats Panel */}
                <div className="bg-white rounded-lg shadow-sm p-4 grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div className="text-center p-4 bg-gray-50 rounded-lg">
                        <p className="text-sm text-gray-600">Total Riders</p>
                        <p className="text-2xl font-bold text-gray-900">{riders.length}</p>
                    </div>
                    <div className="text-center p-4 bg-gray-50 rounded-lg">
                        <p className="text-sm text-gray-600">Paid Riders</p>
                        <p className="text-2xl font-bold text-green-600">{getTotalPaidCount()}</p>
                    </div>
                    <div className="text-center p-4 bg-gray-50 rounded-lg">
                        <p className="text-sm text-gray-600">Unpaid Riders</p>
                        <p className="text-2xl font-bold text-red-600">{riders.length - getTotalPaidCount()}</p>
                    </div>
                    <div className="text-center p-4 bg-gray-50 rounded-lg">
                        <p className="text-sm text-gray-600">Unassigned Riders</p>
                        <p className="text-2xl font-bold text-blue-600">{getUnassignedCount()}</p>
                    </div>
                </div>

                {/* Control Buttons */}
                <div className="flex flex-wrap gap-4 items-center justify-between">
                    <div className="flex flex-wrap gap-4">
                        <button
                            onClick={() => setShowOnlyPaid(!showOnlyPaid)}
                            className={`flex items-center gap-2 px-4 py-2 rounded-lg font-medium transition-colors
                                ${showOnlyPaid
                                    ? 'bg-green-100 text-green-800 border border-green-300'
                                    : 'bg-gray-100 text-gray-800 border border-gray-300'
                                }`}
                        >
                            <FaFilter />
                            {showOnlyPaid ? 'Showing Paid Only' : 'Showing All Riders'}
                        </button>

                        <button
                            onClick={() => setShowUnassigned(!showUnassigned)}
                            className={`flex items-center gap-2 px-4 py-2 rounded-lg font-medium transition-colors
                                ${showUnassigned
                                    ? 'bg-blue-100 text-blue-800 border border-blue-300'
                                    : 'bg-gray-100 text-gray-800 border border-gray-300'
                                }`}
                        >
                            <FaFilter />
                            {showUnassigned ? 'All Categories' : 'Unassigned Only'}
                        </button>

                        <button
                            onClick={handleExportCSV}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                        >
                            <FaDownload />
                            Export to CSV
                        </button>
                    </div>
                </div>

                <div className="bg-gradient-to-r from-blue-50 to-blue-100 border-l-4 border-blue-500 rounded-lg p-4 mb-6">
                    <div className="flex items-center gap-2 text-blue-700">
                        <span className="font-medium">Total Riders:</span> {riders.length}
                        <span className="mx-2 text-blue-300">|</span>
                        <span className="font-medium">Paid Riders:</span> {getTotalPaidCount()}
                        <span className="mx-2 text-blue-300">|</span>
                        <span className="font-medium">Unassigned Riders:</span> {getUnassignedCount()}
                    </div>
                </div>

                {categories && categories.length > 0 && (
                    <div className="mb-8">
                        <div className="bg-white border border-gray-200 rounded-lg shadow-sm">
                            <div className="px-6 py-4 border-b border-gray-200">
                                <h3 className="text-lg font-semibold text-gray-800">Categories</h3>
                            </div>
                            <div className="p-6 bg-gray-50">
                                <div className="flex flex-wrap gap-2">
                                    <button
                                        onClick={() => {
                                            setSelectedCategory(null);
                                            setShowUnassigned(false);
                                        }}
                                        className={`relative px-4 py-2 text-sm font-medium transition-all duration-200 ease-in-out
                                        ${!selectedCategory && !showUnassigned
                                                ? 'text-gray-800 bg-white border-2 border-gray-800 shadow-sm z-10 transform scale-105'
                                                : 'text-gray-600 bg-white border border-gray-300 hover:border-gray-400 hover:text-gray-800'
                                            } rounded-md`}
                                    >
                                        <span className="relative z-10">All Categories</span>
                                        <span className="ml-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none 
                                        ${!selectedCategory && !showUnassigned ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-800'} rounded-full">
                                            {filteredRiders.length}
                                        </span>
                                    </button>
                                    <button
                                        onClick={() => {
                                            setSelectedCategory(null);
                                            setShowUnassigned(true);
                                        }}
                                        className={`relative px-4 py-2 text-sm font-medium transition-all duration-200 ease-in-out
                                        ${showUnassigned
                                                ? 'text-gray-800 bg-white border-2 border-gray-800 shadow-sm z-10 transform scale-105'
                                                : 'text-gray-600 bg-white border border-gray-300 hover:border-gray-400 hover:text-gray-800'
                                            } rounded-md`}
                                    >
                                        <span className="relative z-10">Unassigned</span>
                                        <span className="ml-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none 
                                        ${showUnassigned ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-800'} rounded-full">
                                            {getUnassignedCount()}
                                        </span>
                                    </button>
                                    {categories.map(category => (
                                        <button
                                            key={category.id}
                                            onClick={() => {
                                                setSelectedCategory(category.id);
                                                setShowUnassigned(false);
                                            }}
                                            className={`relative px-4 py-2 text-sm font-medium transition-all duration-200 ease-in-out
                                            ${selectedCategory === category.id
                                                    ? 'text-gray-800 bg-white border-2 border-gray-800 shadow-sm z-10 transform scale-105'
                                                    : 'text-gray-600 bg-white border border-gray-300 hover:border-gray-400 hover:text-gray-800'
                                                } rounded-md`}
                                        >
                                            <span className="relative z-10">{category.name}</span>
                                            <span className="ml-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none 
                                            ${selectedCategory === category.id ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-800'} 
                                            rounded-full">
                                                {getCategoryCount(category.id)}
                                            </span>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="overflow-x-auto border border-gray-200 rounded-lg shadow-sm">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 transition-colors"
                                    onClick={() => handleSort('urn')}
                                >
                                    URN {getSortIcon('urn')}
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 transition-colors"
                                    onClick={() => handleSort('name')}
                                >
                                    Name {getSortIcon('name')}
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Payment Status
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 transition-colors w-64"
                                    onClick={() => handleSort('category')}
                                >
                                    Category {getSortIcon('category')}
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredRiders.map((rider) => (
                                <tr key={rider.userId} className="hover:bg-gray-50 transition-colors">

                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {rider.urn}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {rider.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                                        {rider.payment ? (
                                            rider.payment.offlinePayment ? (
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                                    Paid (Offline)
                                                </span>
                                            ) : (
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                    Paid
                                                </span>
                                            )
                                        ) : (
                                            <span className="inline-flex-items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                                Unpaid
                                            </span>
                                        )}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <CategoryActionDropdown
                                            currentCategory={rider.categoryId}
                                            categories={categories}
                                            onSelect={(categoryId) => handleCategoryChange(rider.userId, categoryId)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </AdminLayout>
    );
}

export default RiderCategoryManager;

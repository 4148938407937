import React from 'react';
import { FaTrophy, FaStar } from 'react-icons/fa';

const getBadgeImage = (distance, timeControlType) => {
  console.log({ distance, timeControlType })
  switch (distance) {
    case 20:
      return timeControlType === 'Race' ? '/20kRaceElevatedRoad.png' : null;
    case 100:
      return timeControlType !== 'BRM' && timeControlType !== 'N/A' ? '/100kBadge_challenge.png' : '/100kBadge.png';
    case 150:
      return '/150kBadge.png';
    case 200:
      return '/200kBadge.png';
    case 250:
      return timeControlType !== 'BRM' && timeControlType !== 'N/A' ? '/250Badge12h.png' : '/250kBadge.png';
    case 300:
      return '/300kBadge.png';
    default:
      return null;
  }
};

const renderBadge = (distance, timeControlType, count, colorClass) => {
  if (count > 0) {
    return (
      <div className="relative w-24 h-24 sm:w-28 sm:h-28 md:w-32 md:h-32 flex items-center justify-center transform hover:scale-105 transition-transform duration-300">
        <div className="relative flex flex-col items-center">
          <img
            src={getBadgeImage(distance, timeControlType)}
            alt={`${distance}k Badge`}
            className="w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 mb-2 object-contain"
          />
          <div className={`absolute -top-2 -right-2 bg-white ${colorClass} rounded-full w-8 h-8 flex items-center justify-center font-bold border-2 text-xs sm:text-sm`}>
            {count}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const Badges = ({ rideHistory }) => {
  const badgeCounts = rideHistory.reduce((acc, ride) => {
    if (ride.Result === 'Finished') {
      const timeControlType = ride.timeControlType === 'N/A' ? 'BRM' : (ride.timeControlType || 'BRM');
      const key = `${ride.rideDistance}_${timeControlType}`;
      acc[key] = (acc[key] || 0) + 1;
    }
    return acc;
  }, {});

  console.log(badgeCounts);

  return (
    <div className="w-full max-w-3xl mx-auto">
      <h2 className="text-2xl font-semibold mb-4 text-center text-textPrimary">Badge Collection</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4 justify-items-center">
        {renderBadge(20, 'race', badgeCounts['20_race'], 'text-orange-600 border-orange-600')}
        {renderBadge(100, 'BRM', badgeCounts['100_BRM'], 'text-yellow-600 border-yellow-600')}
        {renderBadge(100, 'challenge', badgeCounts['100_Challenge'], 'text-green-600 border-green-600')}
        {renderBadge(150, 'BRM', badgeCounts['150_BRM'], 'text-green-600 border-green-600')}
        {renderBadge(200, 'BRM', badgeCounts['200_BRM'], 'text-red-600 border-red-600')}
        {renderBadge(250, 'BRM', badgeCounts['250_BRM'], 'text-blue-600 border-blue-600')}
        {renderBadge(250, '12h', badgeCounts['250_12h'], 'text-blue-600 border-blue-600')}
        {renderBadge(300, 'BRM', badgeCounts['300_BRM'], 'text-purple-600 border-purple-600')}
        {Object.keys(badgeCounts).length === 0 && (
          <p className="text-textSecondary col-span-full text-center">No badges earned yet</p>
        )}
      </div>
    </div>
  );
};

export default Badges;
import React, { useState, useEffect } from 'react';
import axios from '../../config/axiosConfig';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import CheckInButton from './CheckInButton';

function CheckIn({ ride, controlPoints, waiverSubmitted }) {
  const { rideId } = useParams();
  const { state } = useAuth();
  const [checkedInStations, setCheckedInStations] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCheckedInStations = async () => {
      try {
        const response = await axios.get(`/rides/${rideId}/check-in`);
        if (response.status === 200) {
          const checkedInControls = response.data.controls.map(control => ({
            controlPointNumber: Number(control.controlPointNumber),
            imageUrl: control.imageUrl,
            isAdminCheckIn: control.isAdminCheckIn
          }));
          setCheckedInStations(checkedInControls);
        } else {
          console.error('Failed to fetch checked-in stations:', response);
        }
      } catch (error) {
        console.error('Error fetching checked-in stations:', error);
      }
    };

    if (state.isAuthenticated) {
      fetchCheckedInStations();
    }
  }, [rideId, state.isAuthenticated]);

  const handleRemoveCheckIn = async (controlPointNumber) => {
    try {
      const response = await axios.delete(`/rides/${rideId}/check-in/${controlPointNumber}`);
      if (response.status === 200) {
        setCheckedInStations((prev) => prev.filter((control) => control.controlPointNumber !== controlPointNumber));
      } else {
        console.error('Failed to remove check-in:', response);
      }
    } catch (error) {
      console.error('Error removing check-in:', error);
    }
  };

  if (!state.isAuthenticated) {
    return (
      <div className="check-in max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg mt-8">
        <h1 className="text-3xl font-semibold text-accent mb-2">Check-In at Timing Stations</h1>
        <p className="text-textSecondary mb-6">
          Please register or sign in to use the tracker and check-in at timing stations.
        </p>
      </div>
    );
  }

  return (
    <div className="check-in max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg mt-8">
      <h1 className="text-3xl font-semibold text-accent mb-2">Check-In at Timing Stations</h1>
      <p className="text-textSecondary mb-6">
        Confirm your arrival at each timing station by clicking the check-in button. Your location and a selfie will be recorded and sent to the ride organizers.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {controlPoints.map((point, index) => {
          const checkedInControl = checkedInStations.find(control => control.controlPointNumber === index + 1);
          const isControlClosed = new Date(point.closeTime) < new Date();

          const gpsCoordinates = point.gpsCoordinates || "";
          const [latitude, longitude] = gpsCoordinates.split(',').map(coord => coord.trim());
          if (!latitude || !longitude) {
            console.error(`Invalid coordinates for control point: ${point.description}`);
            return null;
          }

          return (
            <div
              key={index}
              className={`p-4 border rounded-lg shadow-md ${checkedInControl ? 'bg-green-200' : index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'}`}
            >
              <h2 className="text-xl font-semibold mb-2 text-accent-dark">{`${index + 1}. ${point.description}`}</h2>
              <p className="text-textSecondary">Distance: {point.distance} km</p>
              <p className="text-textSecondary">
                Close Time:{' '}
                {new Date(point.closeTime).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}, {new Date(point.closeTime).toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })}
              </p>
              {checkedInControl ? (
                <div className="checked-in-message mt-4">
                  <p className="text-green-700 font-bold text-xl mb-2">Check-in Successful</p>
                  {checkedInControl.imageUrl && (
                    <img
                      src={checkedInControl.imageUrl}
                      alt={`Check-in at ${point.description}`}
                      className="w-32 h-32 object-cover rounded-lg" // Adjusted size
                    />
                  )}
                  {!isControlClosed && (
                    <button
                      onClick={() => handleRemoveCheckIn(checkedInControl.controlPointNumber)}
                      className="mt-2 px-4 py-2 rounded bg-red-500 hover:bg-red-600 text-white"
                    >
                      Remove Check-In
                    </button>
                  )}
                </div>
              ) : (
                <div className="not-checked-in-message mt-4">
                  <p className="text-red-700 font-bold text-xl mb-2">Not Checked In</p>
                  <CheckInButton
                    rideId={rideId}
                    control={{
                      ...point,
                      latitude: latitude.trim(),
                      longitude: longitude.trim(),
                    }}
                    index={index}
                    checkedInStations={checkedInStations}
                    setCheckedInStations={setCheckedInStations}
                    isAvailable={waiverSubmitted}
                    setError={setError}
                  />
                  {!waiverSubmitted && (
                    <p className="text-error mt-2">Please submit the waiver form to enable check-in.</p>
                  )}
                </div>
              )}
              {checkedInControl && checkedInControl.isAdminCheckIn && (
                <p className="text-accent font-bold mt-2">Organizer has checked you in.</p>
              )}
            </div>
          );
        })}
      </div>
      {error && <p className="text-error mt-4">{error}</p>}
    </div>
  );
}

export default CheckIn;
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import axios from '../../../config/axiosConfig';

function DuplicateModal({ show, onClose, onSuccess, selectedRide }) {
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [duplicateError, setDuplicateError] = useState(null);
  const [newStartDateTime, setNewStartDateTime] = useState(() => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 7);
    return startDate.toISOString().slice(0, 16);
  });
  const [newRegistrationCloseDateTime, setNewRegistrationCloseDateTime] = useState(() => {
    const registrationCloseDate = new Date();
    registrationCloseDate.setDate(registrationCloseDate.getDate() + 6);
    return registrationCloseDate.toISOString().slice(0, 16);
  });

  if (!show) return null;

  const handleDuplicate = async () => {
    const newStartDate = new Date(newStartDateTime);
    const registrationClosingDate = new Date(newRegistrationCloseDateTime);
    const now = new Date();

    if (registrationClosingDate <= now) {
      setDuplicateError('Registration closing date must be in the future.');
      return;
    }

    if (newStartDate <= registrationClosingDate) {
      setDuplicateError('Start date must be after registration closing date.');
      return;
    }

    try {
      setDuplicateLoading(true);
      setDuplicateError(null);

      const payload = {
        sourceRideId: selectedRide.rideId,
        newStartDate: newStartDate.toISOString(),
        registrationClosingDate: registrationClosingDate.toISOString(),
      };

      const response = await axios.post(
        `/rides/${selectedRide.rideId}/duplicate`,
        payload
      );

      onSuccess(response.data.newRideId);
    } catch (error) {
      console.error('Error duplicating ride:', error);
      setDuplicateError(
        error.response?.data?.message || 'Failed to duplicate ride. Please try again.'
      );
    } finally {
      setDuplicateLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Set New Ride Dates</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <FaTimes />
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Start Date & Time
            </label>
            <input
              type="datetime-local"
              value={newStartDateTime}
              onChange={(e) => setNewStartDateTime(e.target.value)}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              min={new Date().toISOString().slice(0, 16)}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Registration Close Date & Time
            </label>
            <input
              type="datetime-local"
              value={newRegistrationCloseDateTime}
              onChange={(e) => setNewRegistrationCloseDateTime(e.target.value)}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              min={new Date().toISOString().slice(0, 16)}
            />
            <p className="text-sm text-gray-500 mt-1">
              Must be before the start date
            </p>
          </div>

          {duplicateError && (
            <div className="text-red-500 text-sm mt-2">
              {duplicateError}
            </div>
          )}

          <div className="flex justify-end space-x-3 mt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
              disabled={duplicateLoading}
            >
              Cancel
            </button>
            <button
              onClick={handleDuplicate}
              disabled={duplicateLoading}
              className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark disabled:opacity-50"
            >
              {duplicateLoading ? 'Duplicating...' : 'Duplicate Ride'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DuplicateModal;

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import LocationAndContactComponent from './RideDetailsTab/LocationAndContactComponent';
import TruncatedText from '../ui/truncated-text';

function RideDetailsTab({ ride }) {
  return (
    <div className="space-y-6">
      {/* Cover Image Section */}
      <div className="relative w-full h-0 pb-[40%] overflow-hidden rounded-xl bg-gray-100">
        {ride.coverImage ? (
          <img
            src={ride.coverImage}
            alt={ride.name}
            className="absolute inset-0 w-full h-full object-cover"
          />
        ) : (
          <div className="absolute inset-0 flex items-center justify-center text-gray-400">
            No cover image available
          </div>
        )}
      </div>

      <LocationAndContactComponent
        startPoint={ride.startPoint}
        responsibleName={ride.responsibleName}
        responsibleEmail={ride.responsibleEmail}
        responsiblePhone={ride.responsiblePhone}
        whatsappGroupUrl={ride.whatsappGroupUrl}
      />

      <div className="space-y-6">
        {/* Ride Details Section */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
          <h2 className="text-xl font-semibold mb-4">Ride Details</h2>
          {ride.details ? (
            <div className="bg-white relative">
              <TruncatedText>
                <ReactMarkdown 
                  remarkPlugins={[remarkGfm]} 
                  rehypePlugins={[rehypeRaw]}
                  className="prose prose-sm max-w-none"
                >
                  {ride.details}
                </ReactMarkdown>
              </TruncatedText>
            </div>
          ) : (
            <div className="text-gray-400 italic">
              No additional details available
            </div>
          )}
        </div>

        {/* Rules and Regulations Section */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
          <h2 className="text-xl font-semibold mb-4">Rules and Regulations</h2>
          {ride.rulesAndRegulations ? (
            <div className="bg-white relative">
              <TruncatedText>
                <ReactMarkdown 
                  remarkPlugins={[remarkGfm]} 
                  rehypePlugins={[rehypeRaw]}
                  className="prose prose-sm max-w-none"
                >
                  {ride.rulesAndRegulations}
                </ReactMarkdown>
              </TruncatedText>
            </div>
          ) : (
            <div className="text-gray-400 italic">
              No rules and regulations available
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RideDetailsTab;

import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from '../../config/axiosConfig';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
    Image as ImageIcon,
    Upload,
    X as XIcon,
    CheckCircle,
    AlertCircle,
    Info,
    Trash2,
    ZoomIn,
    ZoomOut,
    Move
} from 'lucide-react';
import AdminLayout from './AdminLayout';

const CoverImageManager = () => {
    const { rideId } = useParams();
    const queryClient = useQueryClient();
    const [editing, setEditing] = useState(false);
    const [newImage, setNewImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [imageInfo, setImageInfo] = useState(null);
    const [isZoomed, setIsZoomed] = useState(false);
    const [dragCount, setDragCount] = useState(0);

    const { data: ride } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
    });

    useEffect(() => {
        if (ride?.coverImage) {
            setPreviewUrl(ride.coverImage);
        }
    }, [ride]);

    const removeMutation = useMutation({
        mutationFn: async () => {
            return axios.patch(`/rides/${rideId}`, {
                coverImage: '',
                clubId: ride?.clubId
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['rideDetails', rideId]);
            setPreviewUrl('');
            setSuccess('Cover image removed successfully');
            setTimeout(() => setSuccess(''), 3000);
        },
        onError: () => {
            setError('Failed to remove cover image');
            setTimeout(() => setError(''), 3000);
        }
    });

    const handleRemoveImage = () => {
        if (window.confirm('Are you sure you want to remove the cover image?')) {
            removeMutation.mutate();
        }
    };

    useEffect(() => {
        if (newImage) {
            const img = new Image();
            img.src = previewUrl;
            img.onload = () => {
                setImageInfo({
                    width: img.width,
                    height: img.height,
                    aspectRatio: (img.width / img.height).toFixed(2),
                    size: (newImage.size / (1024 * 1024)).toFixed(2)
                });
            };
        }
    }, [newImage, previewUrl]);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                setError('Image size must be less than 5MB');
                return;
            }
            setNewImage(file);
            setPreviewUrl(URL.createObjectURL(file));
            setEditing(true);
            setError('');
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png', '.webp']
        },
        maxSize: 5 * 1024 * 1024,
        multiple: false
    });

    const handleUpload = async () => {
        if (!newImage) return;
        setIsUploading(true);
        setError('');

        try {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64Image = reader.result.split(',')[1];

                const interval = setInterval(() => {
                    setUploadProgress(prev => {
                        if (prev >= 90) {
                            clearInterval(interval);
                            return 90;
                        }
                        return prev + 10;
                    });
                }, 200);

                try {
                    const response = await axios.patch(`/rides/${rideId}`, {
                        imageBase64: base64Image,
                        clubId: ride?.clubId
                    });

                    setUploadProgress(100);
                    setTimeout(() => {
                        setEditing(false);
                        setNewImage(null);
                        setIsUploading(false);
                        setUploadProgress(0);
                        setPreviewUrl(response.data.coverImageUrl);
                        setSuccess('Cover image updated successfully');
                        setTimeout(() => setSuccess(''), 3000);
                    }, 500);
                } catch (error) {
                    setError('Failed to upload image. Please try again.');
                    console.error('Error uploading image:', error);
                    clearInterval(interval);
                    setUploadProgress(0);
                    setIsUploading(false);
                }
            };
            reader.readAsDataURL(newImage);
        } catch (error) {
            setError('Failed to process image. Please try again.');
            setIsUploading(false);
        }
    };

    const handleCancel = () => {
        setEditing(false);
        setNewImage(null);
        setPreviewUrl(ride?.coverImage || '');
        setError('');
        setImageInfo(null);
    };

    const handleDragEnter = () => {
        setDragCount(prev => prev + 1);
    };

    const handleDragLeave = () => {
        setDragCount(prev => prev - 1);
    };

    return (
        <AdminLayout title="Cover Image Management">
            <div className="max-w-4xl mx-auto rounded-lg">
                {success && (
                    <div className="mb-4 p-4 bg-green-50 border-l-4 border-green-500 text-green-700">
                        {success}
                    </div>
                )}
                {error && (
                    <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
                        {error}
                    </div>
                )}
                <div className="space-y-6">
                    {!editing ? (
                        <div className="relative group">
                            <div className="relative h-64 overflow-hidden rounded-lg">
                                {previewUrl ? (
                                    <img
                                        src={previewUrl}
                                        alt="Current cover"
                                        className={`w-full h-full object-cover transition-all duration-300 
                                        ${isZoomed ? 'scale-150' : 'scale-100'}`}
                                    />
                                ) : (
                                    <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                                        <ImageIcon className="h-12 w-12 text-gray-400" />
                                    </div>
                                )}
                                <div className="absolute inset-0 bg-black/0 group-hover:bg-black/30 transition-all duration-300">
                                    <div className="absolute bottom-4 right-4 flex space-x-2 opacity-0 group-hover:opacity-100 transition-all duration-300">
                                        {previewUrl && (
                                            <>
                                                <button
                                                    onClick={() => setIsZoomed(!isZoomed)}
                                                    className="bg-white/90 hover:bg-white text-gray-800 p-2 rounded-lg shadow-lg transition-transform hover:-translate-y-1"
                                                >
                                                    {isZoomed ? <ZoomOut className="h-5 w-5" /> : <ZoomIn className="h-5 w-5" />}
                                                </button>
                                                <button
                                                    onClick={handleRemoveImage}
                                                    className="bg-red-500/90 hover:bg-red-500 text-white p-2 rounded-lg shadow-lg transition-transform hover:-translate-y-1"
                                                >
                                                    <Trash2 className="h-5 w-5" />
                                                </button>
                                            </>
                                        )}
                                        <button
                                            onClick={() => setEditing(true)}
                                            className="bg-white/90 hover:bg-white text-gray-800 p-2 rounded-lg shadow-lg transition-transform hover:-translate-y-1 flex items-center space-x-2"
                                        >
                                            <Upload className="h-5 w-5" />
                                            <span>{previewUrl ? 'Change Image' : 'Add Image'}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            <div
                                {...getRootProps()}
                                className="relative"
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                            >
                                <div className={`
                                    border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-all duration-300
                                    ${dragCount > 0 ? 'border-blue-500 bg-blue-50 scale-102' : 'border-gray-300 hover:border-blue-400'}
                                `}>
                                    <input {...getInputProps()} />
                                    <div className="space-y-3">
                                        <Upload className={`mx-auto h-12 w-12 transition-colors duration-300 ${dragCount > 0 ? 'text-blue-500' : 'text-gray-400'}`} />
                                        <p className="text-gray-600 font-medium">
                                            {isDragActive ? "Drop your image here..." : "Drag and drop your image here"}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            or click to browse your files
                                        </p>
                                        <div className="flex items-center justify-center space-x-2 text-xs text-gray-400">
                                            <span>Supports: JPG, PNG, WebP</span>
                                            <span>•</span>
                                            <span>Max size: 5MB</span>
                                            <span>•</span>
                                            <span>Recommended: 1920x1080</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {previewUrl && (
                                <div className="space-y-4">
                                    <div className="relative rounded-lg overflow-hidden shadow-lg">
                                        <img
                                            src={previewUrl}
                                            alt="Preview"
                                            className="w-full h-64 object-cover"
                                        />
                                        {isUploading && (
                                            <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center">
                                                <div className="w-3/4 max-w-md space-y-3">
                                                    <div className="flex items-center justify-between text-white">
                                                        <span>Uploading your image...</span>
                                                        <span>{uploadProgress}%</span>
                                                    </div>
                                                    <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                                                        <div
                                                            style={{ width: `${uploadProgress}%` }}
                                                            className="h-full bg-blue-500 transition-all duration-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {imageInfo && (
                                        <div className="bg-gray-50 rounded-lg p-4 grid grid-cols-2 md:grid-cols-4 gap-4">
                                            <div className="text-center">
                                                <p className="text-sm text-gray-500">Dimensions</p>
                                                <p className="font-medium">{imageInfo.width} x {imageInfo.height}</p>
                                            </div>
                                            <div className="text-center">
                                                <p className="text-sm text-gray-500">Aspect Ratio</p>
                                                <p className="font-medium">{imageInfo.aspectRatio}</p>
                                            </div>
                                            <div className="text-center">
                                                <p className="text-sm text-gray-500">File Size</p>
                                                <p className="font-medium">{imageInfo.size} MB</p>
                                            </div>
                                            <div className="text-center">
                                                <p className="text-sm text-gray-500">Format</p>
                                                <p className="font-medium">{newImage?.type.split('/')[1].toUpperCase()}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className="flex space-x-4">
                                <button
                                    onClick={handleUpload}
                                    disabled={isUploading || !newImage}
                                    className={`
                                        flex-1 flex items-center justify-center px-4 py-3 rounded-lg text-white font-medium
                                        ${isUploading || !newImage
                                            ? 'bg-gray-400 cursor-not-allowed'
                                            : 'bg-blue-500 hover:bg-blue-600 transform hover:-translate-y-1 transition-all duration-200'
                                        }
                                    `}
                                >
                                    {isUploading ? (
                                        <>
                                            <Upload className="mr-2 h-5 w-5 animate-bounce" />
                                            Uploading...
                                        </>
                                    ) : (
                                        <>
                                            <Upload className="mr-2 h-5 w-5" />
                                            Upload Image
                                        </>
                                    )}
                                </button>
                                <button
                                    onClick={handleCancel}
                                    disabled={isUploading}
                                    className="flex-1 flex items-center justify-center px-4 py-3 rounded-lg text-gray-700 font-medium border border-gray-300 hover:bg-gray-50 transform hover:-translate-y-1 transition-all duration-200"
                                >
                                    <XIcon className="mr-2 h-5 w-5" />
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </AdminLayout>
    );
};

export default CoverImageManager;

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ChevronDown, ChevronUp, ChevronRight } from 'lucide-react';
import axios from '../../config/axiosConfig';

function RiderDetails({ rides }) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="w-full">
            <div onClick={() => setIsExpanded(!isExpanded)}
                className="cursor-pointer text-gray-500 hover:text-primary text-sm mt-1 flex items-center">
                {isExpanded ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
                <span className="ml-1 text-xs">{isExpanded ? 'Hide' : 'Show'} ride details</span>
            </div>
            {isExpanded && (
                <div className="mt-1 pl-4 space-y-1">
                    {rides.map((ride) => (
                        <Link to={`/rides/${ride.rideId}`} key={ride.rideId}
                            className="flex items-center justify-between p-1 hover:bg-gray-50 rounded text-xs">
                            <div className="flex items-center">
                                <ChevronRight size={14} className="text-gray-400" />
                                <span className="ml-1">{ride.distance} km</span>
                            </div>
                            <div className="text-primary hover:text-primary-dark">
                                View Ride
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
}

function LeaderboardSection({ title, data, showAll, toggleShowAll }) {
    return (
        <div className="bg-white p-3 rounded-lg shadow">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{title}</h3>
            <div className="space-y-2">
                {data.slice(0, showAll ? data.length : 5).map((rider) => (
                    <div key={rider.id} className="p-2 hover:bg-gray-50 rounded border border-gray-100">
                        <Link to={`/athletes/${rider.rides[0]?.urn}/profile`} className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                                <span className={`font-bold ${rider.rank === 1 ? 'text-yellow-500' :
                                        rider.rank === 2 ? 'text-gray-400' :
                                            rider.rank === 3 ? 'text-amber-700' : 'text-gray-600'
                                    } w-6`}>{rider.rank}.</span>
                                <img
                                    src={`https://ultrarides-images.s3.ap-south-1.amazonaws.com/profiles/${rider.rides[0]?.urn}.jpg`}
                                    alt={`Profile of ${rider.name}`}
                                    className="w-10 h-10 rounded-full shadow-sm object-cover"
                                    onError={(e) => e.target.src = 'https://via.placeholder.com/150?text=No+Image'}
                                />
                                <div>
                                    <span className="text-gray-800 font-medium hover:text-primary">{rider.name}</span>
                                    <div className="text-gray-500 text-xs">URN: {rider.rides[0]?.urn}</div>
                                </div>
                            </div>
                            <div className="text-right">
                                <div className="text-gray-800 font-medium text-sm">
                                    {title === 'By Number of Rides' ?
                                        `${rider.totalRides} rides` :
                                        `${rider.totalDistance} km`}
                                </div>
                                <div className="text-gray-500 text-xs">
                                    {title === 'By Number of Rides' ?
                                        `${rider.totalDistance} km` :
                                        `${rider.totalRides} rides`}
                                </div>
                            </div>
                        </Link>
                        <RiderDetails rides={rider.rides} />
                    </div>
                ))}
                {data.length > 5 && (
                    <button
                        onClick={toggleShowAll}
                        className="w-full py-1 text-sm text-center text-primary hover:text-primary-dark transition-colors border-t border-gray-100"
                    >
                        {showAll ? (
                            <div className="flex items-center justify-center">
                                <ChevronUp size={16} className="mr-1" />
                                Show Less
                            </div>
                        ) : (
                            <div className="flex items-center justify-center">
                                <ChevronDown size={16} className="mr-1" />
                                Show All ({data.length} entries)
                            </div>
                        )}
                    </button>
                )}
            </div>
        </div>
    );
}

function LeaderboardDetails() {
    const { clubId } = useParams();
    const [leaderboardData, setLeaderboardData] = useState(null);
    const [club, setClub] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAllRides, setShowAllRides] = useState(false);
    const [showAllDistance, setShowAllDistance] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const clubResponse = await axios.get(`/clubs/${clubId}`);
                setClub(clubResponse.data);

                const leaderboardResponse = await fetch(
                    `https://ultra-rides-clubstats.s3.ap-south-1.amazonaws.com/clubs/${clubId}/leaderboard.json`
                );
                if (leaderboardResponse.ok) {
                    const data = await leaderboardResponse.json();
                    setLeaderboardData(data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [clubId]);

    if (loading) {
        return (
            <div className="max-w-6xl mx-auto p-4">
                <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
                    <div className="animate-pulse flex items-center space-x-4">
                        <div className="rounded-full bg-gray-200 h-16 w-16"></div>
                        <div className="flex-1">
                            <div className="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
                            <div className="h-4 bg-gray-200 rounded w-1/3"></div>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded-lg shadow-lg p-4">
                    <div className="animate-pulse">
                        <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
                        {[...Array(5)].map((_, idx) => (
                            <div key={idx} className="flex items-center justify-between mb-3">
                                <div className="h-6 bg-gray-200 rounded w-1/3"></div>
                                <div className="h-6 bg-gray-200 rounded w-1/4"></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    if (!club || !leaderboardData || !leaderboardData.leaderboards) {
        return (
            <div className="max-w-6xl mx-auto p-4 bg-white rounded-lg shadow-lg">
                <p className="text-gray-600">No data available.</p>
            </div>
        );
    }

    const { leaderboards, stats } = leaderboardData;

    return (
        <div className="max-w-6xl mx-auto p-4">
            <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
                <div className="flex flex-col md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-4">
                    {club.logo ? (
                        <img src={club.logo} alt={`${club.name} Logo`} className="w-20 h-20 rounded-full object-cover" />
                    ) : (
                        <div className="bg-gray-200 h-20 w-20 rounded-full flex items-center justify-center">
                            <span className="text-gray-400 text-xl">Logo</span>
                        </div>
                    )}
                    <div className="text-center md:text-left">
                        <h1 className="text-2xl font-bold text-gray-800 mb-1">{club.name}</h1>
                        <div className="text-gray-600 mb-2 text-sm">{club.location}</div>
                        <Link to={`/clubs/${clubId}/rides`} className="text-primary hover:text-primary-dark transition-colors text-sm">
                            View Club Rides
                        </Link>
                    </div>
                </div>
            </div>

            <div className="mb-4">
                <h1 className="text-3xl font-bold text-gray-800">Club Leaderboard</h1>
                <p className="text-gray-600">Rankings and achievements of club members</p>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-4">
                <h2 className="text-xl font-bold text-gray-800 mb-4">Club Statistics</h2>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mb-4">
                    <div className="bg-gray-50 p-3 rounded-lg text-center">
                        <div className="text-2xl font-bold text-primary">{stats.totalRiders}</div>
                        <div className="text-gray-600 text-sm">Total Riders</div>
                    </div>
                    <div className="bg-gray-50 p-3 rounded-lg text-center">
                        <div className="text-2xl font-bold text-primary">{stats.totalFinishedRides}</div>
                        <div className="text-gray-600 text-sm">Completed Rides</div>
                    </div>
                    <div className="bg-gray-50 p-3 rounded-lg text-center">
                        <div className="text-2xl font-bold text-primary">{stats.totalDistance.toLocaleString()}</div>
                        <div className="text-gray-600 text-sm">Total Distance (km)</div>
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <LeaderboardSection
                        title="By Number of Rides"
                        data={leaderboards.byRides}
                        showAll={showAllRides}
                        toggleShowAll={() => setShowAllRides(!showAllRides)}
                    />
                    <LeaderboardSection
                        title="By Total Distance"
                        data={leaderboards.byDistance}
                        showAll={showAllDistance}
                        toggleShowAll={() => setShowAllDistance(!showAllDistance)}
                    />
                </div>
            </div>
        </div>
    );
}

export default LeaderboardDetails;
